import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'modules/core/config/i18n';
import { NonCancelableCustomEvent, Select, Container, Header, SelectProps, Box } from '@amzn/awsui-components-react';

import { ApplicationState } from 'modules/app/store';

import styles from './UserCard.module.scss';

const langs = [
    {
        label: 'English',
        value: 'en'
    },
    {
        label: 'Spanish',
        value: 'es'
    },
    {
        label: 'German',
        value: 'de'
    }
];

export function UserCard(): JSX.Element {
    const user = useSelector((state: ApplicationState) => state.user);
    const fullName = `${user.firstName} ${user.lastName}`;

    const { t } = useTranslation('app');

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const [language, setLanguage] = useState<SelectProps.Option>(langs[0]);

    function onLanguageSelect(event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) {
        changeLanguage(event.detail.selectedOption.value ?? '');
        setLanguage(event.detail.selectedOption);
    }
    return (
        <Container disableContentPaddings header={<Header variant="h2">{t('personalDetails')}</Header>}>
            <div id={styles.user}>
                <img src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${user.alias}`} alt="avatar" />
                <Box padding="s">
                    <Header variant="h2">
                        {t('hello')}, {user.firstName}!
                    </Header>
                    <Box fontWeight="bold" variant="span">
                        {t('fullName')}:{' '}
                    </Box>
                    {fullName}
                    <br />
                    <Box fontWeight="bold" variant="span">
                        Email:{' '}
                    </Box>
                    {user.email}
                </Box>
            </div>
            <Box padding="s">
                <Box fontWeight="bold">{t('changeLanguage')}: </Box>
                <div id={styles.selectmenu}>
                    <Select onChange={onLanguageSelect} selectedOption={language} options={langs} />
                </div>
            </Box>
        </Container>
    );
}
