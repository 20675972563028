import React from 'react';
import { Box, Container, Header } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

interface RuleContentProps {
    customFields: string[];
    customSelections: Map<string, string[]>;
}

export const RuleContent = (props: RuleContentProps) => {
    const { customFields, customSelections } = props;

    const { t } = useTranslation('customCategory');

    const DisplayField = ({ field }: { field: string }) => <div>{field}</div>;

    const DisplaySelections = ({ group }: { group: string }) => {
        return (
            <>
                <Box variant="awsui-key-label">{group}</Box>
                <div>{t('ruleContent.options')}</div>
                {customSelections.get(group)?.map(selection => (
                    <DisplayField key={selection} field={selection} />
                ))}
                <br />
            </>
        );
    };

    return customFields.length > 0 || customSelections.size > 0 ? (
        <Container header={<Header variant="h2">{t('ruleContent.title')}</Header>}>
            {customSelections.size > 0 && (
                <div>
                    <Box variant="awsui-key-label">{t('ruleContent.selectionTitle')}</Box>
                    {[...customSelections.keys()].map(group => (
                        <DisplaySelections key={group} group={group} />
                    ))}
                </div>
            )}
            {customFields.length > 0 && (
                <div>
                    <Box variant="awsui-key-label">{t('ruleContent.fieldTitle')}</Box>
                    {customFields.map(field => (
                        <DisplayField key={field} field={field} />
                    ))}
                </div>
            )}
        </Container>
    ) : (
        <></>
    );
};
