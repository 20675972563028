import React from 'react';
import { ErrorContent } from '../components/errorContent/ErrorContent';

interface ErrorProps {
    message: string;
    btnContent: string;
    btnFunction?: () => void;
}

export const Error = ({ message, btnContent, btnFunction }: ErrorProps) => {
    return <ErrorContent message={message} btnContent={btnContent} btnFunction={btnFunction} />;
};
