import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@amzn/awsui-components-react';

interface ExpandButtonProps {
    hideButton: boolean;
    isExpandable: boolean;
    showExpandedSection?: (isExpandable: boolean) => void;
}

export const ExpandButton = ({ hideButton, isExpandable, showExpandedSection }: ExpandButtonProps) => {
    const { t } = useTranslation('purchase');

    const onButtonClick = () => {
        if (showExpandedSection) {
            showExpandedSection(!isExpandable);
        }
    };

    return (
        <>
            {hideButton ? (
                <></>
            ) : (
                <Button
                    data-cy="collapseButton"
                    variant="normal"
                    formAction="submit"
                    iconAlign="right"
                    iconName={isExpandable ? 'angle-down' : 'angle-right'}
                    onClick={onButtonClick}
                >
                    {isExpandable ? t('collapseAll') : t('expandAll')}
                </Button>
            )}
        </>
    );
};
