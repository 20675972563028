import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Button, Box, SpaceBetween } from '@amzn/awsui-components-react';
import style from '../ReallocateBudgetModal/ReallocateBudgetModal.module.scss';

interface RemoveSpendModalProps {
    visible: boolean;
    removeSpend?: (dissmiss: boolean) => void;
}

export const RemoveSpendModal = ({ visible, removeSpend }: RemoveSpendModalProps) => {
    const { t } = useTranslation('purchase');

    const title = t('invoices.removeSpendModalTitle');
    const content = <div className={style['content-padding']}>{t('invoices.removeSpendModalContent')}</div>;

    function onRemoveOrCancel(canRemove: boolean) {
        if (removeSpend) {
            removeSpend(canRemove);
        }
    }

    const Footer = () => (
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" data-cy="invoices.cancel" onClick={() => onRemoveOrCancel(false)}>
                    {t('owner.cancel')}
                </Button>
                <Button variant="primary" data-cy="invoices.ok" onClick={() => onRemoveOrCancel(true)}>
                    {t('invoices.ok')}
                </Button>
            </SpaceBetween>
        </Box>
    );

    return (
        <Modal
            data-cy="removeSpendModal"
            visible={visible}
            header={title}
            footer={<Footer />}
            onDismiss={() => onRemoveOrCancel(false)}
        >
            {content}
        </Modal>
    );
};
