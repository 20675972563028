import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Container, Header, TokenGroup } from '@amzn/awsui-components-react';

import { ApplicationState } from 'modules/app/store';

export function GroupCard(): JSX.Element {
    const user = useSelector((state: ApplicationState) => state.user);

    const { t } = useTranslation('app');

    return (
        <Container variant="stacked" header={<Header variant="h2">{t('permissions')}</Header>}>
            <Box variant="div">
                <Header variant="h3">{t('posixGroups')}:</Header>
                <TokenGroup
                    data-cy="posixTokenGroup"
                    items={user.posix?.map((label: string) => ({ label, iconName: 'key' }))}
                    onDismiss={() => undefined}
                />
            </Box>
            <Box variant="div">
                <Header variant="h3">{t('LDAPGroups')}:</Header>
                <TokenGroup
                    data-cy="ldapTokenGroup"
                    items={user.ldap?.map((label: string) => ({ label, iconName: 'key' }))}
                    onDismiss={() => undefined}
                />
            </Box>
        </Container>
    );
}
