import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Box, SpaceBetween } from '@amzn/awsui-components-react';

import { NameCard } from '../NameCard';
import { ChangedLineAmount } from '../ChangedLineAmount';
import { VersionTitle } from '../VersionTitle/VersionTitle';
import { LineVersion, VersionType } from 'modules/audit/models';
import { OriginalReceiptVersion } from '../OriginalReceiptVersion';

import styles from '../EditedVersion/EditedVersion.module.scss';

export interface AddedOrGeneratedVersionProps {
    lineVersions: LineVersion[];
}

export const AddedOrGeneratedVersion = (props: AddedOrGeneratedVersionProps) => {
    const { lineVersions } = props;
    const lineVersion = lineVersions[0];
    const { t } = useTranslation('purchase');

    function getTitle() {
        if (VersionType.FIRST_EDIT === lineVersion.versionType) return t('version.generatedTitle');
        return t('version.lineAddedTitle');
    }

    return (
        <Container header={<VersionTitle title={getTitle()} lineVersion={lineVersion} />}>
            <div className={lineVersion.isRejected ? styles['transparent-box'] : ''}>
                <Grid gridDefinition={[{ colspan: 1 }, { colspan: 9 }, { colspan: 2 }]}>
                    <NameCard
                        login={lineVersion.updaterLogin}
                        firstName={lineVersion.updaterFirstName}
                        lastName={lineVersion.updaterLastName}
                    />
                    <div>
                        <SpaceBetween size="s">
                            <Box variant="strong">
                                {t('version.poLine')} {lineVersion.lineNumber}: {lineVersion.lineDescription}
                            </Box>
                            <OriginalReceiptVersion
                                receiptVersions={lineVersion.receiptVersions}
                                currency={lineVersion.currencyCode}
                                uncommittedBalance={lineVersion.modifiedUncommittedBalance}
                            />
                        </SpaceBetween>
                    </div>
                    <ChangedLineAmount amount={lineVersion.changedLineAmount} currency={lineVersion.currencyCode} />
                </Grid>
            </div>
        </Container>
    );
};
