import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button, Link, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { useSelector } from 'react-redux';

import { PurchaseDetailPagePanels } from 'modules/purchase/constants';
import { PurchaseDetailTable } from 'modules/purchase/components/PurchaseDetailTable';
import { InvoiceLineTable } from 'modules/invoices/components/InvoiceLineTable';
import {
    ApplicationState,
    getLine,
    isRequisition,
    getCanDisableEdit,
    getUserEditStatus,
    isQuantityBasedLine
} from 'modules/app/store';
import { I18nFormatter } from 'modules/core/class';
import { PurchaseLineHeader } from '../PurchaseLineHeader';

import styles from '../PurchaseLineWrapper/Wrapper.module.scss';
import { ReceiptStatus } from '@amzn/merp-core/models';

interface ViewPurchaseLineProps {
    lineNumber: number;
    loadingReceipts?: boolean;
    loadingInvoiceLines?: boolean;
    onEdit?: () => void;
    onHelp?: (key: PurchaseDetailPagePanels, ...args: unknown[]) => void;
}

export const ViewPurchaseLine = (props: ViewPurchaseLineProps) => {
    const { lineNumber, loadingInvoiceLines, loadingReceipts, onHelp, onEdit } = props;

    const { t } = useTranslation('purchase');

    const isPurchaseRequest = useSelector(isRequisition);
    const line = useSelector((state: ApplicationState) => getLine(state.purchaseDetails, lineNumber));
    const canDisableEdit = useSelector((state: ApplicationState) => getCanDisableEdit(state.purchaseDetails));
    const validUserEditStatus = useSelector((state: ApplicationState) => getUserEditStatus(state));

    const isQuantityBased = isQuantityBasedLine(line);

    const { lastModifiedTime, uncommittedBalance } = line;
    const receipts = isPurchaseRequest
        ? line.receipts.filter(
              r => r.receiptStatus !== ReceiptStatus.VOIDED && r.receiptStatus !== ReceiptStatus.PENDING_VOID
          )
        : line.receipts.filter(r => r.receiptStatus !== ReceiptStatus.VOIDED);

    const auditHistoryPath = `/audit/${line.purchaseId}`;

    const title = isPurchaseRequest ? 'PR' : 'PO';
    const INVALID_SPEND_WARNING = t('line.modal.invalidSpendDistribution', { purchaseType: `${title}` });

    const onSpendInfoClicked = () => {
        if (onHelp) onHelp('SPEND_HELP');
    };

    const onSpendConfirmationInfoClicked = () => {
        if (onHelp) onHelp('SPEND_CONFIRMATION_HELP');
    };

    const onReceiptInfoClicked = () => {
        if (onHelp) onHelp('RECEIPT_HELP');
    };

    const onReceiptStatusInfoClicked = () => {
        if (onHelp) onHelp('RECEIPT_STATUS_HELP');
    };

    const LastUpdated = () => {
        receipts.sort((r1, r2) => r2.lastModifiedTime! - r1.lastModifiedTime!);
        return (
            <div className="mt-2">
                <Box fontSize="heading-xs">
                    {t('line.lastUpdated')} {I18nFormatter.date(t, lastModifiedTime)}: &nbsp;
                    {receipts?.length > 0 &&
                        receipts[0]?.versionCategory &&
                        t(`line.updateReasons.${receipts[0]?.versionCategory}`)}
                    <Link variant="secondary" href={auditHistoryPath} target="_blank" rel="noopener noreferrer">
                        &nbsp;{t('overview.viewEdit')}&nbsp;
                    </Link>
                </Box>
                <div>{receipts?.length > 0 && receipts[0]?.versionDescription}</div>
            </div>
        );
    };

    const ViewPurchaseDetails = () => {
        return (
            <>
                <Box variant="awsui-key-label">
                    {t('line.receiptInformation')} ({receipts?.length})
                </Box>
                <PurchaseDetailTable
                    receipts={receipts}
                    line={line}
                    uncommittedBalance={uncommittedBalance}
                    onSpendInfoClicked={onSpendInfoClicked}
                    onSpendConfirmationInfoClicked={onSpendConfirmationInfoClicked}
                    onReceiptInfoClicked={onReceiptInfoClicked}
                    onReceiptStatusInfoClicked={onReceiptStatusInfoClicked}
                />
                {!isPurchaseRequest && <LastUpdated />}
            </>
        );
    };

    const LoadingReceipts = () => (
        <div className={styles['spinner-centered']}>
            <Spinner />
        </div>
    );

    const disableEdit = !!loadingReceipts;
    const LineHeader = () => {
        return (
            <>
                <Box float="left">
                    <PurchaseLineHeader line={line} />
                </Box>
                <Box float="right" padding={{ top: 's' }}>
                    {validUserEditStatus && (
                        <Button
                            data-cy="editLine"
                            formAction="none"
                            variant="primary"
                            onClick={onEdit}
                            disabled={disableEdit || canDisableEdit}
                        >
                            {t('wrapper.edit')}
                        </Button>
                    )}
                </Box>
            </>
        );
    };

    return (
        <SpaceBetween size="l">
            {!line.hasValidDistribution && (
                <Alert data-cy="invalidSpendDistribution" header={INVALID_SPEND_WARNING} type="warning"></Alert>
            )}
            <LineHeader />
            {loadingReceipts ? <LoadingReceipts /> : <ViewPurchaseDetails />}

            {!isPurchaseRequest && (
                <div className={styles['invoice-lines-container']}>
                    <InvoiceLineTable
                        loading={loadingInvoiceLines}
                        lineNumber={lineNumber}
                        isQuantityBased={isQuantityBased}
                    />
                </div>
            )}
        </SpaceBetween>
    );
};
