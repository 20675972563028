import React from 'react';
import { Box } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { LineVersion } from 'modules/audit/models';

export interface BigObjectiveLineProps {
    lineVersion: LineVersion;
}

export const BigObjectiveLine = (props: BigObjectiveLineProps) => {
    const { lineVersion } = props;
    const { t } = useTranslation('purchase');

    return (
        <Box variant="strong">
            {t('version.poLine')} {lineVersion.lineNumber}: {lineVersion.lineDescription}
        </Box>
    );
};
