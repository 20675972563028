import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, SpaceBetween, Header, Container, ColumnLayout, Box, BoxProps } from '@amzn/awsui-components-react';

import { AppConfig } from 'modules/core';
import { I18nFormatter } from 'modules/core/class';
import { PurchaseDetailPagePanels } from 'modules/purchase/constants';
import { ApplicationState, getUserEditStatus } from 'modules/app/store';
import { EditBigRockObjective } from 'modules/bigRockObjective/components';
import { EditFreeTextModal } from 'modules/customCategory/components/EditFreeTextModal';
import { EditSelectionModal } from 'modules/customCategory/components/EditSelectionModal';
import { useCustomField } from 'modules/customCategory/hooks';
import { CustomField, CustomFieldType } from 'modules/customCategory/models';
import { RequisitionDetailPagePanels } from 'modules/requisition/constants';
import { ConfirmStatus, MspOwner, PurchaseStatus } from '../../models';
import { EditAspOwners } from '../EditAspOwner/EditAspOwner';

interface PurchaseOverviewProps {
    isCreationPage: boolean;
    updateOwners?: (updateOwners: MspOwner[]) => void;
    onHelp?: (key: RequisitionDetailPagePanels | PurchaseDetailPagePanels, ...args: unknown[]) => void;
    onUpdateBigRockObjective?: () => void;
}

export const PurchaseOverview = (props: PurchaseOverviewProps) => {
    const { isCreationPage, updateOwners, onHelp, onUpdateBigRockObjective } = props;

    const purchase = useSelector((state: ApplicationState) => state?.purchaseDetails?.purchase);

    const validUserEditStatus = useSelector(getUserEditStatus);

    const isPurchaseOrder = !purchase?.isPurchaseRequest;
    const purchaseId = purchase?.purchaseId ?? '';

    const title = isPurchaseOrder ? 'PO' : 'PR';
    const date = isPurchaseOrder ? purchase?.orderCreationTime : purchase?.requestCreationTime;

    const [isEditOwnerModalVisible, showEditOwnerModal] = useState(false);
    const [isEditBigRockObjectiveVisible, showEditBigRockObjective] = useState(false);
    const [isFreeTextModalVisible, updateFreeTextModalVisible] = useState(false);
    const [isSelectionModalVisible, updateSelectionModalVisible] = useState(false);

    const { defaultCustomFields } = useCustomField(purchase?.purchaseId ?? '');
    const defaultFreeTextFields = defaultCustomFields?.filter(f => {
        return CustomFieldType.TEXT === f.fieldType;
    });
    const defaultSelectionFields = defaultCustomFields?.filter(f => {
        return CustomFieldType.SELECTION === f.fieldType;
    });

    const existingFreeTextFields = purchase?.customFields ?? [];
    const existingSelectionFields = purchase?.customSelections ?? [];

    const { t } = useTranslation(['purchase', 'bigRock', 'customCategory']);

    const confirmStatusMappings = new Map([
        [ConfirmStatus.CONFIRMED, t('confirmation.isConfirmed')],
        [ConfirmStatus.NOT_CONFIRMED, t('confirmation.unconfirmed')],
        [ConfirmStatus.NOT_APPLICABLE, t('line.noSpendActivity')],
        [ConfirmStatus.PURCHASE_CLOSED, t('confirmation.isClosed')]
    ]);

    const purchaseStatusMap = new Map([
        [PurchaseStatus.CHANGE_PURCHASE_PENDING_MERP_APPROVAL, 'Update Needed'],
        [PurchaseStatus.CANCELLED, 'Cancelled'],
        [PurchaseStatus.SOFT_CLOSED, 'Soft Closed'],
        [PurchaseStatus.PENDING_SOFT_CLOSED, 'Pending Soft Close'],
        [PurchaseStatus.CLOSED, 'Closed'],
        [PurchaseStatus.ISSUED, 'Open']
    ]);

    const textColor: { [key: string]: BoxProps.Color } = {
        updateneeded: 'text-status-error',
        cancelled: 'text-status-inactive',
        softclosed: 'text-status-inactive',
        pendingsoftclose: 'text-status-inactive',
        closed: 'text-status-inactive',
        open: 'text-status-success'
    };

    const viewEditModal = () => {
        showEditOwnerModal(true);
    };

    const closeEditModal = () => {
        showEditOwnerModal(false);
    };

    const onInfoClicked = () => {
        if (onHelp) {
            onHelp('CENTRAL_RECEIVER_HELP', {
                url: AppConfig.coupaAccessFormUrl || '',
                value: t('purchase:helpPanel.accessRequestGuide')
            });
        }
    };

    const confirmStatusString = purchase ? confirmStatusMappings.get(purchase.confirmationReceiptStatus) : '';
    const purchaseStatus = purchase?.status !== undefined ? purchaseStatusMap.get(purchase.status) : '';
    const className = purchaseStatus?.toLowerCase().replace(/\s/g, '') || '';

    const ASPOwner = () => {
        const onAliasClicked = (owner: string) => {
            window.open(`https://phonetool.amazon.com/users/${owner}`, '_blank', 'noopener,noreferrer');
        };

        const AliasButton = ({ alias }: { alias: string }) => (
            <Link variant="info" onClick={() => onAliasClicked(alias)}>
                {alias}@
            </Link>
        );

        const { createdBy, requestedBy, mspOwner } = purchase || {};
        const uniqueAlias = new Set([createdBy?.login, requestedBy?.login, ...(mspOwner || [])]);

        const owners = Array.from(uniqueAlias)
            .filter(s => s)
            .map(String);

        const onOwnersUpdated = (owners: MspOwner[]) => {
            if (updateOwners) {
                updateOwners(owners);
            }
        };

        return (
            <>
                <EditAspOwners
                    visible={isEditOwnerModalVisible}
                    updateOwners={onOwnersUpdated}
                    onCancel={closeEditModal}
                />
                <Box variant="awsui-key-label">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Box variant="span">{t('purchase:overview.mspOwner')}</Box>
                        <Link onFollow={onInfoClicked}>{t('info')}</Link>
                        <Link variant="info" onClick={viewEditModal}>
                            {t('purchase:edit')}
                        </Link>
                    </SpaceBetween>
                </Box>
                <SpaceBetween size={'xs'} direction="horizontal">
                    {owners.map(owner => (
                        <AliasButton key={owner} alias={owner} />
                    ))}
                </SpaceBetween>
            </>
        );
    };

    const stringHasContent = (s: string | undefined) => s != null && s !== '';

    const BigRock = () => {
        return (
            <>
                <Box variant="awsui-key-label">
                    <SpaceBetween direction="horizontal" size="xs">
                        <span>{t('purchase:overview.bigRock')}</span>
                        {validUserEditStatus && (
                            <Link onFollow={() => showEditBigRockObjective(true)}>{t('purchase:edit')}</Link>
                        )}
                    </SpaceBetween>
                </Box>
                <div>
                    {
                        <span>
                            {stringHasContent(purchase?.bigRockObjective)
                                ? t(`bigRock:dropdown.objectives.${purchase?.bigRockObjective}`)
                                : t('bigRock:dropdown.objectives.noObjective')}
                        </span>
                    }
                </div>
            </>
        );
    };

    const displayCustomField = (field: CustomField) => {
        return <span>{field.fieldValues.join(', ')}</span>;
    };

    const CustomFreeText = () => {
        return (
            <div>
                {defaultFreeTextFields && defaultFreeTextFields.length > 0 && (
                    <Box variant="awsui-key-label">
                        <SpaceBetween direction="horizontal" size="xs">
                            <span>{defaultFreeTextFields[0].fieldKey}</span>
                            <Link onFollow={() => updateFreeTextModalVisible(true)}>{t('purchase:edit')}</Link>
                        </SpaceBetween>
                    </Box>
                )}
                {existingFreeTextFields.length > 0 ? (
                    <div>{displayCustomField(existingFreeTextFields[0])}</div>
                ) : (
                    <div>{t('customCategory:editModal.freeTextEmpty')}</div>
                )}
            </div>
        );
    };

    const CustomSelection = () => {
        return (
            <div>
                {defaultSelectionFields && defaultSelectionFields.length > 0 && (
                    <Box variant="awsui-key-label">
                        <SpaceBetween direction="horizontal" size="xs">
                            <span>{defaultSelectionFields[0].fieldKey}</span>
                            <Link onFollow={() => updateSelectionModalVisible(true)}>{t('purchase:edit')}</Link>
                        </SpaceBetween>
                    </Box>
                )}
                {existingSelectionFields.length > 0 ? (
                    <div>{displayCustomField(existingSelectionFields[0])}</div>
                ) : (
                    <div>{t('customCategory:editModal.selectionEmpty')}</div>
                )}
            </div>
        );
    };

    return (
        <Container
            header={
                <Header variant="h2">
                    {title} {t('purchase:overview.overview')}
                </Header>
            }
        >
            <ColumnLayout columns={3} variant="text-grid" borders={'vertical'}>
                <div>
                    <SpaceBetween size="l">
                        <div>
                            <Box variant="awsui-key-label">{t('purchase:overview.vendor')}</Box>
                            <div>{purchase?.supplier?.displayName}</div>
                        </div>
                        <div>
                            <ASPOwner />
                        </div>
                        {isPurchaseOrder && defaultFreeTextFields && defaultFreeTextFields.length > 0 && (
                            <CustomFreeText />
                        )}
                    </SpaceBetween>
                </div>
                <div>
                    <SpaceBetween size="l">
                        <div>
                            <Box variant="awsui-key-label">{t('purchase:overview.created')}</Box>
                            <div>
                                {I18nFormatter.date(t, date)}
                                {purchase && isPurchaseOrder && (
                                    <Link href={`/audit/${purchaseId}`} target="_blank" rel="noopener noreferrer">
                                        &nbsp;{t('purchase:overview.viewEdit')}&nbsp;
                                    </Link>
                                )}
                            </div>
                        </div>
                        {isPurchaseOrder && (
                            <div>
                                <Box variant="awsui-key-label">{t('purchase:overview.confirmed')}</Box>
                                <div>{confirmStatusString}</div>
                            </div>
                        )}
                        {isPurchaseOrder && defaultSelectionFields && defaultSelectionFields.length > 0 && (
                            <CustomSelection />
                        )}
                    </SpaceBetween>
                </div>
                <div>
                    <SpaceBetween size="l">
                        <div>
                            {isCreationPage ? (
                                <div>
                                    <Box variant="awsui-key-label">{t('purchase:overview.account')}</Box>
                                    <div>{purchase?.lines[0]?.company}</div>
                                </div>
                            ) : (
                                <BigRock />
                            )}
                        </div>
                        {isPurchaseOrder && (
                            <div>
                                <div>
                                    <Box variant="awsui-key-label">{t('purchase:overview.status')}</Box>
                                    <Box color={textColor[className]}>{purchaseStatus}</Box>
                                </div>
                            </div>
                        )}
                    </SpaceBetween>
                </div>
            </ColumnLayout>
            <EditBigRockObjective
                visible={isEditBigRockObjectiveVisible}
                onDismissed={() => {
                    if (
                        onUpdateBigRockObjective &&
                        purchase?.bigRockObjective !== undefined &&
                        purchase?.bigRockObjective !== ''
                    ) {
                        onUpdateBigRockObjective();
                    }
                    showEditBigRockObjective(false);
                }}
            />
            <EditFreeTextModal
                visible={isFreeTextModalVisible}
                defaultFreeTextFields={defaultFreeTextFields ?? []}
                onDismissed={() => {
                    updateFreeTextModalVisible(false);
                }}
            />
            <EditSelectionModal
                visible={isSelectionModalVisible}
                defaultSelectionFields={defaultSelectionFields ?? []}
                onDismissed={() => {
                    updateSelectionModalVisible(false);
                }}
            />
        </Container>
    );
};
