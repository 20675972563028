import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ColumnLayout } from '@amzn/awsui-components-react';

import { ReceiptVersionRequest, ReceiptVersion } from 'modules/audit/models';
import { ReceiptVersionList } from 'modules/audit/components/ReceiptVersionList/ReceiptVersionList';

import styles from './OriginalReceiptVersion.module.scss';

export interface OriginalReceiptVersionProps {
    currency: string;
    receiptVersions: ReceiptVersion[];
    uncommittedBalance: number;
}

export const OriginalReceiptVersion = (props: OriginalReceiptVersionProps) => {
    const { currency, receiptVersions, uncommittedBalance } = props;
    const { t } = useTranslation('purchase');

    function getModifiedRequests() {
        return receiptVersions.map(
            ({ modifiedReceiptAmount, modifiedReceiptTime }) =>
                new ReceiptVersionRequest(modifiedReceiptAmount, modifiedReceiptTime)
        );
    }

    return (
        <ColumnLayout columns={1}>
            <div className={styles['receipt-version-title']}>
                <Box variant="strong">{t('version.original')}</Box>
                <ColumnLayout columns={2}>
                    <ReceiptVersionList
                        requests={getModifiedRequests()}
                        currency={currency}
                        uncommittedBalance={uncommittedBalance}
                    />
                </ColumnLayout>
            </div>
        </ColumnLayout>
    );
};
