import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
    Button,
    Modal,
    FormField,
    SpaceBetween,
    Box,
    Input,
    NonCancelableCustomEvent,
    InputProps
} from '@amzn/awsui-components-react';
import { CustomField, CustomFieldType } from 'modules/customCategory/models';
import { useCustomField } from 'modules/customCategory/hooks';
import { useSelector } from 'react-redux';
import { getPurchaseId } from 'modules/app/store';

interface EditFreeTextModalProps {
    visible?: boolean;
    defaultFreeTextFields: CustomField[];
    onDismissed: () => void;
}

export const EditFreeTextModal = (props: EditFreeTextModalProps) => {
    const { visible, defaultFreeTextFields, onDismissed } = props;

    const { t } = useTranslation('customCategory');

    const [field, updateField] = useState<string>('');

    const purchaseId = useSelector(getPurchaseId);
    const { loading, success, updateCustomFieldsForPurchaseAsync } = useCustomField(purchaseId);

    useEffect(() => {
        if (!success) return;

        onDismissed();
    }, [success]);

    const onSave = () => {
        if (field === '') {
            onDismissed();

            return;
        }

        updateCustomFieldsForPurchaseAsync(purchaseId, [
            Object.assign(new CustomField(), {
                fieldKey: defaultFreeTextFields[0].fieldKey,
                fieldValues: [field],
                fieldType: CustomFieldType.TEXT,
                optional: false,
                multiSelect: false
            })
        ]);

        onDismissed();
    };

    const onCancel = () => {
        updateField('');
        onDismissed();
    };

    function onCustomFieldChange({ detail: { value } }: NonCancelableCustomEvent<InputProps.ChangeDetail>): void {
        updateField(value);
    }

    return (
        <Modal
            visible={visible ?? false}
            header={t('editModal.freeTextTitle')}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="normal"
                            onClick={onCancel}
                            disabled={loading}
                            data-cy="EditFreeTextModalModal.cancel"
                        >
                            {t('editModal.cancel')}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={onSave}
                            disabled={loading}
                            loading={loading}
                            data-cy="EditFreeTextModalModal.save"
                        >
                            {t('editModal.save')}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={onDismissed}
        >
            <div>
                {defaultFreeTextFields && defaultFreeTextFields.length > 0 && (
                    <FormField>
                        <div>{defaultFreeTextFields[0].fieldKey}</div>
                        <Input value={field} onChange={onCustomFieldChange} />
                    </FormField>
                )}
            </div>
        </Modal>
    );
};
