/* eslint-disable react/display-name */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header } from '@amzn/awsui-components-react';

type RollUpsHeaderProps = {
    header: string;
    disabled?: boolean;
    exportLoading?: boolean;
    onExport?: () => void;
};

export const RollUpsHeader = (props: RollUpsHeaderProps) => {
    const { header, disabled, exportLoading, onExport } = props;

    const { t } = useTranslation('rollups');

    return (
        <Header
            variant="h2"
            actions={
                <Button variant="primary" disabled={disabled} onClick={onExport} loading={exportLoading}>
                    {t('export')}
                </Button>
            }
        >
            {header}
        </Header>
    );
};
