import React from 'react';
import { Box, Container, Grid } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { LineVersion, VersionType } from 'modules/audit/models';
import { NameCard } from '../NameCard/NameCard';
import { VersionTitle } from '../VersionTitle/VersionTitle';
import { ChangedLineAmount } from '../ChangedLineAmount';

import styles from './EditedVersion.module.scss';

export interface EditedVersionProps {
    lineVersions: LineVersion[];
    versionType: VersionType;
}

export const EditedVersion = (props: EditedVersionProps) => {
    const { lineVersions, versionType } = props;
    const lineVersion = lineVersions[0];
    const { t } = useTranslation('purchase');

    const getTitleBasedOnVersionType = (versionType: VersionType) => {
        const versionTypeTitleMap = new Map();

        versionTypeTitleMap.set(VersionType.BULK_EDIT_SPEND, t('version.bulkEditedTitle'));
        versionTypeTitleMap.set(VersionType.ONBOARD_PURCHASE_SPEND, t('version.onboardPurchaseSpendTitle'));
        versionTypeTitleMap.set(VersionType.ONBOARD_PURCHASE, t('version.onboardPurchaseTitle'));
        versionTypeTitleMap.set(VersionType.COUPA_SYNC_RECEIPTS, t('version.coupaSyncReceiptsTitle'));
        versionTypeTitleMap.set(VersionType.SPEND_EDIT, t('version.editedTitle'));
        versionTypeTitleMap.set(VersionType.CREDIT_MEMO_REDUCED, t('version.creditMemoReducedTitle'));
        versionTypeTitleMap.set(VersionType.CREDIT_MEMO_CANCELLED, t('version.creditMemoCancelledTitle'));

        return versionTypeTitleMap.get(versionType) ?? '';
    };

    lineVersion.receiptVersions.sort((a, b) => b.modifiedReceiptTime - a.modifiedReceiptTime);

    const title = getTitleBasedOnVersionType(versionType);

    return (
        <Container header={<VersionTitle title={title} lineVersion={lineVersion} showDetails={true} />}>
            <div className={lineVersion.isRejected ? styles['transparent-box'] : ''}>
                <Grid gridDefinition={[{ colspan: 1 }, { colspan: 9 }, { colspan: 2 }]}>
                    <NameCard
                        login={lineVersion.updaterLogin}
                        firstName={lineVersion.updaterFirstName}
                        lastName={lineVersion.updaterLastName}
                    />
                    <div>
                        <Box variant="strong">
                            {t('version.poLine')} {lineVersion.lineNumber}: {lineVersion.lineDescription}
                        </Box>
                        <div>
                            {lineVersion.receiptVersions.length > 0 &&
                                lineVersion.receiptVersions[0].versionCategory &&
                                t(`line.updateReasons.${lineVersion.receiptVersions[0].versionCategory}`)}
                            <div>
                                {lineVersion.receiptVersions.length > 0 &&
                                    lineVersion.receiptVersions[0].versionDescription}
                            </div>
                        </div>
                    </div>
                    <ChangedLineAmount amount={lineVersion.changedLineAmount} currency={lineVersion.currencyCode} />
                </Grid>
            </div>
        </Container>
    );
};
