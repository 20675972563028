import React from 'react';
import { AppLayout, BreadcrumbGroup } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ApplicationState } from 'modules/app/store';
import { ErrorRouter } from 'modules/error/components';
import { dashboardPanels } from 'modules/purchase/constants';
import { useHelpPanel } from 'modules/core/hooks';
import { AssignOwnerStepper } from 'modules/budgetManagement/components/AssignOwnerStepper';

export const AssignOwner = () => {
    const { t } = useTranslation('budgetManagement');

    const { panel, dismissPanel } = useHelpPanel(dashboardPanels);

    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);

    const breadcrumbItems = [
        { text: t('dashboard'), href: '/budget' },
        {
            text: t('stepper.title'),
            href: '#'
        }
    ];

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <ErrorRouter
                        errorStatusCode={applicationHealth.errorCode}
                        dashboardError={applicationHealth.accessError}
                    />
                ) : (
                    <AssignOwnerStepper />
                )
            }
            breadcrumbs={<BreadcrumbGroup ariaLabel="Breadcrumbs" items={breadcrumbItems} />}
            contentType="table"
            navigationHide={true}
            toolsOpen={panel !== undefined}
            toolsHide={panel === undefined}
            tools={panel}
            onToolsChange={dismissPanel}
            headerSelector="#merp-nav"
        />
    );
};
