import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FlashbarProps, Form, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';

import { PurchaseOverview } from 'modules/purchase/components/PurchaseOverview/PurchaseOverview';
import { ExpandButton, PurchaseLineWrapper, LoadingIndicator } from 'modules/purchase/components';
import { DetailPageContentProps } from 'modules/purchase/views/purchaseDetail';
import { ApplicationState, getPurchaseSubmitStatus, isNonMarketing } from 'modules/app/store';
import { OwnerUpdateStatus } from 'modules/purchase/models/OwnerUpdateStatus';
import { RequisitionDetailPagePanels } from 'modules/requisition/constants';
import { usePurchase, usePurchaseOwner } from 'modules/purchase/hooks';
import { PurchaseDetailPagePanels } from 'modules/purchase/constants';
import { PurchaseSubmitStatus } from 'modules/purchase/models';
import { useValidate } from 'modules/user/hooks';
import { AppConfig } from 'modules/core';
import _ from 'lodash';

type PurchaseViewProps = DetailPageContentProps;

export const Requisition = (props: PurchaseViewProps) => {
    const { onUpdateFlashbar, onFlashbarMessageDismissed, onHelp } = props;

    const { t } = useTranslation('purchase');

    const isMarketingPR = !useSelector(isNonMarketing);
    const purchase = useSelector((state: ApplicationState) => state?.purchaseDetails?.purchase, shallowEqual);
    const purchaseSubmitted = useSelector(getPurchaseSubmitStatus);

    const { hasSmallerLines, hasNextPage, loadLinesRef } = usePurchase(purchase?.purchaseId || '');

    const { ownerUpdated, updateMspOwnersAsync } = usePurchaseOwner(purchase?.purchaseId || '');

    const [isExpandable, showExpandedSection] = useState(hasSmallerLines);

    useValidate(purchase?.purchaseId || '');

    useEffect(() => {
        if (ownerUpdated.status === OwnerUpdateStatus.NOT_UPDATED) {
            return;
        }
        const updateSuccess: FlashbarProps.MessageDefinition = {
            type: 'success',
            header: t('owner.flashTitle'),
            dismissible: true,
            onDismiss: onDismissFlashbar
        };

        const updateFailed: FlashbarProps.MessageDefinition = {
            type: 'error',
            header: t('owner.flashErrorTitle'),
            content: ownerUpdated.reason,
            dismissible: true,
            onDismiss: onDismissFlashbar
        };

        const flashbar = ownerUpdated.status === OwnerUpdateStatus.UPDATE_SUCCESS ? updateSuccess : updateFailed;

        if (onUpdateFlashbar) {
            onUpdateFlashbar(flashbar);
        }
    }, [ownerUpdated, t]);

    useEffect(() => {
        if (purchaseSubmitted === PurchaseSubmitStatus.NOT_SUBMITTED) {
            return;
        }
        const updateSuccess: FlashbarProps.MessageDefinition = {
            type: 'success',
            header: t('flashPRTitle'),
            content: t('flashPRContent'),
            dismissible: true,
            onDismiss: onDismissFlashbar
        };

        const updateFailed: FlashbarProps.MessageDefinition = {
            type: 'error',
            header: t('flashTitleError', { purchaseType: 'PR' }),
            content: t('flashContentError', { purchaseType: 'PR' }),
            dismissible: true,
            onDismiss: onDismissFlashbar
        };

        const flashbar = purchaseSubmitted === PurchaseSubmitStatus.SUBMIT_SUCCESS ? updateSuccess : updateFailed;

        if (onUpdateFlashbar) {
            onUpdateFlashbar(flashbar);
        }
    }, [purchaseSubmitted, t]);

    useEffect(() => {
        if (isMarketingPR && _.isEmpty(purchase?.bigRockObjective)) {
            const flashbarItem: FlashbarProps.MessageDefinition = {
                type: 'error',
                content: t('bigRockObjective.flashbarErrorContent'),
                dismissible: true,
                onDismiss: onDismissFlashbar
            };
            if (onUpdateFlashbar) {
                onUpdateFlashbar(flashbarItem);
            }
        }
    }, [purchase?.bigRockObjective]);

    const onInfoClicked = (key: RequisitionDetailPagePanels | PurchaseDetailPagePanels) => {
        if (onHelp && purchase) {
            const centralReciverHelp = {
                url: AppConfig.coupaAccessFormUrl || '',
                value: t('purchase:helpPanel.accessRequestGuide')
            };

            const options =
                key === 'CENTRAL_RECEIVER_HELP'
                    ? centralReciverHelp
                    : { url: purchase?.coupaUrl, value: `PR ID ${purchase.purchaseId}` };
            onHelp(key, options);
        }
    };

    const onDismissFlashbar = () => {
        if (onFlashbarMessageDismissed) {
            onFlashbarMessageDismissed();
        }
    };

    if (!purchase) return <></>;

    return (
        <Form
            header={
                <Header variant="h1">
                    <SpaceBetween size="s" direction="horizontal">
                        <div>{`PR: ${purchase?.purchaseId}`}</div>
                        <Link data-cy="prInfo" onFollow={() => onInfoClicked('PR_HELP')}>
                            {t('info')}
                        </Link>
                    </SpaceBetween>
                </Header>
            }
        >
            <SpaceBetween size="xl">
                <PurchaseOverview
                    isCreationPage={false}
                    updateOwners={updateMspOwnersAsync}
                    onHelp={onHelp}
                    onUpdateBigRockObjective={onDismissFlashbar}
                />
                <ExpandButton
                    hideButton={hasSmallerLines}
                    isExpandable={isExpandable}
                    showExpandedSection={showExpandedSection}
                />
                {purchase?.lines.map(line => (
                    <PurchaseLineWrapper
                        key={line.lineNumber}
                        lineNumber={line.lineNumber}
                        purchaseStatus={purchase.status}
                        toggleExpand={isExpandable}
                        onHelp={onInfoClicked}
                        onFlashbarMessageDismissed={onDismissFlashbar}
                        onUpdateFlashbar={onUpdateFlashbar}
                        line={line}
                    />
                ))}
            </SpaceBetween>
            <LoadingIndicator hasNextPage={hasNextPage} loadLinesRef={loadLinesRef} />
        </Form>
    );
};
