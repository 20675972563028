import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    Select,
    AppLayout,
    BreadcrumbGroup,
    SelectProps,
    NonCancelableCustomEvent,
    Box
} from '@amzn/awsui-components-react';

import { VersionType } from 'modules/audit/models';
import { ApplicationState } from 'modules/app/store';
import { ErrorRouter } from 'modules/error/components';
import { usePaginatedVersions, getVersionComponent } from 'modules/audit/hooks';

import styles from 'modules/audit/views/Audit.module.scss';

export const AuditList = () => {
    const { purchaseId } = useParams();
    const { t } = useTranslation('purchase');

    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);

    const filterItems: SelectProps.Option[] = [
        {
            label: t('version.all'),
            value: 'allHistory'
        },
        {
            label: t('version.totalChanged'),
            value: 'isLineAmountChanged'
        },
        {
            label: t('version.poConfirmed'),
            value: 'isConfirmProcess'
        }
    ];

    const [filter, setFilter] = useState(filterItems[0]);

    function onFilterSelect(event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) {
        setFilter(event.detail.selectedOption);
    }

    const { loading, versionEvents, hasNextPage, paginateFromServerAsync } = usePaginatedVersions({
        purchaseId: purchaseId || '',
        filter: filter.value || ''
    });

    const paginationObserver = useRef<IntersectionObserver>();

    const lastAuditVersionRef = useCallback(
        (jsxElement: HTMLHeadingElement) => {
            if (loading) return;

            if (paginationObserver.current) {
                paginationObserver.current.disconnect();
            }
            paginationObserver.current = new IntersectionObserver(async entries => {
                if (entries[0].isIntersecting && hasNextPage) {
                    await paginateFromServerAsync(true);
                }
            });

            if (jsxElement) paginationObserver.current.observe(jsxElement);
        },
        [loading, hasNextPage, paginateFromServerAsync]
    );

    const orderId = versionEvents ? versionEvents[0]?.orderId || '' : '';

    const breadcrumbItems = [
        {
            text: t('version.poList'),
            href: '/dashboard/purchaseOrder'
        },
        {
            text: `${t('version.poId')}: ${orderId}`,
            href: `/purchaseOrder/${purchaseId}`
        },
        {
            text: t('version.auditHistory'),
            href: `/purchaseOrder/${purchaseId}/history`
        }
    ];

    const dotStyle = (versionType: VersionType) => {
        return versionType === VersionType.CONFIRM_PROCESS ? styles['confirmed-dot'] : styles['dot'];
    };

    const Content = () => (
        <>
            <div className={styles['audit-view-container']}>
                <BreadcrumbGroup items={breadcrumbItems}></BreadcrumbGroup>
                <div className={styles['audit-title']}>
                    <Box variant="h1" color="inherit" padding={{ top: 'xxs', bottom: 'xxs' }}>
                        {t('version.auditHistory')}
                    </Box>
                </div>
                <div className={styles['audit-dropdown']}>
                    <Select onChange={onFilterSelect} selectedOption={filter} options={filterItems} />
                </div>
            </div>
            <div className={styles['audit-timeline vertical']}>
                <div>
                    {versionEvents?.map((versionEvent, index) => (
                        <div key={index} className={styles['audit-event']}>
                            <div className={styles['audit-version-date']}>
                                <Box fontSize="body-m">
                                    {new Date(versionEvent.lineVersions[0].lineVersion).toLocaleString()}
                                </Box>
                            </div>
                            <div className={styles['audit-line-left']}>
                                <div className={dotStyle(versionEvent.type)}></div>
                            </div>
                            <div className={styles['audit-version-container']}>{getVersionComponent(versionEvent)}</div>
                        </div>
                    ))}
                </div>
            </div>
            {hasNextPage && (
                <h1 ref={lastAuditVersionRef} className={styles['audit-loading']}>
                    {t('version.loading')}
                </h1>
            )}
        </>
    );

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <ErrorRouter
                        errorStatusCode={applicationHealth.errorCode}
                        dashboardError={applicationHealth.accessError}
                    />
                ) : (
                    <Content />
                )
            }
            toolsHide={true}
            navigationHide={true}
            headerSelector="#merp-nav"
        />
    );
};
