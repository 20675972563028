import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, FormField, Header, Container } from '@amzn/awsui-components-react';

interface AssignOwnerInputProps {
    owner: string;
    updateOwner: React.Dispatch<React.SetStateAction<string>>;
    errorText?: string;
}

export const AssignCoaOwnerInput = (props: AssignOwnerInputProps) => {
    const { owner, updateOwner, errorText } = props;
    const { t } = useTranslation('budgetManagement');

    const setInputText = (inputValue: string) => {
        updateOwner(inputValue);
    };

    return (
        <Container header={<Header variant="h2">{t('coaInput.title')}</Header>}>
            <FormField label={t('coaInput.label')} errorText={errorText}>
                <Input
                    type="text"
                    value={owner}
                    placeholder="User alias"
                    onChange={({ detail }) => setInputText(detail.value)}
                />
            </FormField>
        </Container>
    );
};
