import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import { FormField, Textarea, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';

import { MotiveOptions, UpdateMotive, UpdateReceiptsReason } from 'modules/purchase/models';

import { isRequisition } from 'modules/app/store';
import { OptionDefinition } from 'modules/core/types';

interface UpdateReceiptsReasonProps {
    onReasonsChange: (reason: UpdateReceiptsReason) => void;
    updatedReason?: UpdateReceiptsReason;
}

export const UpdateReceiptReason = ({ onReasonsChange, updatedReason }: UpdateReceiptsReasonProps) => {
    const { t } = useTranslation('purchase');

    const isPurchaseRequest = useSelector(isRequisition);

    const { register, triggerValidation, setValue, errors } = useFormContext();

    useEffect(() => {
        register({ name: 'selectReason', type: 'custom' });
        register({ name: 'explanation', type: 'custom' });
    }, [register]);

    let updateReasons: SelectProps.Option[] = [...MotiveOptions].map(value => ({
        value,
        label: t(`line.updateReasons.${value}`)
    }));

    if (isPurchaseRequest) {
        updateReasons = updateReasons.filter(option => option.value !== 'editInvoiceMatch');
    } else {
        updateReasons = updateReasons.filter(option => option.value !== 'initialInput');
    }

    const [reason, setReason] = useState<UpdateReceiptsReason>(
        Object.assign(new UpdateReceiptsReason(), updatedReason)
    );

    const setReasonValue = () => {
        setValue('selectReason', reason.motive, true);
        setValue('explanation', reason.explanation, true);
    };

    const useEffectOnce = (setReason: () => void) => useEffect(setReason, []);
    useEffectOnce(setReasonValue);

    function onUpdateReasonsChange(inputName: string, value: string) {
        setValue(inputName, value);

        triggerValidation(inputName);

        setReason(prev => {
            if (inputName === 'selectReason') prev.motive = value as UpdateMotive;
            else prev.explanation = value;
            return prev;
        });

        onReasonsChange(reason);
    }

    return (
        <SpaceBetween size="l">
            <FormField description={t('line.updateReason')} errorText={errors?.selectReason?.message}>
                <Select
                    data-cy="lineReasonDropDown"
                    selectedOption={
                        updateReasons.find(updateReason => updateReason.value === reason.motive) as OptionDefinition
                    }
                    placeholder={t('line.updateReasons.selectOne')}
                    options={updateReasons}
                    onChange={event => onUpdateReasonsChange('selectReason', event.detail.selectedOption.value ?? '')}
                />
            </FormField>
            <FormField description={t('line.updateReasonExplanation')} errorText={errors?.explanation?.message}>
                <Textarea
                    data-cy="lineExplanationTextArea"
                    name="explanation"
                    placeholder={t('line.updateReasonPlaceholder')}
                    value={reason.explanation ?? ''}
                    onChange={(event: { detail: { value: string } }) =>
                        onUpdateReasonsChange('explanation', event.detail.value)
                    }
                />
            </FormField>
        </SpaceBetween>
    );
};
