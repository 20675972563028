import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isRequisition, updateUserEditStatus, updateUserSoftCloseAccess } from 'modules/app/store';
import { validateUserEditService } from 'modules/user/services';
import { logger } from 'modules/core';

export function useValidate(purchaseId: string) {
    const dispatch = useDispatch();

    const isPR = useSelector(isRequisition);

    const validateUserEditAsync = useCallback(async () => {
        try {
            const result = await validateUserEditService.validateUserEditAsync(purchaseId);
            dispatch(updateUserEditStatus(result.validUserEdit ?? false));
        } catch (error) {
            logger.error(error);
        }
    }, [purchaseId, dispatch]);

    const validateUserSoftCloseAccessAsync = useCallback(async () => {
        try {
            const result = await validateUserEditService.validateUserSoftCloseAccessAsync(purchaseId);
            dispatch(updateUserSoftCloseAccess(result.validUserSoftCloseAccess ?? false));
        } catch (error) {
            logger.error(error);
        }
    }, [purchaseId, dispatch]);

    useEffect(() => {
        if (purchaseId) {
            validateUserEditAsync();
        }
    }, [purchaseId]);

    useEffect(() => {
        if (!isPR && purchaseId) {
            validateUserSoftCloseAccessAsync();
        }
    }, [isPR, purchaseId]);
}
