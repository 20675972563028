import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tabs, Form, Header, AppLayout, Flashbar, FlashbarProps, Grid } from '@amzn/awsui-components-react';

import { CountryMappings } from '../CountryMappings/CountryMappings';
import { OrgMappings } from '../OrgMappings/OrgMappings';
import { useCountryMappings, useOrgMappings } from 'modules/mappings/hooks';
import { ApplicationState } from 'modules/app/store';
import { ErrorRouter } from 'modules/error/components';
import { UpdateStatus } from 'modules/mappings/models';

export const CountryAndOrgMappings = () => {
    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);
    const [flashbarItems, updateFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

    const { t } = useTranslation('mappings');

    const { orgMappings, saveOrgMappingsAsync, onOrgMappingUpdate, onAddingNewOrg, updateSuccess } = useOrgMappings();
    const { countryMappings } = useCountryMappings();

    useEffect(() => {
        if (updateSuccess.status === UpdateStatus.NOT_SUBMITTED) {
            return;
        }
        const successMsg: FlashbarProps.MessageDefinition = {
            type: 'success',
            header: t('orgMappings.flashTitle'),
            dismissible: true,
            onDismiss: () => updateFlashbarItems([])
        };

        const failureMsg: FlashbarProps.MessageDefinition = {
            type: 'error',
            header: t('orgMappings.flashErrorTitle'),
            content: updateSuccess.reason,
            dismissible: true,
            onDismiss: () => updateFlashbarItems([])
        };

        const flashbar = updateSuccess.status === UpdateStatus.SUBMIT_SUCCESS ? successMsg : failureMsg;

        updateFlashbarItems([flashbar]);
    }, [updateSuccess, t]);

    const OrgMappingsTab = (
        <OrgMappings
            orgMappings={orgMappings || []}
            onMappingsSave={saveOrgMappingsAsync}
            onMappingUpdate={onOrgMappingUpdate}
            onAddingNewOrg={onAddingNewOrg}
        />
    );

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <ErrorRouter errorStatusCode={applicationHealth.errorCode} />
                ) : (
                    <Grid gridDefinition={[{ colspan: 1 }, { colspan: 10 }, { colspan: 1 }]}>
                        <div></div>
                        <div>
                            <Form header={<Header variant="h1">{t('countryAndOrgMapping')}</Header>}>
                                <Tabs
                                    tabs={[
                                        {
                                            label: t('orgMappings.orgMapping'),
                                            id: 'orgMapping',
                                            content: OrgMappingsTab
                                        },
                                        {
                                            label: t('countryMappings.countryMapping'),
                                            id: 'countryMapping',
                                            content: <CountryMappings countryMappings={countryMappings || []} />
                                        }
                                    ]}
                                />
                            </Form>
                        </div>
                        <div></div>
                    </Grid>
                )
            }
            notifications={<Flashbar items={flashbarItems} />}
            navigationHide={true}
            toolsHide={true}
            tools={undefined}
            headerSelector="#merp-nav"
        />
    );
};
