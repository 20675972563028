import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    Box,
    Button,
    Select,
    SelectProps,
    SpaceBetween,
    FormField,
    Alert,
    Header
} from '@amzn/awsui-components-react';

import { FormSchema } from 'modules/mappings/models';
import style from '../BigOChannelMappings/BigOChannelMappings.module.scss';
import { FieldError } from 'react-hook-form';
import { CoaDropdownOptions } from 'modules/mappings/models/CoaDropdownOptions';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

interface DropdownSettersType {
    [key: string]: React.Dispatch<React.SetStateAction<OptionDefinition | undefined>>;
}
import { channelList } from 'modules/channel/constants';
import { ObjectiveOptions } from 'modules/bigRockObjective/constants/ObjectivesOption';
import { COAConnector, toSelectOptions } from 'modules/mappings/constants';

interface AddCoaModalProps {
    coaDropdownOptions: CoaDropdownOptions;
    repeatedMapping: boolean;
    onCancel: () => void;
    onSave: (coaString: string, bigObjective: string, channel: string) => void;
}

export const AddCoaModal = (props: AddCoaModalProps) => {
    const { onCancel, onSave, coaDropdownOptions, repeatedMapping } = props;
    const { t } = useTranslation('mappings');
    const title = t('bigOChannelMappings.addCoaCombination');

    const { register, setValue, errors, formState, triggerValidation } = FormSchema.BigONewCoaUseForm();

    const [company, setCompany] = useState<SelectProps.Option>();
    const [productLine, setProduct] = useState<SelectProps.Option>();
    const [channel, setCoaChannel] = useState<SelectProps.Option>();
    const [glAccount, setAccount] = useState<SelectProps.Option>();
    const [costCenter, setCostCenter] = useState<SelectProps.Option>();
    const [location, setLocation] = useState<SelectProps.Option>();
    const [project, setProject] = useState<SelectProps.Option>();
    const [customEighthSegment, setCustomEighthSegment] = useState<SelectProps.Option>();
    const [bigObjective, setBigObjective] = useState<SelectProps.Option>();
    const [channelType, setChannel] = useState<SelectProps.Option>();
    const [disableSave, setDisableSave] = useState<boolean>(repeatedMapping);

    const bigOLabels = Object.values(ObjectiveOptions).map(option => t(`dropdown.bigOList.${option}`));
    const bigOOptions = toSelectOptions(bigOLabels, Object.values(ObjectiveOptions));

    const channelLabels = channelList.map(option => t(`dropdown.channelsList.${option}`));
    const channelOptions = toSelectOptions(channelLabels, channelList);

    const coaSegments: (OptionDefinition | undefined)[] = [
        company,
        location,
        costCenter,
        glAccount,
        productLine,
        channel,
        project,
        customEighthSegment
    ];
    const dropdownSetters: DropdownSettersType = {
        ['company']: setCompany,
        ['location']: setLocation,
        ['costCenter']: setCostCenter,
        ['glAccount']: setAccount,
        ['productLine']: setProduct,
        ['channel']: setCoaChannel,
        ['project']: setProject,
        ['customEighthSegment']: setCustomEighthSegment,
        ['bigObjective']: setBigObjective,
        ['channelType']: setChannel
    };

    useEffect(() => {
        register({ name: 'company', type: 'custom' });
        register({ name: 'location', type: 'custom' });
        register({ name: 'costCenter', type: 'custom' });
        register({ name: 'glAccount', type: 'custom' });
        register({ name: 'productLine', type: 'custom' });
        register({ name: 'channel', type: 'custom' });
        register({ name: 'project', type: 'custom' });
        register({ name: 'customEighthSegment', type: 'custom' });
        register({ name: 'bigObjective', type: 'custom' });
        register({ name: 'channelType', type: 'custom' });
    }, [register]);

    const getCoaString = () => {
        const coaSegmentLabels = coaSegments.map(segment => segment?.label);
        return coaSegmentLabels.join(COAConnector);
    };

    const onCoaSave = () => {
        triggerValidation();
        if (formState.isValid && bigObjective && channelType) {
            onSave(getCoaString(), bigObjective.value || '', channelType.value || '');
        }
    };

    const onDropdownChange = (dropdownType: string, selection: SelectProps.Option) => {
        setValue(dropdownType, selection.label || '', true);
        dropdownSetters[dropdownType]?.(selection);
        setDisableSave(false);
    };

    const dropdown = (
        isCOADropdown: boolean,
        dropdownType: string,
        options: SelectProps.Option[],
        selectedOption?: SelectProps.Option,
        fieldError?: FieldError
    ) => (
        <div className={isCOADropdown ? style['dropdown-div-coa'] : style['dropdown-div-attributes']}>
            <p>{t(`bigOChannelMappings.${dropdownType}`)}</p>
            <FormField errorText={fieldError?.message}>
                <Select
                    onChange={({ detail }) => onDropdownChange(dropdownType, detail.selectedOption)}
                    selectedOption={selectedOption || null}
                    options={options}
                    empty={t('bigOChannelMappings.noOptions')}
                    data-cy={dropdownType}
                />
            </FormField>
        </div>
    );

    const coaDropdowns = (
        <SpaceBetween direction="horizontal" size="xs">
            {dropdown(true, 'company', toSelectOptions(coaDropdownOptions.company), company, errors?.company)}
            {dropdown(true, 'location', toSelectOptions(coaDropdownOptions.location), location, errors?.location)}
            {dropdown(
                true,
                'costCenter',
                toSelectOptions(coaDropdownOptions.costCenter),
                costCenter,
                errors?.costCenter
            )}
            {dropdown(true, 'glAccount', toSelectOptions(coaDropdownOptions.glAccount), glAccount, errors?.glAccount)}
            {dropdown(
                true,
                'productLine',
                toSelectOptions(coaDropdownOptions.productLine),
                productLine,
                errors?.productLine
            )}
            {dropdown(true, 'channel', toSelectOptions(coaDropdownOptions.channel), channel, errors?.channel)}
            {dropdown(true, 'project', toSelectOptions(coaDropdownOptions.project), project, errors?.project)}
            {dropdown(
                true,
                'customEighthSegment',
                toSelectOptions(coaDropdownOptions.customEighthSegment),
                customEighthSegment,
                errors?.customEighthSegment
            )}
        </SpaceBetween>
    );

    const bigOChannelDropdowns = (
        <SpaceBetween direction="horizontal" size="xs">
            {dropdown(false, 'bigObjective', bigOOptions, bigObjective, errors?.bigObjective)}
            {dropdown(false, 'channelType', channelOptions, channelType, errors?.channelType)}
        </SpaceBetween>
    );
    return (
        <Modal
            visible={true}
            header={title}
            onDismiss={onCancel}
            size="large"
            footer={
                <Box float="right">
                    <Button variant="link" onClick={onCancel}>
                        {t('cancel')}
                    </Button>
                    <Button variant="primary" onClick={onCoaSave} disabled={disableSave}>
                        {t('bigOChannelMappings.saveThisCombination')}
                    </Button>
                </Box>
            }
        >
            <Alert header={t('bigOChannelMappings.coaModalAlertHeader')} visible={repeatedMapping} type="warning">
                <Box display="inline-block">{t('bigOChannelMappings.coaModalAlertContent')}</Box>
            </Alert>
            <div className={style['modal-div']}>
                <Header variant="h2">{t('bigOChannelMappings.coaValue')}</Header>
                <Box variant="p">{t('bigOChannelMappings.coaModalDescLine1')}</Box>
                <Box variant="p">{t('bigOChannelMappings.coaModalDescLine2')}</Box>
                {coaDropdowns}
                {bigOChannelDropdowns}
            </div>
        </Modal>
    );
};
