import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { AppRoutes } from 'modules/app/routes';
import { updateUser } from 'modules/app/store';
import { Auth } from 'modules/auth';
import { Error } from 'modules/error/views';
import { authService } from 'modules/auth/services/auth.service';
import { logger } from 'modules/core';

export const MainRoutes = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        (async function() {
            logger.debug('Authentication guard invoked');
            const claims = await authService.getUserClaimsAsync();
            if (claims) {
                dispatch(updateUser(claims));
            } else {
                navigate('/auth');
            }
        })();
    }, [dispatch]);

    return (
        <Routes>
            <Route path="/*" element={<AppRoutes />} />
            <Route path="auth" element={<Auth />} />
            <Route path="*" element={<Error message="error.urlError" btnContent="error.urlBtn" />} />
        </Routes>
    );
};
