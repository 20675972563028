import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    Button,
    Select,
    SelectProps,
    NonCancelableCustomEvent,
    Box,
    SpaceBetween
} from '@amzn/awsui-components-react';

interface InvoiceModalProps {
    title: string;
    content: JSX.Element;
    options: Array<SelectProps.Option>;
    defaultSelection?: string;
    saveOrDismissFunction?: (dissmissOrSave: boolean, selected?: string) => void;
}

export const InvoiceModal = (props: InvoiceModalProps) => {
    const { t } = useTranslation('purchase');

    const { title, content, options, defaultSelection, saveOrDismissFunction } = props;

    const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>();

    useEffect(() => {
        if (!options.length) {
            setSelectedOption(undefined);
            return;
        }

        const { value, label, labelTag } = !defaultSelection
            ? options[0]
            : (options.find(({ value }) => value === defaultSelection) as SelectProps.Option);

        setSelectedOption({ value, label, labelTag });
    }, [options, defaultSelection]);

    const handleOnChange = (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
        const { label, value, labelTag } = event.detail.selectedOption;
        setSelectedOption({ label, value, labelTag });
    };

    function onSaveOrCancel(canSave: boolean) {
        if (!saveOrDismissFunction) {
            return;
        }

        saveOrDismissFunction(canSave, selectedOption?.value);
    }

    const Footer = () => (
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={() => onSaveOrCancel(false)}>
                    {t('owner.cancel')}
                </Button>
                <Button variant="primary" onClick={() => onSaveOrCancel(true)}>
                    {t('owner.save')}
                </Button>
            </SpaceBetween>
        </Box>
    );

    return (
        <Modal visible={true} header={title} footer={<Footer />} onDismiss={() => onSaveOrCancel(false)}>
            {content}
            <Select
                options={options}
                selectedOption={selectedOption as SelectProps.Option}
                triggerVariant="option"
                selectedAriaLabel="Selected"
                onChange={event => handleOnChange(event)}
            />
        </Modal>
    );
};
