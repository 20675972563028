import React from 'react';

import { Box, ColumnLayout } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { DownloadBulkEditSpend, UploadBulkEditSpend } from 'modules/bulkEdit/components';

export const BulkEditSpend = () => {
    const { t } = useTranslation('bulkEdit');

    return (
        <>
            <div>
                <Box variant="h1">{t('bulkUpload')}</Box>
            </div>
            <br />
            <ColumnLayout columns={2}>
                <div>
                    <Box variant="h3">{t('bulkEditDownload')}</Box>
                    <br />
                    <DownloadBulkEditSpend />
                </div>
                <div>
                    <Box variant="h3">{t('bulkEditUpload')}</Box>
                    <br />
                    <UploadBulkEditSpend />
                </div>
            </ColumnLayout>
        </>
    );
};
