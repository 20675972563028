import React from 'react';
import { Button } from '@amzn/awsui-components-react';

import { TableRow } from 'modules/core/model';

import style from './ExpandableCell.module.scss';
export interface ExpandableCellProps {
    row: TableRow<unknown>;
    cell: JSX.Element | string;
    content?: JSX.Element | JSX.Element[];
    control?: boolean;
    onChange?: (rowNumber: number) => void;
}

export const ExpandableCell = (props: ExpandableCellProps) => {
    const { row, cell, content, control, onChange } = props;

    const onIconClicked = () => {
        if (onChange) {
            onChange(row.number);
        }
    };

    let containerStyle = style.container;

    const cellStyle = control ? [style.cell, style.control].join(' ') : style.cell;
    const contentRowClass = control ? style['content-row-control'] : style['content-row'];

    if (row.selected) {
        containerStyle += ` ${style.selected}`;
    }

    const rows = content ? (Array.isArray(content) ? content : [content]) : [];

    return (
        <div className={containerStyle}>
            <div className={cellStyle}>
                {control && (
                    <div className={style['control-btn']}>
                        <Button
                            variant="icon"
                            iconName={row.expanded ? 'caret-down-filled' : 'caret-right-filled'}
                            onClick={onIconClicked}
                        ></Button>
                    </div>
                )}
                {cell}
            </div>
            {row.expanded &&
                rows.map((c, i) => (
                    <div className={contentRowClass} key={i}>
                        {c}
                    </div>
                ))}
        </div>
    );
};
