import React from 'react';
import styles from './MessageCard.module.scss';
import { Box, Container, Icon, IconProps, SpaceBetween } from '@amzn/awsui-components-react';

interface MessageCardProps {
    iconName: IconProps.Name;
    content: string;
    iconColor: string;
}
export const MessageCard = (props: MessageCardProps) => {
    return (
        <div className={styles.full_screen_container}>
            <div className={styles.wrapper}>
                <Box display="block" margin={'xxl'}>
                    <Container>
                        <SpaceBetween size={'xxl'}>
                            <div className={styles.txt}>{props.content}</div>
                            <div className={`${styles.img} ${styles[props.iconColor]}`}>
                                <Icon name={props.iconName} size="large"></Icon>
                            </div>
                        </SpaceBetween>
                    </Container>
                </Box>
            </div>
        </div>
    );
};
