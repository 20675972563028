import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Header, HelpPanel, Icon, Link } from '@amzn/awsui-components-react';

interface LinkDetails {
    url: string;
    value: string;
}
interface ParagraphDetails {
    content: string;
    header?: string;
    link?: LinkDetails;
}
interface ToolContentProps {
    header: string;
    paragraphs: string[] | ParagraphDetails[];
    link?: LinkDetails;
}

export const ToolContent = ({ header, paragraphs, link }: ToolContentProps) => {
    const [t] = useTranslation('purchase');

    const isString = (value: string | ParagraphDetails): value is string => typeof value === 'string';

    return (
        <HelpPanel
            header={<Header variant="h2">{t(header)}</Header>}
            footer={
                <>
                    {link && (
                        <div>
                            <Header variant="h3">
                                Go to COUPA <Icon name="external" />
                            </Header>
                            <ul>
                                <li>
                                    <a href={link.url} target="_blank" rel="noopener noreferrer">
                                        {link.value}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    )}
                </>
            }
        >
            {paragraphs.map((details, key) => (
                <p key={key}>
                    {isString(details) && t(details)}
                    {!isString(details) && (
                        <>
                            {details.header && <b>{t(details.header)}: </b>}
                            {!details.link && t(details.content)}
                            {details.link && (
                                <Trans
                                    i18nKey={t(details.content)}
                                    values={{ link: t(details.link.value) }}
                                    components={{
                                        anchor: <Link href={`${details.link.url}`} target="_blank" />
                                    }}
                                />
                            )}
                        </>
                    )}
                </p>
            ))}
        </HelpPanel>
    );
};
