/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow } from 'modules/core/model/TableRow';
import { BudgetTableTemplate } from '../BudgetTableTemplate';
import { BudgetTableHeaderTemplate } from '../BudgetTableHeaderTemplate';
import { OwnerStatusRow } from 'modules/budgetManagement/model/OwnerStatusRow';
import { Box, Button } from '@amzn/awsui-components-react';

import styles from './BudgetPlanTable.module.scss';

import { BudgetTablePagination } from '../BudgetTablePagination';

interface BudgetPlanTableProps {
    items: any[];
    loading: boolean;
    inGuidanceCycle: boolean;
    isGuidanceCycleLocked?: boolean;
    onEditCoaStringsClicked?: () => void;
    onStartCycleClicked?: () => void;
    onAssignOwnerClicked?: () => void;
}

export const BudgetPlanTable = (props: BudgetPlanTableProps) => {
    const {
        items,
        loading,
        inGuidanceCycle,
        isGuidanceCycleLocked,
        onEditCoaStringsClicked,
        onStartCycleClicked,
        onAssignOwnerClicked
    } = props;

    const { t } = useTranslation('budgetManagement');

    const columns = [
        {
            id: 'company',
            header: t('table.company'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa1}</div>
        },
        {
            id: 'location',
            header: t('table.location'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa2}</div>
        },
        {
            id: 'costCenter',
            header: t('table.costCenter'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa3}</div>
        },
        {
            id: 'glAccount',
            header: t('table.glAccount'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa4}</div>
        },
        {
            id: 'productLine',
            header: t('table.productLine'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa5}</div>
        },
        {
            id: 'channel',
            header: t('table.channel'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa6}</div>
        },
        {
            id: 'project',
            header: t('table.project'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa7}</div>
        },
        {
            id: 'eighthSegment',
            header: t('table.eighthSegment'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa8}</div>
        },
        {
            id: 'owner',
            header: t('table.budgetPlan.owner'),
            cell: (row: TableRow<OwnerStatusRow>) => (
                <Box color={row.payload.owner ? 'text-status-info' : 'text-status-inactive'}>
                    <div>{row.payload.owner || t('table.budgetPlan.notAvailable')}</div>
                </Box>
            )
        }
    ];

    if (inGuidanceCycle) {
        columns.push({
            id: 'status',
            header: t('table.budgetPlan.status'),
            cell: (row: TableRow<OwnerStatusRow>) => {
                const status = row.payload.taskStatus?.toLowerCase();
                return (
                    <div className={styles[status || 'notAvailable']}>
                        {t(`table.budgetPlan.${status || 'notAvailable'}`)}
                    </div>
                );
            }
        });
    }

    const editCoaStrings = <Button onClick={onEditCoaStringsClicked}>{t('table.budgetPlan.editCoaStrings')}</Button>;
    const startGuidanceCycle = (
        <Button onClick={onStartCycleClicked} disabled={inGuidanceCycle}>
            {t('table.budgetPlan.startGuidanceCycle')}
        </Button>
    );
    const assignOwners = (
        <Button
            variant="primary"
            onClick={onAssignOwnerClicked}
            disabled={items.length === 0 || !isGuidanceCycleLocked}
        >
            {t('table.budgetPlan.assignOwners')}
        </Button>
    );

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const pagination = <BudgetTablePagination openEnded={false} getItems={async () => {}} />;

    return (
        <BudgetTableTemplate
            loading={loading}
            items={items}
            columns={columns}
            header={
                <BudgetTableHeaderTemplate
                    title={t('table.budgetPlan.title')}
                    emptyTitle={t('table.budgetPlan.emptyTitle')}
                    loading={loading}
                    tableItemCount={items.length}
                    buttons={[editCoaStrings, startGuidanceCycle, assignOwners]}
                />
            }
            pagination={pagination}
        />
    );
};
