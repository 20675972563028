/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { RollUpsView } from '../views';

export function RollUpRoutes() {
    const PageRoutes = { RollUps: '/*' };

    return (
        <Routes>
            <Route path={PageRoutes.RollUps} element={<RollUpsView />} />
        </Routes>
    );
}
