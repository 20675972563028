import React from 'react';
import { AppLayout, Box, BreadcrumbGroup, Grid, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { BulkReceiving } from '../components';

export const BulkReceivingView = () => {
    const { t } = useTranslation('bulkReceiving');

    const breadcrumbItems = [
        { text: t('breadCrumbs.poList'), href: '/purchaseOrder' },
        {
            text: t('breadCrumbs.receiving'),
            href: '#'
        }
    ];

    return (
        <AppLayout
            content={
                <Grid gridDefinition={[{ colspan: { xl: 12, l: 12, xxs: 12 }, offset: { xl: 0, l: 0, xxs: 0 } }]}>
                    <SpaceBetween direction="vertical" size="l">
                        <BreadcrumbGroup ariaLabel="Breadcrumbs" items={breadcrumbItems} />
                        <Box fontSize="heading-xl">{t('title')}</Box>
                        <BulkReceiving />
                    </SpaceBetween>
                </Grid>
            }
            toolsHide={true}
            navigationHide={true}
        />
    );
};
