import React from 'react';
import { Container } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { LineVersion } from 'modules/audit/models';

import { VersionTitle } from '../VersionTitle/VersionTitle';

export interface InvoiceAddedVersionProps {
    lineVersions: LineVersion[];
}

export const InvoiceAddedVersion = (props: InvoiceAddedVersionProps) => {
    const { lineVersions } = props;
    const lineVersion = lineVersions[0];
    const { t } = useTranslation('purchase');

    return (
        <Container
            header={
                <VersionTitle title={t('version.newInvoiceAddedTitle')} lineVersion={lineVersion} showDetails={false} />
            }
        />
    );
};
