/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Header, SpaceBetween } from '@amzn/awsui-components-react';

interface BudgetTableHeaderTemplateProps {
    title: string;
    emptyTitle: string;
    loading: boolean;
    tableItemCount?: number;
    buttons?: any[];
}

export const BudgetTableHeaderTemplate = (props: BudgetTableHeaderTemplateProps) => {
    const { title, emptyTitle, loading, tableItemCount, buttons } = props;

    const validNumber = (count?: number) => {
        return count != undefined && count > 0;
    };

    const getTitle = () => {
        let displayTitle = title;

        if (!loading && !validNumber(tableItemCount)) {
            displayTitle = emptyTitle;
        }

        return <>{displayTitle}</>;
    };

    return (
        <Header
            variant="h2"
            counter={!loading && validNumber(tableItemCount) ? `(${tableItemCount})` : ''}
            actions={
                <SpaceBetween direction="horizontal" size={'xs'}>
                    {buttons?.map((button, index) => (
                        <React.Fragment key={index}>{button}</React.Fragment>
                    ))}
                </SpaceBetween>
            }
        >
            {getTitle()}
        </Header>
    );
};
