import React from 'react';
import { Box, ColumnLayout, Container, Grid, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { LineVersion } from 'modules/audit/models';
import { NameCard } from 'modules/audit/components/NameCard';
import { BigObjectiveLine } from './BigObjectiveLine';

import {} from './BigObjectiveVersion.module.scss';

export interface BigObjectiveVersionProps {
    lineVersions: LineVersion[];
}

export const BigObjectiveVersion = (props: BigObjectiveVersionProps) => {
    const { lineVersions } = props;
    const { t } = useTranslation(['purchase', 'bigRock']);

    return (
        <div className={'bigobjective_version'}>
            <Container header={<Header variant="h2">{t('version.bigObjectiveChangedTitle')}</Header>}>
                <Grid gridDefinition={[{ colspan: 1 }, { colspan: 9 }]}>
                    <div>
                        <NameCard
                            login={lineVersions[0].updaterLogin}
                            firstName={lineVersions[0].updaterFirstName}
                            lastName={lineVersions[0].updaterLastName}
                        />
                    </div>
                    <div>
                        <ColumnLayout columns={1}>
                            {lineVersions.map(lineVersion => (
                                <BigObjectiveLine key={lineVersion.lineNumber} lineVersion={lineVersion} />
                            ))}
                        </ColumnLayout>
                        <ColumnLayout borders="vertical" columns={2}>
                            <SpaceBetween size="l">
                                <Box variant="strong">{t('version.original')}</Box>
                                <div>{t(`bigRock:dropdown.objectives.${lineVersions[0].originalBigObjective}`)}</div>
                            </SpaceBetween>
                            <SpaceBetween size="l">
                                <Box variant="strong">{t('version.modified')}</Box>
                                <div>{t(`bigRock:dropdown.objectives.${lineVersions[0].modifiedBigObjective}`)}</div>
                            </SpaceBetween>
                        </ColumnLayout>
                    </div>
                </Grid>
            </Container>
        </div>
    );
};
