/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { CountryAndOrgMappings } from 'modules/mappings/components/CountryAndOrgMappings/CountryAndOrgMappings';
import { ChannelMeasurement } from 'modules/mappings/views/ChannelMeasurement';
import { BigOChannelMappings } from 'modules/mappings/components/BigOChannelMappings/BigOChannelMappings';

export function MappingRoutes() {
    const PageRoutes = {
        CountryAndOrgMapping: '/countryAndOrgMapping',
        ChannelMeasurement: '/channelMeasurements',
        BigOChannelMapping: '/bigOChannelMappings'
    };

    return (
        <Routes>
            <Route path={PageRoutes.CountryAndOrgMapping} element={<CountryAndOrgMappings />} />
            <Route path={PageRoutes.ChannelMeasurement} element={<ChannelMeasurement />} />
            <Route path={PageRoutes.BigOChannelMapping} element={<BigOChannelMappings />} />
        </Routes>
    );
}
