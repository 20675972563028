import React, { useEffect, useState } from 'react';
import { authService } from 'modules/auth/services/auth.service';
import { MessageCard } from 'modules/auth/components/messageCard/MessageCard';
import { IconProps } from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

export const Auth = () => {
    const { t } = useTranslation('auth');
    const navigate = useNavigate();

    const [contents, setContents] = useState({
        text: t('midwayAuth'),
        iconName: 'lock-private' as IconProps.Name,
        iconColor: 'orange'
    });

    function delay(milliSeconds: number) {
        return new Promise(resolve => setTimeout(resolve, milliSeconds));
    }

    useEffect(() => {
        (async function(): Promise<void> {
            const redirectMessageCardContents = {
                text: t('midwayRedirect'),
                iconName: 'status-negative' as IconProps.Name,
                iconColor: 'red'
            };

            await delay(500);
            const isAuthorized = await authService.isAuthorizedAsync();

            if (isAuthorized) {
                navigate('/');
            } else {
                setContents(redirectMessageCardContents);
                await delay(1000);
                await authService.signInAsync();
            }
        })();
    }, [t, setContents]);

    const { iconName, text, iconColor } = contents;

    return <MessageCard iconName={iconName} content={text} iconColor={iconColor} />;
};
