import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnLayout } from '@amzn/awsui-components-react';

import { ReceiptVersionRequest } from 'modules/audit/models';
import { I18nFormatter } from 'modules/core/class';

import styles from './ReceiptVersionList.module.scss';

export interface ReceiptVersionListProps {
    requests: ReceiptVersionRequest[];
    currency?: string;
    uncommittedBalance?: number;
}

export const ReceiptVersionList = (props: ReceiptVersionListProps) => {
    const { requests, currency, uncommittedBalance } = props;
    const { t } = useTranslation('purchase');
    const sortByTime = (a: ReceiptVersionRequest, b: ReceiptVersionRequest) => a.receiptTime - b.receiptTime;

    return (
        <div>
            <ColumnLayout columns={2}>
                {requests.sort(sortByTime).map((request, index) => (
                    <React.Fragment key={index}>
                        <div className={styles['with-no-gutters']}>
                            {I18nFormatter.amount(t, request.receiptAmount)} {currency}
                        </div>
                        <div className={styles['with-no-gutters']}>{I18nFormatter.date(t, request.receiptTime)}</div>
                    </React.Fragment>
                ))}
                {uncommittedBalance !== undefined && (
                    <React.Fragment>
                        <div className={styles['with-no-gutters']}>
                            {I18nFormatter.amount(t, uncommittedBalance)} {currency}
                        </div>
                        <div className={styles['with-no-gutters']}>{t('line.uncommittedBalance')}</div>
                    </React.Fragment>
                )}
            </ColumnLayout>
        </div>
    );
};
