import React, { useState } from 'react';
import { Select, SelectProps, NonCancelableCustomEvent } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { InvoiceLine } from 'modules/invoices/models';
import { I18nFormatter } from 'modules/core/class';

import styles from './EditInvoiceModal.module.scss';

interface EditInvoiceModalDropdownProps {
    selectedOptions: string[];
    index: number;
    lineNumber: number;
    defaultLineId?: string;
    isFocus: boolean;
    invoiceLines: InvoiceLine[];
    onChange?: (lineId: string, index: number) => void;
}

export const EditInvoiceModalDropdown = (props: EditInvoiceModalDropdownProps) => {
    const { t } = useTranslation('purchase');

    const { selectedOptions, index, invoiceLines, defaultLineId, isFocus, onChange } = props;

    const optionsList: SelectProps.Option[] = invoiceLines
        .filter(line => !selectedOptions.includes(line.id) || line.id === defaultLineId)
        .map(line => ({
            value: line.id || '',
            label: `${line.invoiceNumber} - line ${line.lineNumber}` || '',
            tags: [t('invoices.editModal.createDate'), I18nFormatter.date(t, line.createdAtFns)]
        }));

    const selection = optionsList.find(option => option.value === defaultLineId);
    const lineIndex = invoiceLines.findIndex(line => line.id === defaultLineId);

    const [selectedLineOption, updateSelectedLineOption] = useState(selection ?? null);
    const [selectedLineIndex, updateSelectedLineIndex] = useState(lineIndex);
    const onLineIdChange = ({ detail }: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
        const { selectedOption } = detail;
        updateSelectedLineOption(selectedOption);

        const { value } = selectedOption;
        updateSelectedLineIndex(invoiceLines.findIndex(line => line.id === value));
        if (onChange) {
            onChange(value ?? '', index);
        }
    };

    return (
        <div className={styles['table-cell']}>
            <Select
                options={optionsList}
                selectedOption={selectedLineOption}
                data-cy="EditInvoiceModalDropdown"
                onChange={onLineIdChange}
            />
            {isFocus && selectedLineIndex !== -1 && (
                <div className={styles['dropdown-row']}>
                    <div className={styles['table-row']}>
                        <div className={styles['dropdown-label-cell']}>{t('invoices.editModal.total')}</div>
                        <div className={styles['dropdown-amount-cell']}>
                            {invoiceLines[selectedLineIndex].amount.toFixed(2)}
                        </div>
                    </div>
                    <div className={styles['table-row']}>
                        <div className={styles['dropdown-label-cell']}>{t('invoices.editModal.unmatched')}</div>
                        <div className={styles['dropdown-amount-cell']}>
                            {invoiceLines[selectedLineIndex].unmatchedAmount.toFixed(2)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
