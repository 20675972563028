import React from 'react';

import { BigObjective } from '../../components';
import { useBigObjectiveRollUps } from '../../hooks';

export const BigObjectiveView = () => {
    const { loading, rollUps } = useBigObjectiveRollUps();

    return <BigObjective items={rollUps} loading={loading} />;
};
