import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Button, Box, SpaceBetween } from '@amzn/awsui-components-react';
import style from '../CorrectSpendModal/CorrectSpendModal.module.scss';

interface CorrectSpendModalProps {
    visible: boolean;
    correctedSpend?: (dissmiss: boolean) => void;
}

export const CorrectSpendModal = ({ visible, correctedSpend }: CorrectSpendModalProps) => {
    const { t } = useTranslation('purchase');

    const title = t('invoices.correctSpendModalTitle');
    const content = <div className={style['content-padding']}>{t('invoices.correctSpendModalContent')}</div>;

    function onCorrectOrCancel(canCorrect: boolean) {
        if (correctedSpend) {
            correctedSpend(canCorrect);
        }
    }

    const Footer = () => (
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" data-cy="correctSpend.cancel" onClick={() => onCorrectOrCancel(false)}>
                    {t('owner.cancel')}
                </Button>
                <Button variant="primary" data-cy="correctSpend.ok" onClick={() => onCorrectOrCancel(true)}>
                    {t('invoices.ok')}
                </Button>
            </SpaceBetween>
        </Box>
    );

    return (
        <Modal visible={visible} header={title} footer={<Footer />} onDismiss={() => onCorrectOrCancel(false)}>
            {content}
        </Modal>
    );
};
