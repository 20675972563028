import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Box, Button, SpaceBetween, Link } from '@amzn/awsui-components-react';

import { Receipt } from 'modules/purchase/models/Receipt';
import { InvoiceAllocation } from 'modules/invoices/models';
import { UseInvoiceAllocationModal } from 'modules/invoices/hooks';
import { EditInvoiceModalDropdown, EditInvoiceModalInput } from '.';
import { ApplicationState, getInvoiceLines } from 'modules/app/store';
import { I18nFormatter } from 'modules/core/class';

import styles from './EditInvoiceModal.module.scss';

interface EditInvoiceModalProps {
    receipts: Receipt[];
    receiptIndex: number;
    onSave?: (invoiceAllocations: InvoiceAllocation[], matchButtonClicked: boolean) => void;
    onDismissed?: () => void;
}

export const EditInvoiceModal = (props: EditInvoiceModalProps) => {
    const { receipts, receiptIndex, onDismissed, onSave } = props;

    const { t } = useTranslation('purchase');

    const receipt = receipts[receiptIndex];
    const { allocations } = receipt;
    const [receiptAmount, setReceiptAmount] = useState<number>(receipt.receiptAmount);
    const [billedAmount, setInvoiceBilledAmount] = useState<number>(receipt.billedAmount);
    const [focusIndex, setFocusIndex] = useState<number>(-1);

    const {
        invoiceAllocations,
        invoiceLinesInEdit,
        setInvoiceAllocations,
        updateInputAllocation,
        addEmptyInvoiceAllocation,
        removeInvoiceAllocation,
        validateAvailableAmount,
        updateAllocationLineId,
        calculateOverflowAmount
    } = UseInvoiceAllocationModal(receipts, receiptIndex);

    const invoiceLines = useSelector((state: ApplicationState) => getInvoiceLines(state, receipt.lineNumber));
    const hasInvoiceLines = invoiceLines ? invoiceLines.length > 0 : false;

    const [usedMatchButton, setUsedMatchedButton] = useState<boolean>(false);
    const [allowSaveButton, setAllowSaveButton] = useState<boolean>(true);

    const onMatchInvoiceTotalClicked = (amount: number) => {
        setReceiptAmount(amount);
        setUsedMatchedButton(true);
    };

    function getInvoiceTotalAmount(lineId: string) {
        return invoiceLines.find(line => line.id === lineId)?.amount;
    }

    function currentAllocationTotal(allocations: InvoiceAllocation[]) {
        return allocations.reduce((p, alloc) => p.plus(alloc.allocatedAmount), new BigNumber(0)).toNumber();
    }

    const onLineIdChange = (lineId: string, allocationIndex: number) => {
        setUsedMatchedButton(false);
        updateAllocationLineId(lineId, allocationIndex);

        const isValid = validateAvailableAmount(invoiceAllocations[allocationIndex], allocationIndex);
        setAllowSaveButton(isValid);
        setFocusIndex(allocationIndex);
    };

    const onInputAllocationChange = (amount: number, allocationIndex: number, formState: boolean) => {
        updateInputAllocation(amount, allocationIndex);
        setUsedMatchedButton(false);
        setInvoiceBilledAmount(currentAllocationTotal(invoiceAllocations));

        const isValid = validateAvailableAmount(invoiceAllocations[allocationIndex], allocationIndex);
        setAllowSaveButton(isValid && formState);
        setFocusIndex(allocationIndex);
    };

    const onAddAllocation = () => {
        addEmptyInvoiceAllocation();
        setUsedMatchedButton(false);
    };

    const onRemoveAllocation = (allocation: InvoiceAllocation) => {
        removeInvoiceAllocation(allocation);
        setUsedMatchedButton(false);
        setFocusIndex(-1);

        invoiceAllocations.length == 0
            ? setInvoiceBilledAmount(0)
            : setInvoiceBilledAmount(currentAllocationTotal(invoiceAllocations));
    };

    const onUpdateInvoiceAllocations = () => {
        if (onSave) {
            onSave(invoiceAllocations, usedMatchButton);
        }
    };

    const onCancelInvoiceAllocationUpdates = () => {
        if (onDismissed) {
            setInvoiceAllocations(allocations);
            onDismissed();
        }
    };

    const SpendRow = () => (
        <div className={styles['table-row']}>
            <div className={styles['table-cell']}>
                <strong>{t('invoices.editModal.spend')}</strong>
            </div>
            <div className={styles['table-cell-amount']}>{I18nFormatter.amount(t, receiptAmount)}</div>
            <div className={styles['table-button']}>
                <Link
                    variant="primary"
                    onFollow={() => onMatchInvoiceTotalClicked(billedAmount)}
                    data-cy="matchInvoiceButton"
                >
                    {t('invoices.editModal.matchInvoiceButton')}
                </Link>
            </div>
        </div>
    );

    const InvoiceRow = () => (
        <div className={styles['table-row']}>
            <div className={styles['table-cell']}>
                <strong>{t('invoices.editModal.matched')}</strong>
            </div>
            <div className={styles['table-cell-amount']}>
                <div>{I18nFormatter.amount(t, billedAmount)}</div>
            </div>
            <div className={styles['table-button']} />
        </div>
    );

    const InvoiceAllocationRow = () =>
        invoiceAllocations.map((allocation, allocationIndex) => (
            <div key={allocation.id + '-' + allocationIndex} className={styles['table-row']}>
                {hasInvoiceLines && (
                    <>
                        <EditInvoiceModalDropdown
                            index={allocationIndex}
                            lineNumber={receipt.lineNumber}
                            selectedOptions={invoiceAllocations.map(alloc => alloc.invoiceLineId)}
                            defaultLineId={allocation.invoiceLineId}
                            invoiceLines={invoiceLinesInEdit}
                            onChange={onLineIdChange}
                            isFocus={focusIndex === allocationIndex}
                        />
                        <EditInvoiceModalInput
                            allocationIndex={allocationIndex}
                            inputValue={allocation.allocatedAmount}
                            totalInvoiceAmount={getInvoiceTotalAmount(allocation.invoiceLineId)}
                            allocation={allocation}
                            onChange={onInputAllocationChange}
                            updateInputAllocation={updateInputAllocation}
                            validateAvailableAmount={validateAvailableAmount}
                            calculateOverflowAmount={calculateOverflowAmount}
                        />
                        <div className={styles['table-button']}>
                            <Link variant="primary" onFollow={() => onRemoveAllocation(allocation)} data-cy="unmatch">
                                {t('invoices.editModal.unmatch')}
                            </Link>
                        </div>
                    </>
                )}
            </div>
        ));

    const AddButtonRow = () => (
        <>
            {invoiceAllocations.length < (invoiceLines || []).length && (
                <div className={styles['table-row']}>
                    <div className={styles['table-cell']}>
                        <Button onClick={onAddAllocation} data-cy="addInvoice">
                            {t('invoices.editModal.addButton')}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );

    const Footer = () => (
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" onClick={onCancelInvoiceAllocationUpdates} data-cy="editInvoiceModalCancelBtn">
                    {t('invoices.editModal.cancel')}
                </Button>
                <Button
                    variant="primary"
                    onClick={onUpdateInvoiceAllocations}
                    data-cy="editInvoiceModalSaveBtn"
                    disabled={!allowSaveButton}
                >
                    {t('invoices.editModal.save')}
                </Button>
            </SpaceBetween>
        </Box>
    );

    return (
        <Modal
            visible={true}
            onDismiss={onCancelInvoiceAllocationUpdates}
            header={t('invoices.editModal.title')}
            footer={<Footer />}
            size="large"
        >
            <SpendRow />
            <InvoiceRow />
            {InvoiceAllocationRow()}
            <AddButtonRow />
        </Modal>
    );
};
