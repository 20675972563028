import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Button } from '@amzn/awsui-components-react/polaris';
import { useAppNotifications } from 'modules/app/hooks';
import { AppNotification } from 'modules/app/model';

type NotificationBannerProps = {
    notification: AppNotification;
    onDiscard: (notification: AppNotification) => void;
};

const NotificationBanner = memo(({ notification, onDiscard }: NotificationBannerProps) => {
    const { t } = useTranslation('app');
    const [visible, setVisible] = useState(true);

    const handleDiscard = useCallback(() => {
        onDiscard(notification);
        setVisible(false);
    }, [notification, onDiscard]);

    if (!visible) {
        return null;
    }

    return (
        <Alert
            dismissible={notification.isDismissible}
            onDismiss={() => setVisible(false)}
            type={notification.type}
            action={notification.isDiscardable && <Button onClick={handleDiscard}>{t('doNotShowAgain')}</Button>}
            data-testid={notification.id}
        >
            {notification.content}
        </Alert>
    );
});

NotificationBanner.displayName = 'NotificationBanner';

export const AppNotifications = () => {
    const { notifications, discardNotification } = useAppNotifications();

    return (
        <>
            {notifications.map(notification => (
                <NotificationBanner key={notification.id} notification={notification} onDiscard={discardNotification} />
            ))}
        </>
    );
};
