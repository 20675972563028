/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { logger } from 'modules/core';
import { purchaseListService } from '../services';
import { showErrorPage } from 'modules/app/store';
import { PurchaseIndex } from '@amzn/merp-core/models/purchase';

export function usePurchasesForExportV2() {
    const resultsAccumulator = useRef<PurchaseIndex[]>([]);
    const from = useRef<number>(0);
    const dispatch = useDispatch();

    const reset = useCallback(() => {
        resultsAccumulator.current = [];
        from.current = 0;
    }, []);

    const fetchBatch = useCallback(async (query: any) => {
        const response = await purchaseListService.getPurchaseOrdersAsyncV2(query);

        if (!response.items) {
            throw new Error('Unable to fetch purchases');
        }

        return response.items;
    }, []);

    const getPaginatedBulkPurchaseV2 = useCallback(
        async (isReport: boolean, includeCustomField: boolean) => {
            try {
                const BATCH_SIZE = 15;
                const BATCH_COUNT = 4;
                reset();

                do {
                    const batchPromises: Promise<PurchaseIndex[]>[] = [];
                    for (let i = 0; i < BATCH_COUNT; i++) {
                        const batchFrom = from.current + i * BATCH_SIZE;
                        const batchQuery = {
                            from: batchFrom,
                            pageSize: BATCH_SIZE,
                            sortedBy: 'Created',
                            report: isReport,
                            includeCustomField
                        };

                        batchPromises.push(fetchBatch(batchQuery));
                    }

                    const batchResults = await Promise.all(batchPromises);
                    const results = batchResults.flat();
                    from.current += results.length;
                    resultsAccumulator.current = [...resultsAccumulator.current, ...results];

                    if (results.length === 0) {
                        break;
                    }
                } while (from.current > 0);
                return [...resultsAccumulator.current];
            } catch (e) {
                logger.error(e);
                dispatch(showErrorPage({ errorCode: 400, accessError: true }));
                return [];
            } finally {
                reset();
            }
        },
        [dispatch, fetchBatch, reset]
    );

    return {
        getPaginatedBulkPurchaseV2
    };
}
