/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppLayout, Flashbar, FlashbarProps, Form, Header } from '@amzn/awsui-components-react';
import { useSelector } from 'react-redux';

import { ApplicationState } from 'modules/app/store';
import { BudgetPlanCycleTable } from 'modules/budgetManagement/components/BudgetPlanCycleTable';
import { dashboardPanels } from 'modules/purchase/constants';
import { useBudget } from 'modules/budgetManagement/hooks';
import { ErrorRouter } from 'modules/error/components';
import { useHelpPanel } from 'modules/core/hooks';
import { UpdateStatus } from '@amzn/merp-core/models';

export interface FinancialAnalystApprovingtProps {
    entityId?: string;
}

export const FinancialAnalystApproving = (props: FinancialAnalystApprovingtProps) => {
    const { entityId } = props;

    const { t } = useTranslation('budgetManagement');
    const navigate = useNavigate();
    const { panel, dismissPanel } = useHelpPanel(dashboardPanels);

    const [flashbarItems, updateFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);
    const userClaims = useSelector((state: ApplicationState) => state.user);
    const userAlias = userClaims.alias;
    const firstName = userClaims.firstName || userAlias;

    const { loadingBudget, cycle, yearForecasts, approveBudget, updateSuccess } = useBudget(entityId ?? '');

    useEffect(() => {
        if (updateSuccess.status === UpdateStatus.PENDING) {
            return;
        }

        const successMsg = {
            type: 'success',
            header: t('budgetDashboard.budgetApprovedFlashTitle'),
            dismissible: true,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onDismiss: () => {}
        } as FlashbarProps.MessageDefinition;

        const failureMsg = {
            type: 'error',
            header: t('budgetDashboard.budgetApprovalErrorFlashTitle'),
            dismissible: true,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onDismiss: () => {}
        } as FlashbarProps.MessageDefinition;

        const flashbarItems = updateSuccess.status === UpdateStatus.COMPLETED ? successMsg : failureMsg;

        updateFlashbarItems([flashbarItems]);
    }, [updateSuccess, t]);

    function onApproveBudgetClicked() {
        if (approveBudget) {
            approveBudget(entityId ?? '');
        }

        navigate('/budget');
    }

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <ErrorRouter
                        errorStatusCode={applicationHealth.errorCode}
                        dashboardError={applicationHealth.accessError}
                    />
                ) : (
                    <Form
                        header={
                            <Header variant="h1">
                                {t('approvingPageTitle', {
                                    firstName: firstName
                                })}
                            </Header>
                        }
                    >
                        <BudgetPlanCycleTable
                            budgetId={entityId}
                            items={yearForecasts ?? []}
                            loading={loadingBudget}
                            cycle={cycle}
                            approveBudget={onApproveBudgetClicked}
                        />
                    </Form>
                )
            }
            notifications={<Flashbar items={flashbarItems} />}
            contentType="table"
            navigationHide={true}
            toolsOpen={panel !== undefined}
            toolsHide={panel === undefined}
            tools={panel}
            onToolsChange={dismissPanel}
            headerSelector="#merp-nav"
        />
    );
};
