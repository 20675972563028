import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { AppLayout, Box, BreadcrumbGroup, ExpandableSection, Form } from '@amzn/awsui-components-react';

import { VersionType } from 'modules/audit/models';
import { ApplicationState } from 'modules/app/store';
import { getEventDetails } from 'modules/audit/hooks';
import { ErrorRouter } from 'modules/error/components';
import { PurchaseLine } from 'modules/purchase/models';
import { ViewLineVersion } from 'modules/audit/components/ViewLineVersion/ViewLineVersion';

import styles from '../Audit.module.scss';

// TODO: Will delete this after the storybook stories are fixed
// SIM : https://sim.amazon.com/issues/V1587894181
export interface AuditDetailProps {
    purchaseId?: string;
    versionType?: VersionType;
    originalLine?: PurchaseLine;
    modifiedLine?: PurchaseLine;
    lineVersionLineNumber?: string;
}

export const AuditDetail = (_props: AuditDetailProps) => {
    const { purchaseId, lineVersionLineNumber } = useParams();

    const purchase = useSelector((state: ApplicationState) => state?.purchaseDetails?.purchase);

    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);

    const { t } = useTranslation('purchase');
    const [modifiedPurchaseLineExpanded, updateModifiedPurchaseLineExpanded] = useState(true);
    const [originalPurchaseLineExpanded, updateOriginalPurchaseLineExpanded] = useState(true);

    const { versionType, originalPurchaseLine, modifiedPurchaseLine } = getEventDetails(
        purchaseId || '',
        lineVersionLineNumber || ''
    );

    const breadcrumbItems = [
        {
            text: t('version.poList'),
            href: '/dashboard/purchaseOrder'
        },
        {
            text: `${t('version.poId')}: ${purchase?.orderId}`,
            href: `/purchaseOrder/${purchaseId}`
        },
        {
            text: t('version.auditHistory'),
            href: `/audit/${purchaseId}`
        },
        {
            text: t('version.historyDetail'),
            href: `/audit/${purchaseId}/${lineVersionLineNumber}`
        }
    ];

    const getHeader = () => {
        return versionType === VersionType.INVOICE_MATCHED
            ? t('version.invoiceMatchedPageHeader')
            : t('version.spendEditedPageHeader');
    };

    const displayLineBeforeEditing =
        versionType === VersionType.SPEND_EDIT || versionType === VersionType.BULK_EDIT_SPEND;

    const Content = () => (
        <>
            <div className={styles['audit-view-container']}>
                <BreadcrumbGroup items={breadcrumbItems}></BreadcrumbGroup>
            </div>
            <Form header={<Box variant="span">{getHeader()}</Box>}>
                {modifiedPurchaseLine && (
                    <ExpandableSection
                        headerText={t('version.afterEditing')}
                        variant="container"
                        expanded={modifiedPurchaseLineExpanded}
                        onChange={() => updateModifiedPurchaseLineExpanded(!modifiedPurchaseLineExpanded)}
                    >
                        <ViewLineVersion line={modifiedPurchaseLine}></ViewLineVersion>
                    </ExpandableSection>
                )}
                {originalPurchaseLine && displayLineBeforeEditing && (
                    <ExpandableSection
                        headerText={t('version.beforeEditing')}
                        variant="container"
                        expanded={originalPurchaseLineExpanded}
                        onChange={() => updateOriginalPurchaseLineExpanded(!originalPurchaseLineExpanded)}
                    >
                        <ViewLineVersion line={originalPurchaseLine}></ViewLineVersion>
                    </ExpandableSection>
                )}
            </Form>
        </>
    );

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <ErrorRouter
                        errorStatusCode={applicationHealth.errorCode}
                        dashboardError={applicationHealth.accessError}
                    />
                ) : (
                    <Content />
                )
            }
            toolsHide={true}
            navigationHide={true}
            headerSelector="#merp-nav"
        />
    );
};
