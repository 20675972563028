import React from 'react';

import { useChannelRollUps } from 'modules/rollups/hooks';
import { Channel } from 'modules/rollups/components/Channel';

export const ChannelView = () => {
    const { loading, rollUps } = useChannelRollUps();

    return <Channel items={rollUps} loading={loading} />;
};
