import React from 'react';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { PurchaseDetailTable } from 'modules/purchase/components/PurchaseDetailTable';
import { PurchaseLine } from 'modules/purchase/models';

interface ViewLineVersionProps {
    line: PurchaseLine;
}

export const ViewLineVersion = (props: ViewLineVersionProps) => {
    const { line } = props;

    const { t } = useTranslation('purchase');

    const { receipts, uncommittedBalance } = line;

    const ViewPurchaseDetails = () => {
        return (
            <div>
                <Box variant="awsui-key-label">
                    {t('line.receiptInformation')} ({receipts?.length ?? 0})
                </Box>
                <PurchaseDetailTable receipts={receipts} uncommittedBalance={uncommittedBalance} line={line} />
            </div>
        );
    };

    const LineHeader = () => {
        return (
            <Box padding={{ top: 'xs' }}>
                <SpaceBetween direction="horizontal" size="s">
                    <Box variant="awsui-key-label">{t('line.item')}</Box>
                    <Box variant="div">{line.description}</Box>
                    <Box variant="awsui-key-label">{t('line.accountingString')}</Box>
                    <Box variant="div">{line.account?.accountingString}</Box>
                </SpaceBetween>
            </Box>
        );
    };

    return (
        <SpaceBetween size="l">
            <LineHeader />
            <ViewPurchaseDetails />
        </SpaceBetween>
    );
};
