import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Button, Modal, FormField, SpaceBetween, Box } from '@amzn/awsui-components-react';

import { getPurchaseId } from 'modules/app/store';
import { BigRockDropdown } from '../BigRockDropdown';
import { useBigObjective } from 'modules/purchase/hooks';

interface EditBigRockObjectiveProps {
    visible?: boolean;
    onDismissed: () => void;
}

export const EditBigRockObjective = (props: EditBigRockObjectiveProps) => {
    const { onDismissed, visible } = props;

    const { t } = useTranslation('bigRock');
    const purchaseId = useSelector(getPurchaseId);
    const { loading, success, bigObjective, updatePurchaseBigObjectiveAsync } = useBigObjective();

    const [selection, updateSelection] = useState<string>();

    useEffect(() => {
        updateSelection(bigObjective);
    }, []);

    useEffect(() => {
        if (!success) return;

        updateSelection(bigObjective);

        onDismissed();
    }, [success]);

    const onSelectionChanged = (id: string) => {
        updateSelection(id);
    };

    const onSave = () => {
        if (!selection) return;

        updatePurchaseBigObjectiveAsync(purchaseId, selection);
    };

    return (
        <Modal
            visible={visible ?? false}
            header={t('editBigRock.title')}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="normal"
                            onClick={onDismissed}
                            disabled={loading}
                            data-cy="editBigObjectiveModal.cancel"
                        >
                            {t('editBigRock.cancel')}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={onSave}
                            disabled={loading}
                            loading={loading}
                            data-cy="editBigObjectiveModal.save"
                        >
                            {t('editBigRock.save')}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={onDismissed}
        >
            <div>
                <FormField
                    description={t('editBigRock.description')}
                    label={
                        <div>
                            <span>{t('editBigRock.bigObjective')}*</span>
                        </div>
                    }
                >
                    <BigRockDropdown onChange={onSelectionChanged} defaultSelection={selection} />
                </FormField>
            </div>
        </Modal>
    );
};
