import { v4 as uuidV4 } from 'uuid';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Button, SpaceBetween, Grid } from '@amzn/awsui-components-react';

import { logger } from 'modules/core';
import { CurrencyCode } from 'modules/core/model';
import { DeepCopy } from 'modules/purchase/class';
import { PurchaseLine, Receipt, ReceiptStatus } from 'modules/purchase/models';
import { DateGenerator } from 'modules/core/class';
import { AllocationCell } from '../AllocationCell';
import { InvoiceAllocation } from 'modules/invoices/models';
import { EditReceiptEstimation } from './EditReceiptEstimation';
import { invoiceAllocationHandler } from 'modules/invoices/class';
import {
    EditInvoiceModal,
    RemoveSpendModal,
    ReallocateBudgetModal,
    ReallocateBudgetOptions
} from 'modules/invoices/components';
import { useRemoveSpendModal, useReallocateBudgetModal } from 'modules/invoices/hooks';
import {
    ApplicationState,
    getInvoiceLines,
    isRequisition,
    updateInvoiceLine,
    updateInvoiceLines,
    hasBeenApprovedByMsp,
    isQuantityBasedLine
} from 'modules/app/store';
import { EditChannelSpendModal } from 'modules/channel/components';
import { ChannelSpend } from 'modules/channel/models/ChannelSpend';

import style from '../PurchaseLineWrapper/Wrapper.module.scss';
import { ReceiptStatusColumn } from '../ReceiptStatusColumn';
import { CorrectSpendModal } from '../CorrectSpendModal';
import { useCorrectSpendModal } from 'modules/purchase/hooks';
import { EditReceiptAmount } from './EditReceiptAmount';

export interface EditReceiptsProps {
    line: PurchaseLine;
    uncommittedBalance: number;
    isStepperMode?: boolean;
    onReceiptsUpdated: (receipts: Receipt[]) => void;
    onAutoAdjustChannelAllocations?: (updatedReceipts: Receipt[], isCreateMode?: boolean | undefined) => void;
}

export const EditReceipts = (props: EditReceiptsProps) => {
    const { uncommittedBalance, line, isStepperMode, onReceiptsUpdated, onAutoAdjustChannelAllocations } = props;

    const { t } = useTranslation('purchase');

    const receipts = line.receipts;
    const currency = line.currencyCode;
    const dispatch = useDispatch();
    const lineNumber = line.lineNumber;
    const isPurchaseRequest = useSelector(isRequisition);
    const isCreationPage = isPurchaseRequest && useSelector(hasBeenApprovedByMsp) === false;
    const invoiceLines = useSelector((state: ApplicationState) => getInvoiceLines(state, line.lineNumber));
    const isQuantityBased = isQuantityBasedLine(line);

    const { clearError, unregister } = useFormContext();

    const { removeSpend, onRemoveSpendAction, showRemoveSpendModal } = useRemoveSpendModal();
    const { correctedSpend, onCorrectSpendAction, showCorrectSpendModal } = useCorrectSpendModal();
    const { reallocateBudget, onReallocateBudgetAction } = useReallocateBudgetModal();

    const [receiptsCopy, setReceiptsCopy] = useState<Receipt[]>(receipts.map(DeepCopy.receipt));

    useEffect(() => {
        setReceiptsCopy(receipts.map(DeepCopy.receipt));
    }, [receipts]);

    const [editInvAllocationReceiptIndex, setEditInvAllocationReceiptIndex] = useState<number>(-1);

    const [channelAllocations, onChannelAllocationsUpdated] = useState<ChannelSpend[][]>(
        receipts.map(receipt => receipt.channelAllocations)
    );
    const [editChannelAllocationReceiptIndex, setEditChannelAllocationReceiptIndex] = useState<number>(-1);

    const onReceiptUpdated = (receipt: Receipt) => {
        const newReceipts = receiptsCopy.map(DeepCopy.receipt);

        if (isQuantityBased) setQuantityBasedAttributes(receipt);

        const receiptIndex = newReceipts.findIndex(r => r.receiptNumber === receipt.receiptNumber);

        if (editInvAllocationReceiptIndex !== -1) {
            receipt.allocations = newReceipts[editInvAllocationReceiptIndex].allocations;
        }

        newReceipts[receiptIndex] = receipt;
        if (!isQuantityBased && onAutoAdjustChannelAllocations && needToAutoUpdateChannelAllocation(receipt)) {
            onAutoAdjustChannelAllocations(newReceipts, isStepperMode || false);

            newReceipts[receiptIndex].channelAllocations[0].amount = receipt.receiptAmount;
        }

        onReceiptsUpdated(newReceipts);
    };

    const setQuantityBasedAttributes = (receipt: Receipt) => {
        receipt.pricePerUnit = line.pricePerUnit;

        const { quantity, pricePerUnit } = receipt;

        const amount = new BigNumber(pricePerUnit ?? 0).times(new BigNumber(quantity ?? 0)).toNumber();

        receipt.receiptAmount = Number.isNaN(amount) ? 0 : amount;
    };

    function needToAutoUpdateChannelAllocation(receipt: Receipt) {
        return receipt.channelAllocations.length === 1;
    }

    const addReceipt = () => {
        const newReceipts = receiptsCopy.map(DeepCopy.receipt);
        newReceipts.push(
            Object.assign(new Receipt(), {
                receiptAmount: 0,
                receiptTime: DateGenerator.parseCurrentTime(Date.now()).valueOf(),
                receiptNumber: uuidV4(),
                lineNumber: line.lineNumber
            })
        );

        onReceiptsUpdated(newReceipts);
        if (!isQuantityBased) onChannelAllocationsUpdated(newReceipts.map(receipt => receipt.channelAllocations));
    };

    const correctReceipt = (sourceReceipt: Receipt) => {
        const newReceipts = receiptsCopy.map(r => {
            const newReceipt = DeepCopy.receipt(r);
            if (r.receiptNumber === sourceReceipt.receiptNumber) newReceipt.receiptStatus = ReceiptStatus.CORRECTION;
            return newReceipt;
        });
        setReceiptsCopy(newReceipts);
        onReceiptsUpdated(newReceipts);
    };

    const removeReceipt = (receipt: Receipt) => {
        const keepNewReceipt = receipt.receiptStatus != null;
        const receiptIndex = receiptsCopy.findIndex(r => r.receiptNumber === receipt.receiptNumber);
        const newReceipts = receiptsCopy
            .map(r => {
                const copy = DeepCopy.receipt(r);

                copy.voidRequest = copy.voidRequest || r.receiptNumber === receipt.receiptNumber;
                if (copy.voidRequest) {
                    copy.receiptStatus = ReceiptStatus.PENDING_VOID;
                }
                return copy;
            })
            .filter(r => r.receiptNumber !== receipt.receiptNumber || keepNewReceipt);

        [`amount[${receiptIndex}]`, `receiptDate[${receiptIndex}]`].forEach(name => {
            clearError(name);
            unregister(name);
        });

        setReceiptsCopy(newReceipts);

        const receiptAllocations = DeepCopy.receipt(receipt).allocations;
        receiptAllocations.forEach(invoiceAllocation => {
            const invoiceLine = invoiceLines.find(line => line.id === invoiceAllocation.invoiceLineId);
            if (!invoiceLine) {
                logger.error(
                    `Unable to find invoice line with id ${invoiceAllocation.invoiceLineId} on removing allocated receipt line`
                );
                return;
            }

            const result = invoiceAllocationHandler.deallocateInvoice(receipt, invoiceLine);
            if (!result) {
                return;
            }

            dispatch(updateInvoiceLine({ lineNumber, invoiceLine: result.invoiceLine }));
        });

        onReceiptsUpdated(newReceipts);
        if (!isQuantityBased) onChannelAllocationsUpdated(newReceipts.map(receipt => receipt.channelAllocations));
    };

    const onRemoveSpend = (shouldRemove: boolean) => {
        onRemoveSpendAction(shouldRemove, removeReceipt);
    };

    const onCorrectSpend = (shouldCorrect: boolean) => {
        onCorrectSpendAction(shouldCorrect, correctReceipt);
    };

    const copyAndReplaceReceipts = (original: Receipt, replacement: Receipt) => {
        const newReceipts = receipts.map(r =>
            r.receiptNumber === original.receiptNumber ? replacement : DeepCopy.receipt(r)
        );

        return newReceipts;
    };

    const replaceReceipt = (receipts: Receipt[], receipt: Receipt) => {
        const index = receipts.findIndex(r => r.receiptNumber === receipt.receiptNumber);
        if (index < 0) {
            throw new Error(`Unable to replace receipt: Did not find receipt ${receipt.receiptNumber}`);
        }
        receipts[index] = receipt;
    };

    const reallocateToReceipt = (from: Receipt, to?: Receipt) => {
        const { source, destination } = invoiceAllocationHandler.reallocateUnbilledAmount(from, to);

        const newReceipts = copyAndReplaceReceipts(from, source);

        // "to" is undefined, we are allocating the unbilled amount to a new spend.
        if (to === undefined) {
            newReceipts.push(destination);
        } else {
            replaceReceipt(newReceipts, destination);
        }

        onReceiptsUpdated(newReceipts);
    };

    const reallocateSpendFactory = () => {
        const options = new Map();

        const buildKey = (receiptNumber: string) => `${ReallocateBudgetOptions.MoveSpendToReceipt}:::${receiptNumber}`;

        for (const r of receipts) {
            options.set(buildKey(r.receiptNumber), (receipt: Receipt) => reallocateToReceipt(receipt, r));
        }
        options.set(ReallocateBudgetOptions.CreateNewSpend, (receipt: Receipt) => reallocateToReceipt(receipt));

        return options;
    };

    const onMatchSpend = (shouldReallocate: boolean, selection?: string) => {
        const callback = reallocateSpendFactory().get(selection);

        onReallocateBudgetAction(shouldReallocate, selection, callback);
    };

    function updateInvoiceLinesReceipts(receipts: Receipt[]) {
        invoiceLines.map(l => {
            l.allocatedAmount = 0;
        });

        receipts.map(r => {
            r.allocations.map(allocation => {
                const ofaId = allocation.invoiceOfaId;
                const line = invoiceLines.find(l => l.ofaId === ofaId);
                if (line !== undefined) {
                    line.allocatedAmount += allocation.allocatedAmount;
                }
            });
        });

        dispatch(updateInvoiceLines({ lineNumber, invoiceLines: invoiceLines }));
    }

    const onSaveInvoiceAllocations = (invoiceAllocations: InvoiceAllocation[], matchButtonClicked: boolean) => {
        const newReceipts = [...receiptsCopy];

        newReceipts[editInvAllocationReceiptIndex].allocations = invoiceAllocations;
        const allocationTotal = invoiceAllocations
            .reduce((p, alloc) => p.plus(alloc.allocatedAmount), new BigNumber(0))
            .toNumber();
        newReceipts[editInvAllocationReceiptIndex].billedAmount = allocationTotal;

        if (matchButtonClicked) {
            newReceipts[editInvAllocationReceiptIndex].receiptAmount =
                newReceipts[editInvAllocationReceiptIndex].billedAmount;
        }

        updateInvoiceLinesReceipts(newReceipts);

        onReceiptsUpdated(newReceipts);
        setEditInvAllocationReceiptIndex(-1);
    };

    const onSaveChannelAllocations = (receiptChannelSpend: ChannelSpend[], receiptIndex: number) => {
        const filteredAllocations = receiptChannelSpend.filter(
            allocation => allocation.amount !== 0 && allocation.type !== ''
        );

        const updatedReceipts = [...receiptsCopy];
        updatedReceipts[receiptIndex].channelAllocations = filteredAllocations;
        onReceiptsUpdated(updatedReceipts);

        channelAllocations[receiptIndex] = filteredAllocations;
        onChannelAllocationsUpdated(channelAllocations);

        setEditChannelAllocationReceiptIndex(-1);
    };

    const canEditInvoices = (receipt: Receipt): boolean => {
        return receipt.receiptStatus === ReceiptStatus.POSTED;
    };

    const shouldDisplayVoidButton = (receipt: Receipt): boolean => {
        return (
            receipt.receiptStatus !== ReceiptStatus.VOIDED &&
            ((receipt.receiptStatus !== ReceiptStatus.PENDING_VOID && isPurchaseRequest) ||
                receipt.receiptStatus === ReceiptStatus.POSTED ||
                (receipt.receiptStatus !== ReceiptStatus.PENDING_VOID && !receipt.confirmStatus))
        );
    };
    const shouldDisplayCorrectButton = (receipt: Receipt): boolean => {
        return receipt.receiptStatus === ReceiptStatus.POSTED && !isPurchaseRequest;
    };

    const shouldDisplayRemoveButton = (receipt: Receipt): boolean => {
        return (
            !receipt.receiptStatus ||
            isPurchaseRequest ||
            (receipt.canEditReceipt && receipt.receiptStatus !== ReceiptStatus.CORRECTION) ||
            (receipt.receiptStatus === ReceiptStatus.PENDING_POST && !receipt.confirmStatus)
        );
    };

    const InvoicesColumn = ({ receipt, receiptIndex }: { receipt: Receipt; receiptIndex: number }) => {
        const canEdit = canEditInvoices(receipt);
        const showModal = receiptIndex === editInvAllocationReceiptIndex && canEdit;
        return (
            <>
                {!isPurchaseRequest && (
                    <>
                        <div>
                            <AllocationCell
                                editMode={canEdit}
                                receipt={receipt}
                                receiptIndex={receiptIndex}
                                isChannelAllocation={false}
                                onOpenEditInvoiceModal={() => setEditInvAllocationReceiptIndex(receiptIndex)}
                            />
                        </div>
                        {showModal && (
                            <EditInvoiceModal
                                receipts={receipts}
                                receiptIndex={receiptIndex}
                                onSave={onSaveInvoiceAllocations}
                                onDismissed={() => setEditInvAllocationReceiptIndex(-1)}
                            />
                        )}
                    </>
                )}
            </>
        );
    };
    const ChannelsColumn = ({ receipt, receiptIndex }: { receipt: Receipt; receiptIndex: number }) => {
        const isPendingVoid = receipt.voidRequest || receipt.receiptStatus === ReceiptStatus.PENDING_VOID;
        const showModal = receiptIndex === editChannelAllocationReceiptIndex && !isPendingVoid;
        return (
            <>
                <div>
                    <AllocationCell
                        editMode={!isPendingVoid}
                        receipt={receipt}
                        receiptIndex={receiptIndex}
                        isChannelAllocation={true}
                        onOpenEditChannelModal={() => setEditChannelAllocationReceiptIndex(receiptIndex)}
                    />
                </div>
                {showModal && (
                    <EditChannelSpendModal
                        receipts={receipts}
                        receiptIndex={receiptIndex}
                        channelAllocationsIp={channelAllocations}
                        onModalSave={onSaveChannelAllocations}
                        onModalCancel={() => setEditChannelAllocationReceiptIndex(-1)}
                    />
                )}
            </>
        );
    };

    const amountBasedModal = (receipt: Receipt, receiptIndex: number) => {
        return (
            <div className={`${style['border']}`} key={receiptIndex}>
                <Grid
                    gridDefinition={[
                        { colspan: { xxs: 12, m: 3 } },
                        { colspan: { xxs: 12, m: 2 } },
                        { colspan: { xxs: 12, m: 2 } },
                        { colspan: { xxs: 12, m: 2 } },
                        { colspan: { xxs: 12, m: 2 } }
                    ]}
                >
                    <EditReceiptEstimation index={receiptIndex} receipt={receipt} onChanged={onReceiptUpdated} />

                    <EditReceiptAmount
                        isQuantityBased={isQuantityBased}
                        index={receiptIndex}
                        receipt={receipt}
                        onChanged={onReceiptUpdated}
                    />

                    <div>
                        {!isPurchaseRequest && <ReceiptStatusColumn receipt={receipt} />}
                        <SpaceBetween direction="horizontal" size={'xs'}>
                            {shouldDisplayRemoveButton(receipt) ? (
                                <Link onFollow={() => showRemoveSpendModal(receipt)} data-cy="receipts.remove">
                                    {t('receipts.remove')}
                                </Link>
                            ) : (
                                shouldDisplayVoidButton(receipt) && (
                                    <Link onFollow={() => showRemoveSpendModal(receipt)} data-cy="receipts.void">
                                        {t('receipts.void')}
                                    </Link>
                                )
                            )}
                        </SpaceBetween>
                        <SpaceBetween direction="horizontal" size={'xs'}>
                            {shouldDisplayCorrectButton(receipt) && (
                                <Link onFollow={() => showCorrectSpendModal(receipt)} data-cy="receipts.correct">
                                    {t('receipts.correct')}
                                </Link>
                            )}
                        </SpaceBetween>
                    </div>
                    {!isCreationPage && (
                        <>
                            <ChannelsColumn receipt={receipt} receiptIndex={receiptIndex} />
                            <InvoicesColumn receipt={receipt} receiptIndex={receiptIndex} />
                        </>
                    )}
                </Grid>
            </div>
        );
    };

    const quantityBasedModal = (receipt: Receipt, receiptIndex: number) => {
        return (
            <div className={style['border']} key={receiptIndex}>
                <Grid
                    gridDefinition={[
                        { colspan: { xxs: 12, m: 3 } },
                        { colspan: { xxs: 12, m: 2 } },
                        { colspan: { xxs: 12, m: 2 } },
                        { colspan: { xxs: 12, m: 2 } },
                        { colspan: { xxs: 12, m: 2 } }
                    ]}
                >
                    <EditReceiptEstimation index={receiptIndex} receipt={receipt} onChanged={onReceiptUpdated} />

                    <EditReceiptAmount
                        isQuantityBased={isQuantityBased}
                        index={receiptIndex}
                        receipt={receipt}
                        onChanged={onReceiptUpdated}
                    />

                    <div>
                        <SpaceBetween direction="horizontal" size={'xs'}>
                            {shouldDisplayRemoveButton(receipt) ? (
                                <Link onFollow={() => showRemoveSpendModal(receipt)} data-cy="receipts.remove">
                                    {t('receipts.remove')}
                                </Link>
                            ) : (
                                shouldDisplayVoidButton(receipt) && (
                                    <Link onFollow={() => showRemoveSpendModal(receipt)} data-cy="receipts.void">
                                        {t('receipts.void')}
                                    </Link>
                                )
                            )}
                        </SpaceBetween>
                        <SpaceBetween direction="horizontal" size={'xs'}>
                            {shouldDisplayCorrectButton(receipt) && (
                                <Link onFollow={() => showCorrectSpendModal(receipt)} data-cy="receipts.correct">
                                    {t('receipts.correct')}
                                </Link>
                            )}
                        </SpaceBetween>
                    </div>
                    <div> {!isCreationPage && receipt.receiptAmount.toFixed(2)}</div>
                    <div>{!isPurchaseRequest && <ReceiptStatusColumn receipt={receipt} />}</div>
                </Grid>
            </div>
        );
    };

    return (
        <SpaceBetween size="xs">
            {receiptsCopy.map((receipt, receiptIndex) => {
                if (
                    receipt.receiptStatus === ReceiptStatus.VOIDED ||
                    (isPurchaseRequest && receipt.receiptStatus == ReceiptStatus.PENDING_VOID)
                )
                    return;
                return isQuantityBased
                    ? quantityBasedModal(receipt, receiptIndex)
                    : amountBasedModal(receipt, receiptIndex);
            })}

            <Grid gridDefinition={[{ colspan: 12 }]}>
                <Button onClick={addReceipt} data-cy="addSpendBtn">
                    {t('receipts.addSpendDistribution')}
                </Button>
            </Grid>

            <RemoveSpendModal visible={removeSpend !== undefined} removeSpend={onRemoveSpend} />
            <CorrectSpendModal visible={correctedSpend !== undefined} correctedSpend={onCorrectSpend} />
            {reallocateBudget && (
                <ReallocateBudgetModal
                    receipts={receipts}
                    unBilledAmount={reallocateBudget?.unBilled}
                    uncommittedBalance={uncommittedBalance}
                    currencyCode={currency as CurrencyCode}
                    fromReceipt={reallocateBudget}
                    saveUnbilledAmount={onMatchSpend}
                />
            )}
        </SpaceBetween>
    );
};
