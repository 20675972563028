import React, { useState } from 'react';
import { Hub } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { AppLayout, Flashbar, Alert, FlashbarProps } from '@amzn/awsui-components-react';

import { PurchaseList } from '../components';
import { useHelpPanel } from 'modules/core/hooks/useHelpPanel.hook';
import { PurchaseType } from '../models';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'modules/app/store';
import { ErrorRouter } from 'modules/error/components';
import { HubCapsule } from '@aws-amplify/core/lib-esm/Hub';
import { dashboardPanels } from 'modules/purchase/constants';
import { ReportResult } from 'modules/reports/model';

const UpdateReceiptsCount = 'UpdateReceiptsCount';

const UpdateReceiptsAlert = () => {
    const { t } = useTranslation('purchase');

    const [receiptsPendingUpdate, setReceiptsPendingUpdate] = useState(0);

    Hub.listen(UpdateReceiptsCount, (capsule: HubCapsule) => {
        setReceiptsPendingUpdate(capsule.payload.data);
    });

    return (
        <>
            {receiptsPendingUpdate > 0 && (
                <Alert type="warning" header={t('dashboard.alertTitle')}>
                    {t('dashboard.alertContent', { count: receiptsPendingUpdate })}
                </Alert>
            )}
        </>
    );
};

export const Dashboard = () => {
    const { t } = useTranslation('purchase');

    const { panel, onPanelChange, dismissPanel } = useHelpPanel(dashboardPanels);
    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);
    const [flashbarItems, updateFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

    const { pathname } = useLocation();
    const purchaseType = pathname?.split('/')[1] as PurchaseType;

    const onConfirmPurchase = (succeeded: boolean, count: number) => {
        const successMessage: FlashbarProps.MessageDefinition[] = [
            {
                type: 'success',
                header: t('dashboard.successFlashTitle', { count }),
                content: t('dashboard.successFlashContent'),
                dismissible: true,
                onDismiss: () => updateFlashbarItems([])
            }
        ];

        const errorMessage: FlashbarProps.MessageDefinition[] = [
            {
                type: 'error',
                header: t('dashboard.errorFlashTitle', { count }),
                content: t('dashboard.errorFlashContent'),
                dismissible: true,
                onDismiss: () => updateFlashbarItems([])
            }
        ];

        updateFlashbarItems(succeeded ? successMessage : errorMessage);
    };

    const onPendingReceipts = (count: number) => {
        Hub.dispatch(UpdateReceiptsCount, { event: UpdateReceiptsCount, data: count });
    };

    const onReportEvent = ({ succeeded, report, error }: ReportResult) => {
        const jobCreated: FlashbarProps.MessageDefinition[] = [
            {
                type: 'success',
                header: 'Report submitted',
                content: `Report creation started, you will be notified by email when the report is complete.\nReport ID: ${report?.id}`,
                dismissible: true,
                onDismiss: () => updateFlashbarItems([])
            }
        ];

        const jobFailed: FlashbarProps.MessageDefinition[] = [
            {
                type: 'error',
                header: 'Report creation failed',
                content: error,
                dismissible: true,
                onDismiss: () => updateFlashbarItems([])
            }
        ];

        updateFlashbarItems(succeeded ? jobCreated : jobFailed);
    };

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <ErrorRouter
                        errorStatusCode={applicationHealth.errorCode}
                        dashboardError={applicationHealth.accessError}
                    />
                ) : (
                    <>
                        <UpdateReceiptsAlert />
                        <br />
                        <PurchaseList
                            purchaseType={purchaseType}
                            onPanelChange={onPanelChange}
                            onPendingReceipts={onPendingReceipts}
                            onConfirmPurchase={onConfirmPurchase}
                            onReportEvent={onReportEvent}
                        />
                    </>
                )
            }
            notifications={<Flashbar items={flashbarItems} />}
            contentType="table"
            navigationHide={true}
            toolsOpen={panel !== undefined}
            toolsHide={panel === undefined}
            tools={panel}
            onToolsChange={dismissPanel}
            headerSelector="#merp-nav"
        />
    );
};
