export class AppNotification {
    constructor(
        public id: string,
        public content: string,
        public type: 'error' | 'warning' | 'info',
        public isDismissible: boolean,
        public isDiscardable: boolean,
        public isActive: boolean,
        public createdAt: number,
        public expiresAt: number
    ) {}

    public get isValid() {
        const now = Date.now();
        return this.isActive && this.expiresAt > now;
    }

    public static from(record: Record<string, unknown>): AppNotification {
        return new AppNotification(
            String(record.id ?? ''),
            String(record.content ?? ''),
            this.validateType(record.type),
            Boolean(record.isDismissible ?? true),
            Boolean(record.isDiscardable),
            Boolean(record.isActive),
            Number(record.createdAt ?? Date.now()),
            Number(record.expiresAt ?? Date.now() + 3600000)
        );
    }

    private static validateType(type: unknown): 'error' | 'warning' | 'info' {
        if (type === 'error' || type === 'warning' || type === 'info') {
            return type;
        }
        return 'info';
    }
}
