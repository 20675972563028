/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'modules/app/store';

import { AppLayout, Form, Header } from '@amzn/awsui-components-react';

import { RequestChangeContainer } from 'modules/budgetManagement/components/RequestChangeContainer';
import { RequestChangeTable } from 'modules/budgetManagement/components/RequestChangeTable';
import { requestChangeProcessor, uploadBudgetProcessor } from 'modules/budgetManagement/helper';
import { YearForecast } from 'modules/budgetManagement/model/YearForecast';
import { UploadErrorModal } from 'modules/budgetManagement/components/UploadErrorModal/UploadErrorModal';
import { yearForecastInputRowMapper } from 'modules/budgetManagement/mappers';
import { RequestChangeHeaders as headers } from 'modules/budgetManagement/model/RequestChangeHeaders';
import { useTask } from 'modules/budgetManagement/hooks';
import { ErrorRouter } from 'modules/error/components';
import { useHelpPanel } from 'modules/core/hooks';
import { dashboardPanels } from 'modules/purchase/constants';

export interface RequestChangeProps {
    budgetId?: string;
}

export const RequestChange = (props: RequestChangeProps) => {
    const { t } = useTranslation('budgetManagement');
    const { postTasks } = useTask();
    const { budgetId } = props;

    const { panel, dismissPanel } = useHelpPanel(dashboardPanels);

    const userClaims = useSelector((state: ApplicationState) => state.user);
    const userAlias = userClaims.alias;
    const firstName = userClaims.firstName || userAlias;
    const lastName = userClaims.lastName ?? '';

    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);

    const [showPreviewTable, setShowPreviewTable] = useState<boolean>(false);
    const [allowUpload, setAllowUpload] = useState<boolean>(false);
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);

    const [uplaodedYearForecast, setUplaodedYearForecast] = useState<YearForecast[]>();

    const [file, setFile] = useState<File>();
    const [fileUrl, setFileUrl] = useState<string>();

    const [uploadErrorModal, setUploadErrorModal] = useState<boolean>(false);

    const [tableItems, updateTableItems] = useState<any[]>();

    const getTitle = () => {
        return (
            <Header variant="h1">
                {t('requestChange.title', {
                    firstName: firstName,
                    lastName: lastName,
                    alias: userAlias
                })}
            </Header>
        );
    };

    const onFileUpload = async (uploadfile: File, fileUrl: string) => {
        if (!uploadfile || !fileUrl) return;

        setFile(uploadfile);
        setFileUrl(fileUrl);
        setShowPreviewTable(false);
        setUploadSuccess(false);
        setAllowUpload(true);

        await uploadBudgetProcessor.validateFile(fileUrl);
    };

    const mapToTableRowYearBudget = (records: any[]) => {
        const yearForecasts: YearForecast[] = [];

        records.sort(sortParsedFileRowsByCoaAndYear);

        let currentCOA = getCoa(records[0]);
        records.forEach((record, i) => {
            if (!record) return;

            const displayCoaInRow = i === 0 || currentCOA !== getCoa(record);
            const forecast = yearForecastInputRowMapper.mapToYearForecastForecast(record, displayCoaInRow);

            if (isRowUpdated(forecast)) yearForecasts.push(forecast);

            currentCOA = getCoa(record);
        });

        const result = yearForecasts.map((record, i) => ({
            number: 'uuid',
            rowId: i,
            selected: false,
            payload: record
        }));

        setUplaodedYearForecast(yearForecasts);

        return result;
    };

    const isRowUpdated = (forecast: YearForecast) => {
        const isCommentOrOwnerChanged = forecast.comment || forecast.assignedOwner;

        return forecast.id !== '' && forecast.owner !== '' && isCommentOrOwnerChanged;
    };

    const onPreview = () => {
        if (!fileUrl) return;

        if (uploadBudgetProcessor.getErrors().length > 0) {
            setUploadErrorModal(true);
            return;
        }

        const parsedFile = uploadBudgetProcessor.getParsedFile();

        updateTableItems(mapToTableRowYearBudget(parsedFile));

        setShowPreviewTable(true);
        setAllowUpload(false);
    };

    const onSendRequestClicked = async () => {
        if (!uplaodedYearForecast || !budgetId) return;

        const requestChangeTasks = uplaodedYearForecast.map(forecast =>
            requestChangeProcessor.createRequestChangeTask(forecast, budgetId, userAlias)
        );

        await postTasks(requestChangeTasks);

        setShowPreviewTable(false);
        setUploadSuccess(true);
        setAllowUpload(false);

        resetFileState();
    };

    const uploadErrorModalClose = () => {
        setUploadErrorModal(false);
        setShowPreviewTable(false);
        setAllowUpload(false);
        resetFileState();
        setUploadSuccess(true);
    };

    const resetFileState = () => {
        setFile(undefined);
        setFileUrl(undefined);
    };

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <ErrorRouter
                        errorStatusCode={applicationHealth.errorCode}
                        dashboardError={applicationHealth.accessError}
                    />
                ) : (
                    <>
                        {uploadErrorModal && (
                            <UploadErrorModal
                                onDismiss={uploadErrorModalClose}
                                errorMsg={uploadBudgetProcessor.getErrors()}
                            />
                        )}

                        <Form header={getTitle()}>
                            <RequestChangeContainer
                                uploadSuccess={uploadSuccess}
                                allowUpload={allowUpload}
                                onFileUpload={onFileUpload}
                                previewChanges={onPreview}
                                file={file}
                            />
                            <br />
                            {showPreviewTable && (
                                <RequestChangeTable
                                    items={tableItems || []}
                                    loading={false}
                                    onSendRequest={onSendRequestClicked}
                                />
                            )}
                        </Form>
                    </>
                )
            }
            contentType="table"
            navigationHide={true}
            toolsOpen={panel !== undefined}
            toolsHide={panel === undefined}
            tools={panel}
            onToolsChange={dismissPanel}
            headerSelector="#merp-nav"
        />
    );
};

function getCoa(record: any) {
    return `${record[headers.COMPANY_CODE]}-${record[headers.LOCATION_CODE]}-${record[headers.COST_CENTER]}-${
        record[headers.ACCOUNT_CODE]
    }-${record[headers.PRODUCT_LINE]}-${record[headers.CHANNEL_CODE]}-${record[headers.PROJECT_CODE]}-${
        record[headers.CUSTOM_SEGMENT]
    }`;
}

function sortParsedFileRowsByCoaAndYear(a: any, b: any) {
    return `${getCoa(a)}-${a[headers.YEAR]}` < `${getCoa(b)}-${b[headers.YEAR]}` ? -1 : 1;
}
