import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    Box,
    Button,
    Input,
    InputProps,
    NonCancelableCustomEvent,
    FormField
} from '@amzn/awsui-components-react';

import { FormSchema } from 'modules/mappings/models';
import style from '../OrgMappings/OrgMappings.module.scss';

interface NewOrgMappingsModalProps {
    onCancel: () => void;
    onSave: (newOrgName: string) => void;
}

export const NewOrgMappingsModal = (props: NewOrgMappingsModalProps) => {
    const { onCancel, onSave } = props;
    const { t } = useTranslation('mappings');
    const title = t('orgMappings.addNewOrg');

    const [newOrgName, setNewOrgName] = useState('');
    const { register, setValue, errors, formState } = FormSchema.getNewOrgUseForm();

    useEffect(() => {
        register({ name: 'newOrgName', type: 'custom' });
    }, [register]);

    const onNameChange = ({ detail: { value } }: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
        setNewOrgName(value);
        setValue('newOrgName', value, true);
    };

    const onModalSave = () => {
        if (formState.isValid) {
            onSave(newOrgName);
        }
    };

    const content = (
        <div className={style['modal-div']}>
            <p>{t('orgMappings.orgName')}</p>
            <FormField errorText={errors?.newOrgName?.message}>
                <Input onChange={onNameChange} className={style['input']} value={newOrgName} />
            </FormField>
        </div>
    );

    return (
        <Modal
            visible={true}
            header={title}
            onDismiss={onCancel}
            data-cy="newOrg"
            footer={
                <Box float="right">
                    <Button variant="link" onClick={onCancel} data-cy="newOrgCancel">
                        {t('cancel')}
                    </Button>
                    <Button variant="primary" disabled={!formState.isValid} onClick={onModalSave} data-cy="newOrgSave">
                        {t('orgMappings.saveThisOrg')}
                    </Button>
                </Box>
            }
        >
            {content}
        </Modal>
    );
};
