import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid } from '@amzn/awsui-components-react';

import { LineVersion } from 'modules/audit/models';
import { ChangedLineAmount } from '../ChangedLineAmount';
import { VersionTitle } from '../VersionTitle/VersionTitle';

import styles from '../EditedVersion/EditedVersion.module.scss';

export interface DeletedVersionProps {
    lineVersions: LineVersion[];
}

export const DeletedVersion = (props: DeletedVersionProps) => {
    const { lineVersions } = props;
    const lineVersion = lineVersions[0];
    const { t } = useTranslation('purchase');

    return (
        <Container header={<VersionTitle title={t('version.lineDeletedTitle')} lineVersion={lineVersion} />}>
            <div className={lineVersion.isRejected ? styles['transparent-box'] : ''}>
                <Grid gridDefinition={[{ colspan: 9 }, { colspan: 1 }, { colspan: 2 }]}>
                    <Box variant="strong">
                        {t('version.poLine')} {lineVersion.lineNumber}: {lineVersion.lineDescription}
                    </Box>
                    <div></div>
                    <ChangedLineAmount amount={lineVersion.changedLineAmount} currency={lineVersion.currencyCode} />
                </Grid>
            </div>
        </Container>
    );
};
