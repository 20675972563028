import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Button,
    Modal,
    TokenGroup,
    FormField,
    Input,
    Alert,
    TokenGroupProps,
    NonCancelableCustomEvent,
    InputProps,
    Box,
    SpaceBetween
} from '@amzn/awsui-components-react';

import { ApplicationState, getCreatedBy, getOwners, getPurchaseId, getRequestedBy } from 'modules/app/store';
import { papiService } from 'modules/purchase/services';
import { validateUserEditService } from 'modules/user/services';
import { FormSchema, Owner, OwnerAction, MspOwner } from '../../models';
import { AppConfig } from 'modules/core';

interface EditAspOwnerProps {
    visible: boolean;
    updateOwners: (updateOwners: MspOwner[]) => void;
    onCancel?: () => void;
}

export const EditAspOwners = (props: EditAspOwnerProps) => {
    const { visible, onCancel, updateOwners } = props;

    const { t } = useTranslation('purchase');

    const loggedUser = useSelector((state: ApplicationState) => state.user);
    const purchaseId = useSelector(getPurchaseId);
    const owners = useSelector(getOwners);
    const createdBy = useSelector(getCreatedBy);
    const requestedBy = useSelector(getRequestedBy);

    const userName = 'userAlias';
    const [alias, setAlias] = useState('');

    const [ownerMap, setOwnerActionMap] = useState<Map<string, OwnerAction>>(new Map([]));
    const [alertVisible, setAlertVisible] = useState(false);
    const [showCentralReceiverAlert, setCentralReceiverAlert] = useState(false);
    const [loading, setButtonLoading] = useState(false);

    const [mspOwners, setOwners] = useState((owners || []).map(login => ({ label: login })));

    const {
        errors,
        register,
        setValue,
        triggerValidation,
        clearError,
        setError,
        formState
    } = FormSchema.getOwnerUseForm();

    useEffect(() => {
        register({ name: userName, type: 'custom' });
    }, [register, userName]);

    const creators = () => {
        const creator = [];
        if (createdBy?.login) {
            const poLabel = `${t('owner.coupaPOOwner')} ${createdBy.login}`;
            creator.push({ label: poLabel, disabled: true });
        }
        if (requestedBy?.login) {
            const requesterLabel = `${t('owner.requestor')} ${requestedBy.login}`;
            creator.push({ label: requesterLabel, disabled: true });
        }
        return creator;
    };

    const setOwnerMap = (alias: string, action: OwnerAction) => {
        setOwnerActionMap(prev => {
            if (prev.has(alias)) {
                prev?.delete(alias);
            } else {
                prev?.set(alias, action);
            }
            return prev;
        });
    };

    const updateInputValue = ({ detail: { value } }: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
        setAlias(value);
        setValue(userName, value);
        triggerValidation(userName);
        setCentralReceiverAlert(false);
    };

    const onCancelPress = () => {
        if (onCancel) {
            clearError();
            onCancel();
        }
    };

    const handleDismissOwner = (event: NonCancelableCustomEvent<TokenGroupProps.DismissDetail>) => {
        const itemIndex = event.detail.itemIndex;
        const aliasRevoked = mspOwners[itemIndex].label;
        setOwners([...mspOwners.slice(0, itemIndex), ...mspOwners.slice(itemIndex + 1)]);
        setOwnerMap(aliasRevoked, 'REVOKE_OWNER');
    };

    const isValid = async (aliasName: string) => {
        const validAlias = await papiService.isValidAliasAsync(aliasName);
        const result = mspOwners?.find(owner => owner.label === aliasName);
        const isAliasCreator = aliasName === createdBy?.login || aliasName === requestedBy?.login;

        if (!validAlias || !formState.isValid) {
            setError(userName, 'manual', 'Alias is not valid');
            return false;
        }
        if (result || isAliasCreator) {
            setError(userName, 'manual', 'Alias is already an owner');
            return false;
        }

        return true;
    };

    const appendOwner = async () => {
        setButtonLoading(true);
        const aliasName = alias.trim().toLowerCase();
        const validAlias = await isValid(aliasName);

        if (validAlias) {
            const response = await validateUserEditService.validateUserEditAsync(purchaseId, aliasName);

            if (!response.validUserEdit) {
                setCentralReceiverAlert(true);
            }

            mspOwners.push({ label: aliasName });
            setOwners([...mspOwners]);
            setOwnerMap(aliasName, 'ADD_OWNER');
        }
        setButtonLoading(false);
    };

    const handleKeyDown = (event: NonCancelableCustomEvent<InputProps.KeyDetail>) => {
        if (event.detail.key === 'Enter') {
            appendOwner();
        }
    };

    const onSave = () => {
        const request = Owner.getOwnerRequest(ownerMap, loggedUser.alias);
        if (request.length > 0) {
            updateOwners(request);
        } else {
            setAlertVisible(true);
        }

        if (onCancel) {
            onCancel();
        }
    };

    const Footer = () => (
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={onCancelPress} data-cy="editOwnerCancel">
                    {t('owner.cancel')}
                </Button>
                <Button variant="primary" onClick={onSave} data-cy="editOwnerSave">
                    {t('owner.save')}
                </Button>
            </SpaceBetween>
        </Box>
    );

    return (
        <Modal
            visible={visible}
            size="large"
            header={t('owner.heading')}
            onDismiss={onCancelPress}
            footer={<Footer />}
            data-cy="editOwner"
        >
            {alertVisible && <Alert header={t('owner.alertMsg')} type="warning" />}
            {showCentralReceiverAlert && (
                <Alert
                    header={t('owner.centralReceiverWarningMsg', {
                        alias: alias.trim().toLowerCase(),
                        link: AppConfig.coupaAccessFormUrl
                    })}
                    type="warning"
                    action={
                        <Button onClick={() => window.open(AppConfig.coupaAccessFormUrl)}>
                            {t('owner.accessBtn')}
                        </Button>
                    }
                />
            )}
            <FormField
                label={t('owner.heading')}
                stretch={false}
                errorText={errors.userAlias?.message}
                secondaryControl={
                    <Button onClick={appendOwner} loading={loading}>
                        {t('owner.add')}
                    </Button>
                }
            >
                <Input
                    type="text"
                    name={userName}
                    value={alias}
                    placeholder="User alias"
                    onChange={e => updateInputValue(e)}
                    onKeyDown={event => handleKeyDown(event)}
                />
            </FormField>

            <TokenGroup items={mspOwners} onDismiss={handleDismissOwner}></TokenGroup>
            <TokenGroup items={creators()}></TokenGroup>
        </Modal>
    );
};
