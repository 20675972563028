import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';

import { logger } from 'modules/core';
import { purchaseService } from 'modules/purchase/services';
import { UpdatePurchaseRequest } from 'modules/purchase/models';

import {
    getLines,
    showErrorPage,
    updatePurchase,
    ApplicationState,
    resetSubmitStatus,
    updateSubmitStatus
} from 'modules/app/store';
import { ResourceResponse, SoftCloseResponse } from 'modules/core/model/response';

import { LINE_PAGE_SIZE } from 'modules/purchase/constants';

export function usePurchase(purchaseId: string) {
    const dispatch = useDispatch();

    const paginationObserver = useRef<IntersectionObserver>();
    const purchase = useSelector((state: ApplicationState) => state?.purchaseDetails?.purchase);

    const [purchaseLines, updateLines] = useState(
        useSelector((state: ApplicationState) => getLines(state.purchaseDetails, 0, LINE_PAGE_SIZE), shallowEqual)
    );

    const hasSmallerLines = (purchase?.lines.length || 0) < LINE_PAGE_SIZE;

    const [loading, setLoading] = useState(false);
    const [hasNextPage, updateHasNextPage] = useState(!hasSmallerLines);
    const [submitPurchaseInProgress, updateSubmitInProgress] = useState(false);

    const getPurchaseAsync = useCallback(async () => {
        const result = await purchaseService.getPurchaseAsync(purchaseId);
        if (result?.purchase === undefined && result?.status) {
            dispatch(showErrorPage({ errorCode: result?.status }));
        } else {
            setLoading(true);
            dispatch(updatePurchase(result.purchase));
            setLoading(false);
        }
    }, [purchaseId, dispatch]);

    const loadLinesRef = useCallback(
        (jsxElement: HTMLHeadingElement) => {
            if (loading) return;

            if (paginationObserver.current) {
                paginationObserver.current.disconnect();
            }
            paginationObserver.current = new IntersectionObserver(async entries => {
                if (entries[0].isIntersecting && hasNextPage) {
                    await getPaginatedPurchaseLines();
                }
            });

            if (jsxElement) paginationObserver.current.observe(jsxElement);
        },
        [loading, hasNextPage, getPaginatedPurchaseLines]
    );

    async function markFullyBilledAsync(purchaseId: string): Promise<SoftCloseResponse> {
        return await purchaseService.markFullyBilled(purchaseId);
    }

    async function updatePurchaseDetailsAsync(purchaseId: string, body: UpdatePurchaseRequest): Promise<void> {
        try {
            updateSubmitInProgress(true);
            dispatch(resetSubmitStatus());

            const response: ResourceResponse = await purchaseService.updatePurchaseDetailsAsync(purchaseId, body);
            const { result } = response;

            if (result) {
                getPurchaseAsync();
            }

            dispatch(updateSubmitStatus(result as boolean));
        } catch (error) {
            logger.error(error);
        } finally {
            updateSubmitInProgress(false);
        }
    }

    async function getPaginatedPurchaseLines(): Promise<void> {
        try {
            setLoading(true);

            const lineLength = purchase?.lines.length || 0;

            const fromLineNumber = purchaseLines.length;

            const toLength = fromLineNumber + LINE_PAGE_SIZE;
            const toLineNumber = toLength > lineLength ? lineLength : toLength;

            const lines = purchase?.lines.slice(fromLineNumber, toLineNumber);

            updateHasNextPage(toLineNumber < lineLength);

            updateLines(current => {
                const newItems = current || [];
                if (lines) {
                    newItems.push(...lines);
                }
                return newItems;
            });
        } catch (error) {
            logger.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!purchase && purchaseId) {
            getPurchaseAsync();
        }
    }, [getPurchaseAsync, purchase]);

    return {
        loading,
        hasNextPage,
        purchaseLines,
        hasSmallerLines,
        submitPurchaseInProgress,
        loadLinesRef,
        markFullyBilledAsync,
        getPaginatedPurchaseLines,
        updatePurchaseDetailsAsync
    };
}
