import React, { useState } from 'react';
import { AppLayout, Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MspTask, TaskAction } from '@amzn/merp-core/models/budgetManagement';
import { TaskStatus } from '@amzn/merp-core/models/budgetManagement/TaskStatus';
import { ApplicationState, isFinancialAnalyst } from 'modules/app/store';
import { ErrorRouter } from 'modules/error/components';
import { dashboardPanels } from 'modules/purchase/constants';
import { useHelpPanel } from 'modules/core/hooks';
import { FinancialAnalystDashboard } from './FinancialAnalystDashboard';
import { OwnerDashboard } from './OwnerDashboard';

export const BudgetDashboard = () => {
    const { t } = useTranslation('budgetManagement');

    const { panel, dismissPanel } = useHelpPanel(dashboardPanels);

    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);
    const isFA = useSelector(isFinancialAnalyst);

    const [flashbarItems, updateFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

    const onTasksUpdate = (task: MspTask) => {
        const changeRequestMessage = [
            {
                type: 'warning',
                header: t('budgetDashboard.changeRequestFlashTitle'),
                content: t('budgetDashboard.changeRequestFlashContent'),
                dismissible: true,
                onDismiss: () => updateFlashbarItems([])
            } as FlashbarProps.MessageDefinition
        ];

        if (task.action === TaskAction.BUDGET_CHANGE_REQUEST && task.status === TaskStatus.PENDING) {
            updateFlashbarItems(changeRequestMessage);
        }
    };

    const onBudgetApproved = () => {
        const budgetApprovedMessage = [
            {
                type: 'success',
                header: t('budgetDashboard.budgetApprovedFlashTitle'),
                dismissible: true,
                onDismiss: () => updateFlashbarItems([])
            } as FlashbarProps.MessageDefinition
        ];

        updateFlashbarItems(budgetApprovedMessage);
    };

    const onUploadComplete = (success: boolean) => {
        const uploadInfoMessage = [
            {
                type: 'info',
                header: t('budgetDashboard.budgetUploadFlashTitle'),
                content: t('budgetDashboard.budgetUploadFlashContent'),
                dismissible: true,
                onDismiss: () => updateFlashbarItems([])
            } as FlashbarProps.MessageDefinition
        ];

        const uploadErrorMessage = [
            {
                type: 'error',
                header: t('budgetDashboard.budgetUploadError'),
                dismissible: true,
                onDismiss: () => updateFlashbarItems([])
            } as FlashbarProps.MessageDefinition
        ];

        updateFlashbarItems(success ? uploadInfoMessage : uploadErrorMessage);
    };

    const onStartGuidancecycle = (success: boolean) => {
        const startSuccessMessage = [
            {
                type: 'success',
                header: t('budgetDashboard.startSuccess'),
                dismissible: true,
                onDismiss: () => updateFlashbarItems([])
            } as FlashbarProps.MessageDefinition
        ];

        const startErrorMessage = [
            {
                type: 'error',
                header: t('budgetDashboard.startFailure'),
                dismissible: true,
                onDismiss: () => updateFlashbarItems([])
            } as FlashbarProps.MessageDefinition
        ];

        updateFlashbarItems(success ? startSuccessMessage : startErrorMessage);
    };

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <ErrorRouter
                        errorStatusCode={applicationHealth.errorCode}
                        dashboardError={applicationHealth.accessError}
                    />
                ) : (
                    <>
                        {isFA ? (
                            <FinancialAnalystDashboard onStartGuidancecycle={onStartGuidancecycle} />
                        ) : (
                            <OwnerDashboard
                                onTasksUpdate={onTasksUpdate}
                                onUploadComplete={onUploadComplete}
                                onBudgetApproved={onBudgetApproved}
                            />
                        )}
                    </>
                )
            }
            notifications={<Flashbar items={flashbarItems} />}
            contentType="table"
            navigationHide={true}
            toolsOpen={panel !== undefined}
            toolsHide={panel === undefined}
            tools={panel}
            onToolsChange={dismissPanel}
            headerSelector="#merp-nav"
        />
    );
};
