import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Box, SpaceBetween } from '@amzn/awsui-components-react';

interface AssignOwnerWarningProps {
    onDismissed: () => void;
    onConfirmed: () => void;
}

export const AssignOwnerWarning = (props: AssignOwnerWarningProps) => {
    const { t } = useTranslation('budgetManagement');

    const { onConfirmed, onDismissed } = props;

    const onYesButtonClicked = () => {
        onConfirmed();
        onDismissed();
    };

    const Footer = () => (
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" onClick={onDismissed}>
                    {t('stepper.modal.no')}
                </Button>
                <Button variant="primary" onClick={onYesButtonClicked}>
                    {t('stepper.modal.yes')}
                </Button>
            </SpaceBetween>
        </Box>
    );

    return (
        <Modal visible={true} header={t('stepper.modal.title')} footer={<Footer />} onDismiss={onDismissed}>
            <div>{t('stepper.modal.content')}</div>
        </Modal>
    );
};
