import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Icon, IconProps, SpaceBetween } from '@amzn/awsui-components-react';

interface ReceivingStatusCellProps {
    status: 'NO_RECEIVING' | 'PARTIALLY_RECEIVED' | 'FULLY_RECEIVED';
}

export const ReceivingStatusCell = ({ status }: ReceivingStatusCellProps) => {
    const { t } = useTranslation('bulkReceiving');

    const variant: { [key: string]: IconProps.Variant } = {
        NO_RECEIVING: 'error',
        PARTIALLY_RECEIVED: 'subtle',
        FULLY_RECEIVED: 'success'
    };

    const name: { [key: string]: IconProps.Name } = {
        NO_RECEIVING: 'status-negative',
        PARTIALLY_RECEIVED: 'status-in-progress',
        FULLY_RECEIVED: 'status-positive'
    };

    const textColor: { [key: string]: BoxProps.Color } = {
        NO_RECEIVING: 'text-status-error',
        PARTIALLY_RECEIVED: 'text-status-inactive',
        FULLY_RECEIVED: 'text-status-success'
    };

    return (
        <SpaceBetween size="xxs" direction="horizontal">
            <Icon size="medium" variant={variant[status]} name={name[status]} />
            <Box color={textColor[status]}>{t(`receivingStatus.${status}`)}</Box>
        </SpaceBetween>
    );
};
