import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Box, Button } from '@amzn/awsui-components-react';

import style from '../OrgMappings/OrgMappings.module.scss';
import { OrgMapping } from 'modules/mappings/models';
import { OrgMappingsUpdatesRow } from '../OrgMappingsUpdatesRow/OrgMappingsUpdatesRow';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'modules/app/store';

interface OrgMappingsUpdatesModalProps {
    orgMappings: OrgMapping[];
    onCancel: () => void;
    onSave: () => void;
}

export const OrgMappingsUpdatesModal = (props: OrgMappingsUpdatesModalProps) => {
    const { onCancel, onSave, orgMappings } = props;
    const { t } = useTranslation('mappings');
    const title = t('savingChanges');

    const [updatedMappings, setUpdatedMappings] = useState<OrgMapping[]>([]);
    const [orgCount, setOrgCount] = useState(0);

    const { costCentersDetails } = useSelector((state: ApplicationState) => state.orgCostCenters);

    useEffect(() => {
        const updatedMappings: OrgMapping[] = [];
        const updatedCCs: Map<string, string[]> = new Map();

        costCentersDetails.forEach(costCenterDetails => {
            if (
                (costCenterDetails.isNewCostCenter() || costCenterDetails.isReassigned()) &&
                costCenterDetails.mappedOrg
            ) {
                const addedOrgCCs = updatedCCs.get(costCenterDetails.mappedOrg);
                const updatedOrcCCList = addedOrgCCs
                    ? addedOrgCCs.concat(costCenterDetails.costCenterName)
                    : [costCenterDetails.costCenterName];

                updatedCCs.set(costCenterDetails.mappedOrg, updatedOrcCCList);
            }
        });

        updatedCCs.forEach((value, key) => {
            const updatedMapping = {
                orgName: key,
                costCenters: value
            } as OrgMapping;
            updatedMappings.push(updatedMapping);
        });

        const updatedOrgCount = updatedMappings.reduce((sum, orgMapping) => sum + orgMapping.costCenters.length, 0);

        setOrgCount(updatedOrgCount);
        setUpdatedMappings(updatedMappings);
    }, [orgMappings]);

    const content = (
        <div className={style['modal-div']}>
            <b>{t('orgMappings.editsModalDescription', { orgCount })}</b>
            <div>
                {updatedMappings.map(orgMapping => (
                    <OrgMappingsUpdatesRow key={orgMapping.orgName} orgMapping={orgMapping} />
                ))}
            </div>
        </div>
    );

    return (
        <Modal
            visible={true}
            header={title}
            size="large"
            onDismiss={onCancel}
            data-cy="updateMappings"
            footer={
                <Box float="right">
                    <Button variant="link" onClick={onCancel} data-cy="updateMappingsCancel">
                        {t('cancel')}
                    </Button>
                    <Button variant="primary" onClick={onSave} data-cy="updateMappingsSave">
                        {t('saveChanges')}
                    </Button>
                </Box>
            }
        >
            {content}
        </Modal>
    );
};
