/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidV4 } from 'uuid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Wizard, WizardProps, Alert } from '@amzn/awsui-components-react';
import { ApplicationState } from 'modules/app/store';
import { useNavigate } from 'react-router-dom';

import { TableRow } from 'modules/core/model/TableRow';
import { useTask, useTableRowForecast } from 'modules/budgetManagement/hooks';
import { OwnerStatusRow } from 'modules/budgetManagement/model/OwnerStatusRow';
import { CoaCombinationTable } from '../CoaCombinationTable';
import { AssignCoaOwnerInput } from '../AssignCoaOwnerInput/AssignCoaOwnerInput';
import { AssignOwnerWarning } from '../AssignOwnerWarning/AssignOwnerWarning';
import { papiService } from 'modules/purchase/services';
import { MspTask, TaskAction, TaskStatus } from '@amzn/merp-core/models/budgetManagement';

import styles from './AssignOwnerStepper.module.scss';

export const AssignOwnerStepper = () => {
    const { t } = useTranslation('budgetManagement');
    const navigate = useNavigate();

    const userClaims = useSelector((state: ApplicationState) => state.user);
    const userAlias = userClaims.alias;

    if (!userAlias) {
        return <></>;
    }

    const { items, fetchForecasts } = useTableRowForecast();
    const { postTasks } = useTask();

    const [activeStep, setActiveStep] = useState<number>(0);
    const [requestedStep, setRequestedStep] = useState<number>();
    const [owner, updateOwner] = useState('');
    const [displayWarning, updateDisplayWarning] = useState<boolean>(false);
    const [selectedItems, updateSelectedItems] = useState<TableRow<OwnerStatusRow>[]>();
    const [inputErrorMsg, updateInputErrorMsg] = useState<string>();
    const [showAlert, setShowAlert] = useState(false);

    const steps = [
        {
            title: t('stepper.selectCoa'),
            content: (
                <>
                    <CoaCombinationTable
                        coaItems={items || []}
                        loading={false}
                        inStepOne={true}
                        selectedItems={selectedItems}
                        updateItems={updateSelectedItems}
                    />
                    <Alert onDismiss={() => setShowAlert(false)} visible={showAlert} header="Select a row."></Alert>
                </>
            )
        },
        {
            title: t('stepper.findOwner'),
            content: (
                <>
                    <CoaCombinationTable coaItems={selectedItems || []} loading={false} inStepOne={false} />
                    <AssignCoaOwnerInput owner={owner} updateOwner={updateOwner} errorText={inputErrorMsg} />
                </>
            )
        },
        {
            title: t('stepper.reviewSave'),
            content: (
                <CoaCombinationTable
                    coaItems={selectedItems || []}
                    loading={false}
                    inStepOne={false}
                    assignedOwner={owner}
                />
            )
        }
    ];

    const getNextButton = (step: number) => {
        return step === 0 ? t('stepper.assignOwner') : t('stepper.nextReview');
    };

    const i18nStrings = {
        nextButton: getNextButton(activeStep),
        cancelButton: t('stepper.cancel'),
        previousButton: t('stepper.back'),
        submitButton: t('stepper.submit'),
        stepNumberLabel: (stepNumber: number) => `Step ${stepNumber}`,
        collapsedStepsLabel: (stepNumber: number, stepsCount: number) => `Step ${stepNumber} of ${stepsCount}`
    };

    const isOwnerAssignedRowSelected = () => {
        const ownerAssignedSelectedItems =
            selectedItems?.filter(selectedItem => selectedItem.payload.owner !== undefined) ?? [];

        return ownerAssignedSelectedItems.length > 0;
    };

    const isCoaRowsNotSelected = () => {
        return selectedItems === undefined || selectedItems.length === 0;
    };

    const triggerAliasValidation = async (requestedStepIndex: number) => {
        const isAliasValid = await papiService.isValidAliasAsync(owner);

        if (isAliasValid) {
            updateInputErrorMsg(undefined);
            setActiveStep(requestedStepIndex);
        } else {
            updateInputErrorMsg('Alias entered is not valid.');
        }
    };

    const onNavigate = (detail: WizardProps.NavigateDetail) => {
        const { requestedStepIndex, reason } = detail;
        setRequestedStep(requestedStepIndex);
        setShowAlert(false);
        if (reason === 'previous' || reason === 'step') {
            setActiveStep(requestedStepIndex);
            return;
        }

        if (activeStep === 0 && isCoaRowsNotSelected()) {
            setShowAlert(true);
            return;
        }
        if (activeStep === 0 && isOwnerAssignedRowSelected()) {
            updateDisplayWarning(true);
        } else if (activeStep === 1) {
            triggerAliasValidation(requestedStepIndex);
        } else {
            setActiveStep(requestedStepIndex);
        }
    };

    const onWarningConfirmed = () => {
        setActiveStep(requestedStep ?? 0);
        updateDisplayWarning(false);
    };

    const onWarningDismissed = () => {
        updateDisplayWarning(false);
    };

    function generateMspTasks(forecasts: TableRow<OwnerStatusRow>[], owner: string) {
        const mspTasks: MspTask[] = [];

        for (let i = 0; i < forecasts.length; i++) {
            const mspTask = new MspTask();

            mspTask.id = uuidV4();
            mspTask.associatedEntity = 'Budget';
            mspTask.requestedBy = userAlias;
            mspTask.assignedTo = owner;
            mspTask.action = TaskAction.COMPLETE_BUDGET;
            mspTask.status = TaskStatus.PENDING;
            mspTask.forecastIds = [forecasts[i].payload.id];

            mspTasks.push(mspTask);
        }

        return mspTasks;
    }

    const onCancel = () => {
        navigate('/budget');
    };

    const onSubmit = async () => {
        await postTasks(generateMspTasks(selectedItems ?? [], owner));
        await fetchForecasts();

        navigate('/budget');
    };

    return (
        <>
            <h1 className={styles['assign-owner-title']}>{t('stepper.title')}</h1>
            <Wizard
                activeStepIndex={activeStep}
                i18nStrings={i18nStrings}
                steps={steps}
                onNavigate={event => onNavigate(event.detail)}
                onSubmit={onSubmit}
                onCancel={onCancel}
            />
            {displayWarning && <AssignOwnerWarning onConfirmed={onWarningConfirmed} onDismissed={onWarningDismissed} />}
        </>
    );
};
