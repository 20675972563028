import React from 'react';

import { useSmartERollUps } from 'modules/rollups/hooks';
import { SmartE } from 'modules/rollups/components/SmartE';

export const SmartEView = () => {
    const { loading, rollUps } = useSmartERollUps();

    return <SmartE items={rollUps} loading={loading} />;
};
