import React from 'react';
import { Modal, Button, Box, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

interface CancelModalProps {
    title: string;
    content: string;
    cancelFunction: () => void;
    backToEditFunction: () => void;
}

export const CancelModal = (props: CancelModalProps) => {
    const { title, content, cancelFunction, backToEditFunction } = props;

    const { t } = useTranslation('purchase');

    return (
        <Modal
            data-cy="cancelModal"
            visible={true}
            onDismiss={() => backToEditFunction()}
            header={title}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => backToEditFunction()}>{t('wrapper.back')}</Button>
                        <Button variant="primary" onClick={() => cancelFunction()}>
                            {t('dashboard.okBtnText')}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            {content}
        </Modal>
    );
};
