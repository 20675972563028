import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { FlashbarProps, ExpandableSection } from '@amzn/awsui-components-react';

import {
    Receipt,
    UpdateReceiptsReason,
    PurchaseLine,
    UpdateReceiptsRequest,
    PurchaseSubmitStatus,
    PurchaseStatus,
    ReceiptStatus
} from 'modules/purchase/models';
import { DeepCopy } from 'modules/purchase/class';
import { useChannelAllocations } from 'modules/channel/hooks';
import { PurchaseDetailPagePanels } from 'modules/purchase/constants';
import { useReceipts, useSubmitPurchase } from 'modules/purchase/hooks';
import { RequisitionDetailPagePanels } from 'modules/requisition/constants';
import { isQuantityBasedLine, isRequisition, updateLine } from 'modules/app/store';
import { useInvoiceLines } from 'modules/invoices/hooks/useInvoiceLines.hook';
import { EditPurchaseLine, LineWrapperHeader, ViewPurchaseLine } from 'modules/purchase/components';
import { purchaseService } from 'modules/purchase/services';

interface PurchaseLineWrapperProps {
    line: PurchaseLine;
    lineNumber: number;
    toggleExpand: boolean;
    purchaseStatus: PurchaseStatus;
    onEditModeToggled?: (editMode: boolean) => void;
    onUpdateFlashbar?: (message: FlashbarProps.MessageDefinition) => void;
    onFlashbarMessageDismissed?: () => void;
    onHelp?: (key: RequisitionDetailPagePanels | PurchaseDetailPagePanels, ...args: unknown[]) => void;
}

export const PurchaseLineWrapper = ({
    line,
    lineNumber,
    toggleExpand,
    purchaseStatus,
    onHelp,
    onUpdateFlashbar,
    onEditModeToggled,
    onFlashbarMessageDismissed
}: PurchaseLineWrapperProps) => {
    const [t] = useTranslation('purchase');

    const isPurchaseRequest = useSelector(isRequisition);

    const title = isPurchaseRequest ? 'PR' : 'PO';

    const [lineCopy, setLineCopy] = useState<PurchaseLine>(DeepCopy.purchaseLine(line));

    const { purchaseId } = lineCopy;

    const [editMode, setEditMode] = useState(false);
    const [expanded, expandSection] = useState(true);

    const { loadingInvoiceLines } = useInvoiceLines(purchaseId, lineNumber);
    const { onAutoAdjustChannelAllocations } = useChannelAllocations(line?.receipts);
    const { loadingReceipts } = useReceipts({ purchaseId, lineNumber, currentReceipts: line?.receipts });

    const {
        submitInProgress,
        purchaseSubmitted,
        reloadPurchaseAsync,
        updateConfirmAndSubmitReceiptsAsync
    } = useSubmitPurchase(purchaseId || '');

    useEffect(() => {
        setLineCopy(DeepCopy.purchaseLine(line));
    }, [line.receipts, line]);

    useEffect(() => {
        if (toggleExpand === expanded) {
            return;
        }

        expandSection(toggleExpand);
    }, [toggleExpand]);

    const updateReceipt = (receipts: Receipt[]) => {
        setLineCopy(line => {
            const copy = DeepCopy.purchaseLine(line);
            copy.receipts = receipts || [];
            //also check for valid allocations, etc here
            return copy;
        });
    };

    useEffect(() => {
        if (!onEditModeToggled) return;

        onEditModeToggled(editMode);
    }, [editMode]);

    useEffect(() => {
        if (purchaseSubmitted.status === PurchaseSubmitStatus.NOT_SUBMITTED || !onUpdateFlashbar) {
            return;
        }

        const submitSuccess: FlashbarProps.MessageDefinition = {
            type: 'success',
            dismissible: true,
            header: isPurchaseRequest ? t('flashPRTitle') : t('flashPOTitle'),
            content: isPurchaseRequest ? t('flashPRContent') : t('flashPOContent'),
            onDismiss: onFlashbarMessageDismissed
        };

        const { reason, status } = purchaseSubmitted;

        const submitFailed: FlashbarProps.MessageDefinition = {
            type: 'error',
            dismissible: true,
            header: t('flashTitleError', { purchaseType: `${title}` }),
            content: reason ? reason : t('flashContentError', { purchaseType: `${title}` }),
            onDismiss: onFlashbarMessageDismissed
        };

        const flashbar = status === PurchaseSubmitStatus.SUBMIT_SUCCESS ? submitSuccess : submitFailed;

        onUpdateFlashbar(flashbar);
    }, [purchaseSubmitted, t, isPurchaseRequest, title]);

    const onUpdateReasonChange = (reason: UpdateReceiptsReason) => {
        setLineCopy(line => {
            const copy = DeepCopy.purchaseLine(line);
            copy.reason = reason;

            return copy;
        });
    };

    const revertChanges = async () => {
        onUpdateReasonChange(new UpdateReceiptsReason());

        setEditMode(false);

        await reloadPurchaseAsync();
        updateReceipt(line.receipts);
    };

    const filterRemovableReceipts = (line: PurchaseLine) => {
        const copy = DeepCopy.purchaseLine(line);
        copy.receipts = copy.receipts.filter(r => r.confirmStatus || r.receiptStatus !== ReceiptStatus.PENDING_VOID);
        setLineCopy(copy);
        return copy;
    };

    async function updateLineCopyFromDB(line: PurchaseLine): Promise<PurchaseLine> {
        const copy = DeepCopy.purchaseLine(line);
        const receiptResult = await purchaseService.getReceiptsAsync(purchaseId, lineNumber);
        copy.receipts = receiptResult.receipts;
        setLineCopy(copy);
        return copy;
    }

    const dispatch = useDispatch();

    const onSaveClicked = async () => {
        const updateRequest = new UpdateReceiptsRequest(isQuantityBasedLine(lineCopy), lineCopy, lineCopy.reason);
        await updateConfirmAndSubmitReceiptsAsync([updateRequest]);
        const newCopy = await updateLineCopyFromDB(lineCopy);
        dispatch(updateLine(filterRemovableReceipts(newCopy)));

        setEditMode(false);
    };

    const onInfoClicked = () => {
        if (onHelp) {
            onHelp('P_LINE_HELP');
        }
    };

    const sortByTime = (a: Receipt, b: Receipt) => a.receiptTime - b.receiptTime;

    const onEditClicked = (editMode: boolean) => {
        lineCopy.receipts?.sort(sortByTime);

        setEditMode(editMode);
    };

    return (
        <ExpandableSection
            key={line.lineNumber}
            headerText={
                <LineWrapperHeader
                    title={title}
                    line={lineCopy}
                    purchaseStatus={purchaseStatus}
                    editMode={editMode}
                    expanded={expanded}
                    loading={loadingReceipts}
                    onInfoClicked={onInfoClicked}
                />
            }
            variant="container"
            expanded={expanded}
            onChange={e => expandSection(e.detail.expanded)}
        >
            {editMode ? (
                <EditPurchaseLine
                    line={lineCopy}
                    isSubmitInProgress={submitInProgress}
                    loadingInvoiceLines={loadingInvoiceLines}
                    onHelp={onHelp}
                    onCancel={revertChanges}
                    onSave={onSaveClicked}
                    onReceiptsChange={updateReceipt}
                    onUpdateReasonChange={onUpdateReasonChange}
                    onAutoAdjustChannelAllocations={onAutoAdjustChannelAllocations}
                />
            ) : (
                <ViewPurchaseLine
                    lineNumber={lineNumber}
                    loadingReceipts={loadingReceipts}
                    onHelp={onHelp}
                    onEdit={() => onEditClicked(true)}
                />
            )}
        </ExpandableSection>
    );
};
