import React from 'react';
import { Popover, Icon, ColumnLayout, Box } from '@amzn/awsui-components-react';

interface NameTagProps {
    login: string;
    firstName: string;
    lastName: string;
}

export const NameTagPopOver = ({ login, firstName, lastName }: NameTagProps) => {
    const contentJSX = (
        <ColumnLayout>
            <Box margin={{ vertical: 'xxs' }}>
                <div>
                    <img src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${login}`} alt="avatar" />
                    <div>
                        <Box padding={{ horizontal: 's' }}>
                            <div>
                                <a
                                    href={`https://phonetool.amazon.com/users/${login}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    PhoneTool
                                    <Icon name="external" />
                                </a>
                                <br />
                                <Box variant="span" color="text-status-info">
                                    {login}@amazon.com
                                </Box>
                            </div>
                        </Box>
                    </div>
                </div>
            </Box>
        </ColumnLayout>
    );

    return (
        <Popover size="large" position="bottom" header={`${firstName} ${lastName}`} content={contentJSX}>
            {firstName} {lastName}
        </Popover>
    );
};
