import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@amzn/awsui-components-react';

import { EditChannelDropDown } from '../EditChannelDropDown';
import { ChannelAllocationInput } from '../ChannelAllocationInput';
import { ChannelSpend } from 'modules/channel/models/ChannelSpend';
import { channelList } from 'modules/channel/constants';

import styles from './EditChannelSpendModalRows.module.scss';

interface EditChannelSpendModalRowsProps {
    channelAllocations: ChannelSpend[];
    onModalChannelAdd: () => void;
    updateSaveButton?: (formState: boolean) => void;
    onModalChannelRemove: (onModalChannelRemove: string) => void;
    onModalChannelUpdate: (onModalChannelUpdate: string, channelIndex: number) => void;
    onModalChannelAllocationChange: (channelIndex: number, amount: number) => void;
}

export const EditChannelSpendModalRows = (props: EditChannelSpendModalRowsProps) => {
    const {
        channelAllocations,
        onModalChannelRemove,
        onModalChannelUpdate,
        onModalChannelAllocationChange,
        onModalChannelAdd,
        updateSaveButton
    } = props;

    let selectedChannels = channelAllocations?.map(receiptChannelAllocation => receiptChannelAllocation.type) || [];
    let channelOptionsList = channelList.filter(channel => !selectedChannels.includes(channel));

    useEffect(() => {
        selectedChannels = channelAllocations?.map(receiptChannelAllocation => receiptChannelAllocation.type) || [];
        channelOptionsList = channelList.filter(channel => !selectedChannels.includes(channel));
    }, [channelAllocations]);

    const { t } = useTranslation('channelSpend');

    const isInvalidInput = (allocationAmount: number) => {
        return allocationAmount < 0 || isNaN(allocationAmount);
    };

    const FirstChannelAllocation = ({ allocation }: { allocation: ChannelSpend }) => (
        <div key={`channel-allocation-cell-${allocation.type}`} className={styles['channel-allocation-cell']}>
            <Box
                color={isInvalidInput(allocation.amount) ? 'text-status-error' : 'inherit'}
                fontSize="body-m"
                variant="span"
                textAlign="center"
            >
                {allocation.amount}
            </Box>
        </div>
    );

    const disableAddButton =
        channelAllocations !== undefined && channelAllocations.find(cs => cs.type === '') !== undefined;

    return (
        <>
            {(channelAllocations || []).map((channelAllocation, channelIndex) => (
                <div
                    key={`channel-Dropdown-${channelAllocation.type}`}
                    className={`${styles['channel-allocation-row']}`}
                >
                    <div className={`${styles['channel-allocation-cell']}`}>
                        <EditChannelDropDown
                            selectedOption={channelAllocation.type}
                            channelOptions={channelOptionsList}
                            onChange={channelSelection => onModalChannelUpdate(channelSelection, channelIndex)}
                        />
                    </div>
                    {channelIndex === 0 ? (
                        <FirstChannelAllocation allocation={channelAllocation} />
                    ) : (
                        <div
                            key={`channel-allocation-cell-${channelAllocation.type}-${channelIndex}`}
                            className={styles['channel-allocation-cell']}
                        >
                            <div className={styles['channel-allocation-input-cell']}>
                                <ChannelAllocationInput
                                    channelAllocation={channelAllocation}
                                    channelIndex={channelIndex}
                                    onModalChannelAllocationChange={onModalChannelAllocationChange}
                                    updateSaveButton={updateSaveButton}
                                />
                            </div>
                        </div>
                    )}
                    <div className={styles['channel-allocation-cell']}>
                        <Button variant="link" onClick={() => onModalChannelRemove(channelAllocation.type)}>
                            {t('remove')}
                        </Button>
                    </div>
                </div>
            ))}
            <div className={`${styles['channel-allocation-cell']}`}>
                <Button data-cy="addChannel" onClick={onModalChannelAdd} disabled={disableAddButton}>
                    {t('addChannel')}
                </Button>
            </div>
        </>
    );
};
