/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useMatch, useLocation } from 'react-router-dom';
import { Box, ButtonDropdown, ButtonDropdownProps } from '@amzn/awsui-components-react';

import { AppConfig } from 'modules/core';
import { ApplicationState, resetErrorPage } from 'modules/app/store';
import { ReactComponent as LogoSvg } from '../../../../assets/logo.svg';

import styles from './Navbar.module.scss';

export const NavBar = () => {
    const GLOBAL_USER_LDAP_GROUP_NAME = 'fintech-msp-global';
    const linkStyle = styles.links;

    const { t } = useTranslation('purchase');

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const dispatch = useDispatch();

    const userClaims = useSelector((state: ApplicationState) => state.user);

    const isGlobalUserOnly = userClaims?.ldap?.includes(GLOBAL_USER_LDAP_GROUP_NAME) || false;

    const [budgetLinkStyle, setBudgetLinkStyle] = useState(styles['not-active']);

    const pathMatches = useCallback((path: string, href: string, pattern = /purchase(Order|Request)/) => {
        const pathMatch = path?.match(pattern);
        const refMatch = href.match(pattern);

        return pathMatch && refMatch && pathMatch[0] === refMatch[0];
    }, []);

    const menuItems = [
        {
            id: '/purchaseRequest',
            text: t('navBar.purchaseRequest')
        },
        {
            id: '/purchaseOrder',
            text: t('navBar.purchaseOrder')
        },
        {
            id: '/receiving',
            text: t('navBar.receiving')
        },
        {
            id: '_blank',
            text: t('navBar.userGuides')
        },
        {
            id: '_blank',
            text: t('navBar.needHelp')
        }
    ];

    const budgetItems = [
        {
            id: '/budget',
            text: t('navBar.dashboard')
        }
    ];

    const mappingItems = [
        {
            id: '/rollups',
            text: t('navBar.dashboard')
        }
    ];

    const globalUserMappingItems = mappingItems.concat([
        {
            id: '/mappings/countryAndOrgMapping',
            text: t('navBar.countryAndOrg')
        }
    ]);

    const teamManagementItems = [
        {
            id: '/onboarding/mycoas',
            text: t('navBar.myCOAs')
        }
    ];

    const superUserMappingItems = mappingItems.concat([
        {
            id: '/mappings/channelMeasurements',
            text: t('navBar.smartE')
        },
        {
            id: '/mappings/bigOChannelMappings',
            text: t('navBar.bigOChannel')
        }
    ]);

    const item = menuItems.find(item => pathMatches(pathname as string, item.id));
    const [selection, updateSelection] = useState(item || menuItems[0]);

    useEffect(() => {
        function setBudgetNavStyle(href: string) {
            const pattern = /budget/;
            const isActiveBudget = pathMatches(pathname as string, href, pattern);
            const styleName = isActiveBudget ? styles['active'] : styles['not-active'];
            setBudgetLinkStyle(styleName);
        }

        setBudgetNavStyle(pathname || '/budget');
    }, [pathname, pathMatches, setBudgetLinkStyle]);

    useEffect(() => {
        if (selection.text === t('navBar.needHelp')) {
            window.open(AppConfig.troubleTicketLink, selection.id);
        }
        if (selection.text === t('navBar.userGuides')) {
            window.open(AppConfig.userGuidesLink, selection.id);
        }
    }, [selection, t]);

    const disableError = () => {
        dispatch(resetErrorPage());
    };

    const onDropdownSelection = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
        const selection = menuItems.find(s => s.id === event.detail.id);
        if (selection) {
            updateSelection(selection);
            navigate(selection.id);
        }
    };

    const NavLink = (props: any) => {
        const { to } = props;
        const matchedValue = useMatch(to);
        const isMatchingPurchase = pathMatches(pathname as string, to);
        const isCurrentPurchase = matchedValue !== null || isMatchingPurchase;

        return (
            <Link
                {...props}
                className={
                    isCurrentPurchase ? `${linkStyle} ${styles['active']}` : `${linkStyle} ${styles['not-active']}`
                }
                onClick={disableError}
            />
        );
    };

    const DropdownNavLink = (props: any) => {
        return <Link {...props} onClick={disableError} />;
    };

    const FinanceManagement = () => {
        const mappingMenuItems = isGlobalUserOnly ? globalUserMappingItems : superUserMappingItems;

        return (
            <div className={styles.dropdown}>
                <Box padding={{ horizontal: 'xl' }}>
                    <button className={styles.dropdownButton}>{t('navBar.financeManagement')}</button>
                    <div className={styles.dropdownContent}>
                        {mappingMenuItems.map((item, index) => (
                            <div key={item.id}>
                                <div>
                                    <DropdownNavLink to={item.id} className={styles.dropdownContentLink}>
                                        {item.text}
                                    </DropdownNavLink>
                                </div>
                                {index < mappingMenuItems.length - 1 && <hr className={styles.dropdownContentHr} />}
                            </div>
                        ))}
                    </div>
                </Box>
            </div>
        );
    };

    const BudgetManagement = () => {
        return (
            <div className={styles.dropdown}>
                <Box padding={{ horizontal: 'xxl' }}>
                    <button className={`${styles.dropdownButton} ${budgetLinkStyle}`}>
                        {t('navBar.budgetManagement')}
                    </button>
                    <div className={styles.dropdownContent}>
                        {budgetItems.map((item, index) => {
                            return (
                                <div key={item.id}>
                                    <div>
                                        <DropdownNavLink to={item.id} className={styles.dropdownContentLink}>
                                            {item.text}
                                        </DropdownNavLink>
                                    </div>
                                    {index < budgetItems.length - 1 && <hr className={styles.dropdownContentHr} />}
                                </div>
                            );
                        })}
                    </div>
                </Box>
            </div>
        );
    };

    const TeamManagement = () => {
        return (
            <div className={styles.dropdown}>
                <Box padding={{ horizontal: 'xxl' }}>
                    <button className={`${styles.dropdownButton} ${budgetLinkStyle}`}>
                        {t('navBar.teamManagement')}
                    </button>
                    <div className={styles.dropdownContent}>
                        {teamManagementItems.map((item, index) => {
                            return (
                                <div key={item.id}>
                                    <div>
                                        <DropdownNavLink to={item.id} className={styles.dropdownContentLink}>
                                            {item.text}
                                        </DropdownNavLink>
                                    </div>
                                    {index < teamManagementItems.length - 1 && (
                                        <hr className={styles.dropdownContentHr} />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </Box>
            </div>
        );
    };

    return (
        <nav id="merp-nav" className={styles.navbar}>
            <div className={styles.brand}>
                <LogoSvg className={styles.logo} />
            </div>
            <div className={styles.dropdownMenu}>
                <ButtonDropdown items={menuItems} onItemClick={onDropdownSelection}>
                    {selection.text}
                </ButtonDropdown>
            </div>
            {menuItems
                .filter(items => items.text !== t('navBar.needHelp') && items.text !== t('navBar.userGuides'))
                .map(filteredItems => (
                    <div key={filteredItems.id}>
                        <Box padding={{ horizontal: 'xxl' }}>
                            <NavLink to={filteredItems.id} className={linkStyle}>
                                {filteredItems.text}
                            </NavLink>
                        </Box>
                    </div>
                ))}

            <FinanceManagement />
            <BudgetManagement />
            <TeamManagement />

            <div className={styles['flexGap']}></div>
            <div>
                <Box padding={{ horizontal: 'xl' }}>
                    <a
                        href={AppConfig.userGuidesLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={linkStyle + ' ' + styles['not-active']}
                    >
                        {t('navBar.userGuides')}
                    </a>
                </Box>
            </div>
            <div>
                <Box padding={{ horizontal: 'xl' }}>
                    <a
                        href={AppConfig.troubleTicketLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={linkStyle + ' ' + styles['not-active']}
                    >
                        {t('navBar.needHelp')}
                    </a>
                </Box>
            </div>
        </nav>
    );
};
