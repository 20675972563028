import React, { useRef, useState } from 'react';
import { AppLayout, Button, Header, Pagination, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';

import { ErrorRouter } from 'modules/error/components';
import { ApplicationState } from 'modules/app/store';
import {
    COACombinationTablePropertyFilter,
    COACombinationTable,
    COACombinationTableHeader
} from 'modules/onboarding/components';
import { useCOACombinations, useCoaForExport } from 'modules/onboarding/hooks';
import { CSVTemplate } from 'modules/dashboard/models';
import { CustomerCoaReportBuilder } from 'modules/onboarding/model/';

export const CustomerCOACombinationsPage = () => {
    const { t } = useTranslation('onboarding');
    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);

    const {
        numberOfTableRowCOACombinations,
        currentTableRowCOACombinations,
        currentTablePageIndex,
        numberOfTablePages,
        loading,
        hasNextPage,
        setTeamName,
        setCurrentTablePageIndex,
        resetCOACombinationsList
    } = useCOACombinations();

    const { coaCombinations, fetchCOACombinationsAcrossTable } = useCoaForExport();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const csvLink = useRef<any>();
    const [csvExport, setCsvExport] = useState<CSVTemplate | undefined>(undefined);

    const onDownloadCoaCombinationsClicked = async () => {
        await fetchCOACombinationsAcrossTable();

        const csvTemplate = new CustomerCoaReportBuilder().useRecords(coaCombinations).build();

        setCsvExport(csvTemplate);
        csvLink.current?.link.click();
    };

    const header: JSX.Element = (
        <>
            {csvExport && (
                <CSVLink
                    data={csvExport.getCSVData()}
                    headers={csvExport.getHeaders()}
                    filename={'coa_export.csv'}
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                />
            )}
            <COACombinationTableHeader
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button id="download" variant="primary" onClick={onDownloadCoaCombinationsClicked}>
                            {t('CustomerCOACombinationsPage.downloadButtonText')}
                        </Button>
                    </SpaceBetween>
                }
                numberOfTotalTableRowCOACombinations={numberOfTableRowCOACombinations}
            />
        </>
    );
    const pagination: JSX.Element = (
        <Pagination
            pagesCount={numberOfTablePages}
            currentPageIndex={currentTablePageIndex}
            openEnd={hasNextPage}
            disabled={loading}
            onChange={event => setCurrentTablePageIndex(event.detail.currentPageIndex)}
        />
    );

    const propertyFilter = (
        <COACombinationTablePropertyFilter resetList={resetCOACombinationsList} setTeamName={setTeamName} />
    );

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <div className="errorRouter">
                        <ErrorRouter errorStatusCode={applicationHealth.errorCode} />
                    </div>
                ) : (
                    <React.Fragment>
                        <Header variant="h1" description={t('CustomerCOACombinationsPage.description')}>
                            {t('CustomerCOACombinationsPage.title')}
                        </Header>
                        <COACombinationTable
                            loading={loading}
                            header={header}
                            filter={propertyFilter}
                            items={currentTableRowCOACombinations}
                            pagination={pagination}
                        />
                    </React.Fragment>
                )
            }
            toolsHide={true}
            navigationHide={true}
        />
    );
};
