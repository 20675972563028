import React from 'react';
import { Box, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { LineVersion } from 'modules/audit/models';

import styles from './VersionTitle.module.scss';

export interface VersionTitleProps {
    title: string;
    lineVersion: LineVersion;
    showDetails?: boolean;
}

export const VersionTitle = (props: VersionTitleProps) => {
    const { title, lineVersion, showDetails } = props;
    const { t } = useTranslation('purchase');
    const versionDetailsUrl = `/audit/${lineVersion.purchaseId}/${lineVersion.lineVersion}$${lineVersion.lineNumber}`;

    return (
        <Header
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    {showDetails && (
                        <Box variant="strong">
                            <Link href={versionDetailsUrl}>{t('version.seeDetails')}</Link>
                        </Box>
                    )}
                    <span className={styles['line-version-status-title']}>
                        {lineVersion.isPendingApproval && t('version.pendingApprovalTitle')}
                        {lineVersion.isRejected && t('version.rejectedTitle')}
                    </span>
                </SpaceBetween>
            }
        >
            {title}
        </Header>
    );
};
