import React, { useEffect, useState } from 'react';

import style from '../BigOChannelMappings/BigOChannelMappings.module.scss';
import { ObjectiveOptions } from 'modules/bigRockObjective/constants';
import { channelList } from 'modules/channel/constants';
import { toSelectOptions } from 'modules/mappings/constants';
import { useTranslation } from 'react-i18next';
import { CoaMapping } from 'modules/mappings/models';
import { Box, Button, Header, Modal, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import { OptionDefinition } from 'modules/core/types';

interface EditBigOChannelMappingModalProps {
    mapping: CoaMapping;
    onCancel: () => void;
    onSave: (mapping: CoaMapping) => void;
}

export const EditBigOChannelMappingModal = (props: EditBigOChannelMappingModalProps) => {
    const { mapping, onCancel, onSave } = props;
    const { t } = useTranslation('mappings');
    const title = t('bigOChannelMappings.updateCoaCombination');
    const [bigObjective, setBigObjective] = useState<SelectProps.Option>();
    const [channelType, setChannelType] = useState<SelectProps.Option>();

    const bigOLabels = Object.values(ObjectiveOptions).map(option => t(`dropdown.bigOList.${option}`));
    const bigOOptions = toSelectOptions(bigOLabels, Object.values(ObjectiveOptions));

    const channelLabels = channelList.map(option => t(`dropdown.channelsList.${option}`));
    const channelOptions = toSelectOptions(channelLabels, channelList);

    useEffect(() => {
        bigOOptions.forEach(option => {
            if (option.value === mapping.bigObjective) setBigObjective(option);
        });
        channelOptions.forEach(option => {
            if (option.value === mapping.channelType) setChannelType(option);
        });
    }, [mapping, setBigObjective, setChannelType]);

    const onModalSave = () => {
        mapping.bigObjective = bigObjective?.value || '';
        mapping.channelType = channelType?.value || '';
        onSave(mapping);
    };

    const dropdown = (
        dropdownType: string,
        options: SelectProps.Option[],
        selectedOption?: SelectProps.Option,
        selectionSetter?: React.Dispatch<React.SetStateAction<OptionDefinition | undefined>>
    ) => (
        <div className={style['dropdown-div-attributes']}>
            <p>{t(`bigOChannelMappings.${dropdownType}`)}</p>
            <Select
                onChange={({ detail }) => selectionSetter?.(detail.selectedOption)}
                selectedOption={selectedOption || null}
                options={options}
                empty={t('bigOChannelMappings.noOptions')}
                data-cy={dropdownType}
            />
        </div>
    );

    const coaDetail = (coaName: string, coaValue: string) => (
        <div>
            <div>
                <p>{t(`bigOChannelMappings.${coaName}`)}</p>
            </div>
            <div>{coaValue}</div>
        </div>
    );

    const bigOChannelDropdowns = (
        <SpaceBetween direction="horizontal" size="s">
            {dropdown('bigObjective', bigOOptions, bigObjective, setBigObjective)}
            {dropdown('channelType', channelOptions, channelType, setChannelType)}
        </SpaceBetween>
    );

    const coaDetails = (
        <SpaceBetween direction="horizontal" size="xxl">
            {coaDetail('company', mapping.company)}
            {coaDetail('location', mapping.location)}
            {coaDetail('costCenter', mapping.costCenter)}
            {coaDetail('glAccount', mapping.glAccount)}
            {coaDetail('productLine', mapping.productLine)}
            {coaDetail('channel', mapping.channel)}
            {coaDetail('project', mapping.project)}
            {coaDetail('customEighthSegment', mapping.customEighthSegment)}
        </SpaceBetween>
    );

    return (
        <Modal
            visible={true}
            header={title}
            onDismiss={onCancel}
            size="large"
            footer={
                <Box float="right">
                    <Button variant="link" onClick={onCancel}>
                        {t('cancel')}
                    </Button>
                    <Button variant="primary" onClick={onModalSave}>
                        {t('bigOChannelMappings.saveThisCombination')}
                    </Button>
                </Box>
            }
        >
            <div className={style['modal-div']}>
                <Header variant="h2">{t('bigOChannelMappings.coaValue')}</Header>
                <Box variant="p">{t('bigOChannelMappings.coaModalDescLine1')}</Box>
                <Box variant="p">{t('bigOChannelMappings.coaModalDescLine2')}</Box>
                {coaDetails}
                <br />
                {bigOChannelDropdowns}
            </div>
        </Modal>
    );
};
