import React from 'react';
import { Box } from '@amzn/awsui-components-react';

interface EmptyListContentProps {
    header: string;
    message: string;
}

export const EmptyListContent = ({ header, message }: EmptyListContentProps) => {
    return (
        <Box textAlign="center">
            <Box margin={{ bottom: 'xs' }} padding={{ top: 's' }} variant="awsui-key-label">
                <b>{header}</b>
            </Box>
            <Box margin={{ bottom: 's' }} variant="p">
                {message}
            </Box>
        </Box>
    );
};
