/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';

import { BudgetDashboard, RequestChange, FinancialAnalystApproving, AssignOwner } from '../views';
import { isFinancialAnalyst, resetErrorPage, showErrorPage } from 'modules/app/store';

export function BudgetRoutes(): JSX.Element {
    const { pathname } = useLocation();

    const dispatch = useDispatch();
    const isFA = useSelector(isFinancialAnalyst);

    const PageRoutes = {
        Budget: 'budget',
        Owners: '/assignOwners',
        RequestChange: '/requestChanges/:budgetId',
        Plan: '/plan/:entityId'
    };

    useEffect(() => {
        if (pathname !== '/budget' && !isFA) {
            dispatch(showErrorPage({ errorCode: 401 }));
        } else {
            dispatch(resetErrorPage());
        }
    }, [pathname, isFA]);

    return (
        <Routes>
            <Route path="*" element={<BudgetDashboard />} />
            <Route path={PageRoutes.Owners} element={<AssignOwner />} />
            <Route path={PageRoutes.RequestChange} element={<RequestChange />} />
            <Route path={PageRoutes.Plan} element={<FinancialAnalystApproving />} />
        </Routes>
    );
}
