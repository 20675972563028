import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { useSelector } from 'react-redux';

import { RequisitionDetailPagePanels } from 'modules/requisition/constants';
import { isRequisition, hasBeenApprovedByMsp } from 'modules/app/store';

import style from './SpendDistribution.module.scss';

interface SpendDistributionHeaderProps {
    withLegend?: boolean;
    isQuantityBased?: boolean;
    onHelp?: (key: RequisitionDetailPagePanels) => void;
}

export const SpendDistributionHeader = (props: SpendDistributionHeaderProps) => {
    const { t } = useTranslation('purchase');
    const showLegend = props.withLegend || false;
    const isQuantityBased = props.isQuantityBased;

    const isPurchaseRequest = useSelector(isRequisition);
    const isCreationPage = isPurchaseRequest && useSelector(hasBeenApprovedByMsp) === false;

    const onInfoClicked = () => {
        if (props.onHelp) {
            props.onHelp('SPEND_HELP');
        }
    };

    const amountLineHeaders = () => {
        return (
            <Grid
                gridDefinition={[
                    { colspan: { xxs: 12, m: 3 } },
                    { colspan: { xxs: 12, m: 2 } },
                    { colspan: { xxs: 12, m: 2 } },
                    { colspan: { xxs: 12, m: 2 } },
                    { colspan: { xxs: 12, m: 2 } }
                ]}
            >
                <div>
                    <Box variant="strong">
                        <SpaceBetween direction="horizontal" size="s">
                            <Box variant="span">{t('line.estimatedReceiptDate')}</Box>
                            <Link onFollow={onInfoClicked}>{t('info')}</Link>
                        </SpaceBetween>
                    </Box>
                    {showLegend && (
                        <Box color="text-label" fontSize="body-s">
                            {t('receipts.headerLegend')}
                        </Box>
                    )}
                </div>
                <div>
                    <Box variant="strong">{t('line.receipt')}</Box>
                </div>
                <div>{!isPurchaseRequest && <Box variant="strong">{t('line.receiptStatus')}</Box>}</div>
                {!isCreationPage && (
                    <div className={style['with-padding']}>
                        <Box variant="strong">{t('channels.title')}</Box>
                    </div>
                )}
                {!isPurchaseRequest && !isCreationPage && (
                    <div className={style['with-padding']}>
                        <Box variant="strong">{t('invoices.matched')}</Box>
                    </div>
                )}
            </Grid>
        );
    };

    const quantityLineHeaders = () => {
        return (
            <Grid
                gridDefinition={[
                    { colspan: { xxs: 12, m: 3 } },
                    { colspan: { xxs: 12, m: 2 } },
                    { colspan: { xxs: 12, m: 2 } },
                    { colspan: { xxs: 12, m: 2 } },
                    { colspan: { xxs: 12, m: 2 } }
                ]}
            >
                <div>
                    <Box variant="strong">
                        <SpaceBetween direction="horizontal" size="s">
                            <Box variant="span">{t('line.estimatedReceiptDate')}</Box>
                            <Link onFollow={onInfoClicked}>{t('info')}</Link>
                        </SpaceBetween>
                    </Box>
                </div>
                <div>
                    <Box variant="strong">{t('line.quantity')}</Box>
                </div>
                <div></div>
                {!isCreationPage && (
                    <div>
                        <Box variant="strong">{t('line.totalAmount')}</Box>
                    </div>
                )}
                <div>{!isPurchaseRequest && <Box variant="strong">{t('line.receiptStatus')}</Box>}</div>
            </Grid>
        );
    };
    return isQuantityBased ? quantityLineHeaders() : amountLineHeaders();
};
