import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Header,
    SpaceBetween,
    ButtonDropdown,
    NonCancelableCustomEvent,
    ButtonDropdownProps
} from '@amzn/awsui-components-react';

import { TableRow } from 'modules/core/model';
import { ApplicationState } from 'modules/app/store';
import { ReportResult } from 'modules/reports/model';
import { PurchaseIndex } from '@amzn/merp-core/models/purchase';
import { YesNoModal, InfoModal } from 'modules/core/components';
import { useReports } from 'modules/reports/hooks/useReports.hook';
import { usePurchasesForExport, usePurchasesForExportV2 } from 'modules/dashboard/hooks';
import { PurchaseExportBuilder, PurchaseExportBuilderV2, CSVTemplate } from 'modules/dashboard/models';

interface PurchaseListHeaderProps {
    isPR?: boolean;
    loading: boolean;
    tableItemCount?: number;
    selectedItems: TableRow<PurchaseIndex>[];
    onModalDismissed?: () => void;
    onConfirmPurchases: () => void;
    onReportEvent?: (event: ReportResult) => void;
}

export const PurchaseListHeader = (props: PurchaseListHeaderProps) => {
    const { isPR, loading, selectedItems, tableItemCount, onConfirmPurchases, onModalDismissed, onReportEvent } = props;

    const [isErrorModalVisible, showErrorModal] = useState(false);
    const [isConfirmModalVisible, showConfirmModal] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const csvLink = useRef<any>();
    const user = useSelector((state: ApplicationState) => state.user);

    const { createInProgress, createReportAsync, report } = useReports();

    const [csvExport, setCsvExport] = useState<CSVTemplate | undefined>(undefined);

    const customFieldReportId = 'exportPoListReportCustomField';
    const syncReportEventIds = ['exportPoListReport', customFieldReportId];

    const { t } = useTranslation('purchase');

    const {
        invoiceLines,
        bulkPurchaseOrders,
        updatePurchaseOrders,
        updateInvoiceLines,
        getPaginatedBulkPurchase
    } = usePurchasesForExport();

    const { getPaginatedBulkPurchaseV2 } = usePurchasesForExportV2();
    const [loadingExport, setLoadingExport] = useState(false);

    useEffect(() => {
        if (onReportEvent && report) onReportEvent(report);
    }, [report]);

    const onConfirmClick = () => {
        if (selectedItems.length) {
            showConfirmModal(true);
            return;
        }

        showErrorModal(true);
    };

    const onBulkEditClick = () => {
        return window.open('/bulkEdit', '_blank');
    };

    const onDismiss = () => {
        showConfirmModal(false);

        if (onModalDismissed) {
            onModalDismissed();
        }
    };

    const onConfirmRecords = () => {
        showConfirmModal(false);
        onConfirmPurchases();
    };

    const downloadData = async (includeCustomField: boolean) => {
        const isESCall = process.env.REACT_APP_USER_PO_EXPORT_FLAG === 'true';
        if (isESCall) {
            downloadDataV2(includeCustomField);
            return;
        }
        setLoadingExport(true);

        await getPaginatedBulkPurchase(true);

        const csvTemplate = new PurchaseExportBuilder().useRecords(bulkPurchaseOrders, invoiceLines).build();
        setCsvExport(csvTemplate);

        csvLink.current?.link.click();

        updatePurchaseOrders([]);

        updateInvoiceLines([]);

        setLoadingExport(false);
    };

    const downloadDataV2 = async (includeCustomField: boolean) => {
        setLoadingExport(true);

        const results = await getPaginatedBulkPurchaseV2(true, includeCustomField);

        const csvTemplate = new PurchaseExportBuilderV2()
            .withCustomField(includeCustomField)
            .useRecords(results)
            .build();
        setCsvExport(csvTemplate);

        csvLink.current?.link.click();

        setLoadingExport(false);
    };

    const isSyncReport = (eventId: string): boolean => {
        return syncReportEventIds.indexOf(eventId) >= 0;
    };

    const ReportsButton = () => {
        const isSuperUser = user.ldap?.includes('fintech-msp-reports') || false;

        const generalReportItems = [
            { id: 'exportPoListReport', text: t('dashboard.exportPoList'), disabled: loadingExport },
            {
                id: customFieldReportId,
                text: t('dashboard.exportPoListCustomField'),
                disabled: loadingExport
            },
            {
                id: 'exportMECReports',
                text: t('dashboard.exportMECReports'),
                disabled: createInProgress
            },
            {
                id: 'exportPastMonthReports',
                text: t('dashboard.exportPastMonthReports'),
                disabled: createInProgress
            },
            {
                id: 'exportPostCloseReports',
                text: t('dashboard.exportPostCloseReports'),
                disabled: createInProgress
            },
            {
                id: 'exportCreditMemoReport',
                text: t('dashboard.exportCreditMemoReport'),
                disabled: createInProgress
            },
            {
                id: 'exportPurchaseConfirmationReport',
                text: t('dashboard.exportPurchaseConfirmationReport'),
                disabled: createInProgress
            }
        ];

        const allReportsItems = [
            {
                id: 'channelSpendReports',
                text: t('dashboard.exportChannelSpend'),
                disabled: createInProgress
            },
            {
                id: 'guidanceCycleReport',
                text: t('dashboard.guidanceCycleReport'),
                disabled: createInProgress
            }
        ];

        allReportsItems.push(...generalReportItems);

        const onDropdownSelection = (event: NonCancelableCustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
            const eventId = event.detail.id;

            isSyncReport(eventId) ? downloadData(eventId === customFieldReportId) : createReportAsync(eventId);
        };

        return (
            <ButtonDropdown
                loading={loadingExport || createInProgress}
                onItemClick={onDropdownSelection}
                items={isSuperUser ? allReportsItems : generalReportItems}
            >
                {t('dashboard.downloadReports')}
            </ButtonDropdown>
        );
    };

    return (
        <>
            <InfoModal
                visible={isErrorModalVisible}
                title={t('dashboard.confirmErrorTitle')}
                content={t('dashboard.confirmErrorDescription')}
                onDismiss={() => showErrorModal(false)}
                onOk={() => showErrorModal(false)}
            />
            <YesNoModal
                visible={isConfirmModalVisible}
                title={t('dashboard.doubleConfirmTitle')}
                content={t('dashboard.doubleConfirmDescription', { count: selectedItems.length })}
                onDismiss={onDismiss}
                noFunction={onDismiss}
                yesFunction={onConfirmRecords}
            />
            <Header
                variant="h3"
                actions={
                    !isPR && (
                        <SpaceBetween size="s" direction="horizontal">
                            <Button onClick={onBulkEditClick}> {t('dashboard.bulkUpload')} </Button>
                            <ReportsButton />
                            {csvExport && (
                                <CSVLink
                                    data={csvExport.getCSVData()}
                                    headers={csvExport.getHeaders()}
                                    filename={`${user.alias}_asp_export.csv`}
                                    className="hidden"
                                    ref={csvLink}
                                    target="_blank"
                                />
                            )}
                            <Button
                                variant="primary"
                                disabled={isPR}
                                onClick={onConfirmClick}
                                data-cy="purchaseListHeaderConfirmBtn"
                            >
                                {t('dashboard.confirm')}
                            </Button>
                        </SpaceBetween>
                    )
                }
                counter={tableItemCount && !loading ? `(${tableItemCount})` : undefined}
            >
                <>{isPR ? 'PR' : 'PO'}</>
            </Header>
        </>
    );
};
