import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Button, Box } from '@amzn/awsui-components-react';

interface ConfirmPurchaseFailedModalProps {
    failedPurchaseIds?: string[];
    onDismiss?: () => void;
}

export const ConfirmPurchaseFailedModal = (props: ConfirmPurchaseFailedModalProps) => {
    const { t } = useTranslation('purchase');

    const { failedPurchaseIds, onDismiss } = props;

    function onDismissPress() {
        if (onDismiss) {
            onDismiss();
        }
    }

    const Footer = () => (
        <Box float="right">
            <Button variant="primary" onClick={() => onDismissPress()}>
                {t('ok')}
            </Button>
        </Box>
    );

    return (
        <Modal onDismiss={onDismissPress} visible={true} header={t('dashboard.modal.title')} footer={<Footer />}>
            {t('dashboard.modal.content')}
            <ul>
                {failedPurchaseIds?.map(id => {
                    return <li key={id}>{id}</li>;
                })}
            </ul>
        </Modal>
    );
};
