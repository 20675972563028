import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, NonCancelableCustomEvent, InputProps, Box } from '@amzn/awsui-components-react';

import { InvoiceAllocation } from 'modules/invoices/models';
import { FormSchema } from 'modules/purchase/models';
import { I18nFormatter } from 'modules/core/class';

import styles from './EditInvoiceModal.module.scss';

interface EditInvoiceModalInputProps {
    allocationIndex: number;
    inputValue: number;
    totalInvoiceAmount?: number;
    allocation: InvoiceAllocation;
    onChange: (amount: number, index: number, formState: boolean) => void;
    updateInputAllocation: (amount: number, index: number) => void;
    validateAvailableAmount: (allocation: InvoiceAllocation, allocationIndex: number) => boolean;
    calculateOverflowAmount: (allocation: InvoiceAllocation, allocationIndex: number) => number;
}

export const EditInvoiceModalInput = (props: EditInvoiceModalInputProps) => {
    const {
        inputValue,
        allocation,
        allocationIndex,
        totalInvoiceAmount,
        onChange,
        updateInputAllocation,
        validateAvailableAmount,
        calculateOverflowAmount
    } = props;

    const { t } = useTranslation('purchase');
    const { register, setValue, errors, formState } = FormSchema.getInvoiceAllocationUseForm();

    const [inputText, setInputText] = useState(inputValue ? inputValue.toString() : '');
    const [isInputValid, setIsInputValid] = useState(false);
    const [overflowAmount, setOverflowAmount] = useState(0);

    const msg = 'invoices.editModal.overflowMessage';

    const overflowAmountMsg = t(msg, {
        totalAmount: I18nFormatter.amount(t, totalInvoiceAmount || 0.0),
        overflowAmount: I18nFormatter.amount(t, overflowAmount || 0.0)
    });

    const onAmountChange = ({ detail: { value } }: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
        setInputText(value);
        setValue('allocationAmount', Number(value), true);

        onChange(Number(value), allocationIndex, formState.isValid);
        if (formState.isValid) {
            updateInputAllocation(Number(value), allocationIndex);

            setIsInputValid(validateAvailableAmount(allocation, allocationIndex));
            setOverflowAmount(calculateOverflowAmount(allocation, allocationIndex));
        }
    };

    useEffect(() => {
        register({ name: 'allocationAmount', type: 'custom' });
        setValue('allocationAmount', Number(inputText), true);
    }, [register]);

    useEffect(() => {
        setIsInputValid(validateAvailableAmount(allocation, allocationIndex));
        setOverflowAmount(calculateOverflowAmount(allocation, allocationIndex));
    }, []);

    return (
        <div className={styles['table-cell-amount']}>
            <Input
                onChange={onAmountChange}
                value={inputText}
                invalid={!isInputValid || (formState.dirty && !formState.isValid)}
            />
            <Box color="text-status-error" fontSize="body-s" variant="span" textAlign="center">
                {!isInputValid ? overflowAmountMsg : errors?.allocationAmount?.message}
            </Box>
        </div>
    );
};
