import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input, Box, NonCancelableCustomEvent, InputProps } from '@amzn/awsui-components-react';

import { Receipt } from 'modules/purchase/models';
import { DeepCopy } from 'modules/purchase/class';
import { useSelector } from 'react-redux';
import { isRequisition } from 'modules/app/store';

export interface EditReceiptAmountProps {
    isQuantityBased: boolean;
    receipt: Receipt;
    index: number;
    onChanged: (receipt: Receipt) => void;
}

export const EditReceiptAmount = (props: EditReceiptAmountProps) => {
    const { isQuantityBased, receipt, onChanged, index } = props;

    const { register, setValue, triggerValidation, errors } = useFormContext();
    const amountName = `amount[${index}]`;
    const quantityName = `quantity[${index}]`;
    const isPurchaseRequest = useSelector(isRequisition);

    const setInputValue = () => {
        setValue(amountName, receipt.receiptAmount, true);
        setValue(quantityName, receipt.quantity, true);

        triggerValidation(amountName);
        triggerValidation(quantityName);
    };

    useEffect(() => {
        register({ name: isQuantityBased ? quantityName : amountName, type: 'custom' });
    }, [register, isQuantityBased ? quantityName : amountName]);

    const useEffectOnRegister = (setInput: () => void) => useEffect(setInput, [register, amountName, quantityName]);
    useEffectOnRegister(setInputValue);

    const onAmountUpdated = ($event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
        const { value } = $event.detail;

        updateAmount(value);
    };

    const onQuantityUpdated = ($event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
        const { value } = $event.detail;

        updateQuantity(value);
    };

    const updateAmount = (value: string) => {
        setValue(amountName, value);
        triggerValidation(amountName);
        const newReceipt = DeepCopy.receipt(receipt);
        newReceipt.receiptAmount = (value as unknown) as number;
        onChanged(newReceipt);
    };

    const updateQuantity = (value: string) => {
        setValue(quantityName, value);
        triggerValidation(quantityName);

        const newReceipt = DeepCopy.receipt(receipt);
        newReceipt.quantity = (value as unknown) as number;
        onChanged(newReceipt);
    };

    const editDisabled = !receipt.canEditReceipt && receipt.receiptStatus != null && !isPurchaseRequest;

    return (
        <div>
            {isQuantityBased && (
                <>
                    <Input
                        data-cy="spendQuantity"
                        name={quantityName}
                        value={receipt.quantity?.toString() ?? ''}
                        onChange={onQuantityUpdated}
                        invalid={!receipt.hasValidQuantity}
                        disabled={editDisabled}
                    />
                    <Box color="text-status-error" fontSize="body-s" variant="div">
                        {errors?.quantity && errors?.quantity[index]?.message}
                    </Box>
                </>
            )}
            {!isQuantityBased && (
                <>
                    <Input
                        data-cy="spendAmount"
                        name={amountName}
                        value={receipt.receiptAmount.toString()}
                        onChange={onAmountUpdated}
                        invalid={!receipt.hasValidReceiptAmount}
                        disabled={editDisabled}
                    />
                    <Box color="text-status-error" fontSize="body-s" variant="div">
                        {errors?.amount && errors?.amount[index]?.message}
                    </Box>
                </>
            )}
        </div>
    );
};
