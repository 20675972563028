import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnLayout, Container, Grid, Header } from '@amzn/awsui-components-react';

import { LineVersion } from 'modules/audit/models';
import { NameCard } from 'modules/audit/components/NameCard';
import { ConfirmVersionLine } from 'modules/audit/components/ConfirmVersionLine';

import {} from './ConfirmVersion.module.scss';

export interface ConfirmVersionProps {
    lineVersions: LineVersion[];
}

export const ConfirmVersion = (props: ConfirmVersionProps) => {
    const { lineVersions } = props;
    const { t } = useTranslation('purchase');

    return (
        <div className={'confirm_version'}>
            <Container header={<Header variant="h2">{t('version.confirmedTitle')}</Header>}>
                <Grid gridDefinition={[{ colspan: 1 }, { colspan: 9 }]}>
                    <NameCard
                        login={lineVersions[0].updaterLogin}
                        firstName={lineVersions[0].updaterFirstName}
                        lastName={lineVersions[0].updaterLastName}
                    />
                    <div>
                        <ColumnLayout columns={1}>
                            {lineVersions.map(lineVersion => (
                                <ConfirmVersionLine key={lineVersion.lineNumber} lineVersion={lineVersion} />
                            ))}
                        </ColumnLayout>
                    </div>
                </Grid>
            </Container>
        </div>
    );
};
