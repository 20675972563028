/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';

import { Dashboard } from 'modules/dashboard/views';
import { PurchaseDetailPage } from 'modules/purchase/views/purchaseDetail';
import { usePurchase } from 'modules/purchase/hooks';

export function PoRoutes(): JSX.Element {
    const PageRoutes = {
        PurchaseId: ':purchaseId',
        BasePO: '/purchaseOrder/*',
        PurchaseOrder: 'purchaseOrder'
    };

    const PurchaseOrderWrapper = () => {
        const { purchaseId } = useParams();
        usePurchase(purchaseId!);

        return <PurchaseDetailPage />;
    };

    return (
        <Routes>
            <Route path="*" element={<Dashboard />} />
            <Route path={PageRoutes.PurchaseId} element={<PurchaseOrderWrapper />} />
        </Routes>
    );
}
