/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';

import { Dashboard } from 'modules/dashboard/views';
import { PurchaseDetailPage } from 'modules/purchase/views/purchaseDetail';
import { usePurchase } from 'modules/purchase/hooks';

export function RequisitionRoutes(): JSX.Element {
    const PageRoutes = {
        Base: '/',
        BasePR: '/purchaseRequest/*',
        PurchaseId: ':purchaseId',
        BasePO: '/purchaseOrder/*',
        PurchaseRequest: 'PurchaseRequest',
        PurchaseOrder: 'purchaseOrder',
        Preferences: '/preferences',
        Audit: '/audit/:purchaseId'
    };

    const RequisitionWrapper = () => {
        const { purchaseId } = useParams();
        usePurchase(purchaseId!);

        return <PurchaseDetailPage />;
    };

    return (
        <Routes>
            <Route path="*" element={<Dashboard />} />
            <Route path={PageRoutes.PurchaseId} element={<RequisitionWrapper />} />
        </Routes>
    );
}
