import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { CustomerCOACombinationsPage, CustomerTeamListPage } from 'modules/onboarding/views';

export const OnboardingRoutes = (): JSX.Element => {
    const PageRoutes = {
        MyCOAs: '/mycoas',
        MyTeams: '/myteams'
    };

    return (
        <Routes>
            <Route path={PageRoutes.MyCOAs} element={<CustomerCOACombinationsPage />} />
            <Route path={PageRoutes.MyTeams} element={<CustomerTeamListPage />} />
        </Routes>
    );
};
