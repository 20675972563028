import React from 'react';
import { AppLayout, Header, Alert } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ErrorRouter } from 'modules/error/components';
import { ApplicationState } from 'modules/app/store';
import { CoaScopeTable } from '../components/CoaScopeTable';
import { RuleContent } from '../components/RuleContent';
import {
    mockCustomSelectionsMap,
    mockedCustomFieldsStringList,
    mockCoaCombinationList
} from '__mocks__/MockedCustomSelectionModel';

export const CustomFieldAndSelection = () => {
    const { t } = useTranslation('customCategory');
    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <div className="errorRouter">
                        <ErrorRouter errorStatusCode={applicationHealth.errorCode} />
                    </div>
                ) : (
                    <>
                        <Alert statusIconAriaLabel="Info" type="info" header="Feature in Pilot Release">
                            Please note this feature is currently in a Pilot Release. It is intended only for Pilot
                            Release customers. If you are a Pilot Release customer, then please use this tool and report
                            back any issues, bugs, or suggestions to the ASP team. Thank you for your understanding as
                            we work towards releasing this feature to a broader customer audience.
                        </Alert>

                        <>
                            <Header variant="h1" description={t('dashboard.content')}>
                                {t('dashboard.title')}
                            </Header>
                            <CoaScopeTable items={mockCoaCombinationList} loading={false} />
                            <RuleContent
                                customFields={mockedCustomFieldsStringList}
                                customSelections={mockCustomSelectionsMap}
                            />
                        </>
                    </>
                )
            }
            toolsHide={true}
            navigationHide={true}
        />
    );
};
