import React from 'react';
import { Button, Box, SpaceBetween, Header } from '@amzn/awsui-components-react';

import style from '../BigOChannelMappings/BigOChannelMappings.module.scss';
import { useTranslation } from 'react-i18next';

interface BigOChannelMappingsHeaderProps {
    mappingsCount: number;
    onAddCoaModalOpen: () => void;
}

export const BigOChannelMappingsHeader = (props: BigOChannelMappingsHeaderProps) => {
    const { mappingsCount, onAddCoaModalOpen } = props;
    const { t } = useTranslation('mappings');

    return (
        <div>
            <Header
                variant="h2"
                counter={`(${mappingsCount})`}
                actions={
                    <Box float="right" className={style['table-header-div']}>
                        <SpaceBetween direction="horizontal" size="m">
                            <Button variant="normal" onClick={onAddCoaModalOpen}>
                                {t('bigOChannelMappings.addCoaCombination')}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                {t('bigOChannelMappings.coaCombination')}
            </Header>
        </div>
    );
};
