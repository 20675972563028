import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Header } from '@amzn/awsui-components-react';

import { LineVersion } from 'modules/audit/models';
import { NameCard } from '../NameCard';

import {} from './SoftClosedVersion.module.scss';

export interface SoftClosedVersionProps {
    lineVersions: LineVersion[];
}

export const SoftClosedVersion = (props: SoftClosedVersionProps) => {
    const { lineVersions } = props;
    const { t } = useTranslation('purchase');

    return (
        <div className="audit_soft_closed_version">
            <Container header={<Header variant="h2">{t('version.poSoftClosedTitle')}</Header>}>
                <Grid gridDefinition={[{ colspan: 1 }, { colspan: 9 }]}>
                    <NameCard
                        login={lineVersions[0].updaterLogin}
                        firstName={lineVersions[0].updaterFirstName}
                        lastName={lineVersions[0].updaterLastName}
                    />
                    <Box variant="strong">{`PO was soft closed by ${lineVersions[0].updaterLogin}`}</Box>
                </Grid>
            </Container>
        </div>
    );
};
