import { useCallback, useEffect, useState } from 'react';

import { AppNotification } from '../model';
import { appNotificationService } from '../services';

const REFRESH_INTERVAL = 30 * 60 * 1000; // 30 min

export const useAppNotifications = () => {
    const [notifications, setNotifications] = useState<AppNotification[]>([]);

    const fetchNotifications = useCallback(async () => {
        const notifications = await appNotificationService.getNotifications();
        setNotifications(notifications);
    }, []);

    useEffect(() => {
        fetchNotifications();
        const intervalId = setInterval(fetchNotifications, REFRESH_INTERVAL);

        return () => clearInterval(intervalId);
    }, []);

    const discardNotification = useCallback((notification: AppNotification) => {
        if (!notification.isDiscardable) {
            return;
        }

        appNotificationService.discardNotification(notification);
        setNotifications(prevNotifications => prevNotifications.filter(n => n.id !== notification.id));
    }, []);

    return { notifications, discardNotification };
};
