import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Header, Form, Table, Link, AppLayout, Flashbar, FlashbarProps, Grid, Box } from '@amzn/awsui-components-react';

import { CoaMapping } from 'modules/mappings/models';
import { ApplicationState } from 'modules/app/store';
import { ErrorRouter } from 'modules/error/components';
import { AddCoaModal } from '../AddCoaModal/AddCoaModal';
import { useBigOChannelMappings } from 'modules/mappings/hooks';
import { CoaDropdownOptions } from 'modules/mappings/models/CoaDropdownOptions';
import { BigOChannelMappingsHeader } from '../BigOChannelMappingsHeader/BigOChannelMappingsHeader';
import { EditBigOChannelMappingModal } from '../EditBigOChannelMappingModal/EditBigOChannelMappingModal';
import { DeleteBigOChannelMappingModal } from 'modules/mappings/components/DeleteBigOChannelMappingModal';

export const BigOChannelMappings = () => {
    const { t } = useTranslation('mappings');
    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);
    const [flashbarItems, updateFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

    const [addCoaModal, setAddCoaModal] = useState(false);
    const [editMappingModal, setEditMappingModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [mappingUpdated, setMappingUpdated] = useState<CoaMapping>();

    const {
        addNewMappingsAsync,
        saveUpdatedMappings,
        deleteRemovedMappingsAsync,
        setRepeatedMapping,
        bigOChannelMappings,
        repeatedMapping,
        coaDropdownOptions,
        submitSuccess
    } = useBigOChannelMappings();

    useEffect(() => {
        if (submitSuccess.status === 'NOT_SUBMITTED') {
            return;
        }
        const successMsg: FlashbarProps.MessageDefinition = {
            type: 'success',
            header: t('bigOChannelMappings.flashTitle'),
            dismissible: true,
            onDismiss: () => updateFlashbarItems([])
        };

        const failureMsg: FlashbarProps.MessageDefinition = {
            type: 'error',
            header: t('bigOChannelMappings.flashErrorTitle'),
            content: submitSuccess.reason,
            dismissible: true,
            onDismiss: () => updateFlashbarItems([])
        };

        const flashbar = submitSuccess.status !== 'SUBMIT_FAILURE' ? successMsg : failureMsg;

        updateFlashbarItems([flashbar]);
    }, [submitSuccess, t]);

    function checkRepeatingCOAString(newMapping: CoaMapping) {
        let repeatMappingsExist = false;
        bigOChannelMappings.forEach(existingMapping => {
            repeatMappingsExist = repeatMappingsExist || newMapping.getCOAString === existingMapping.getCOAString;
        });

        return repeatMappingsExist;
    }

    const saveNewCoaModal = (coaString: string, bigObjective: string, channel: string) => {
        const newMapping = new CoaMapping(coaString, bigObjective, channel);

        const repeatMappingsExist = checkRepeatingCOAString(newMapping);
        setRepeatedMapping(repeatMappingsExist);

        if (!repeatMappingsExist) {
            addNewMappingsAsync(newMapping);
            setAddCoaModal(false);
        }
    };

    const updateMappingEdit = (mapping: CoaMapping) => {
        saveUpdatedMappings(mapping);
        setEditMappingModal(false);
    };

    const openEditModal = (mapping: CoaMapping) => {
        setEditMappingModal(true);
        setMappingUpdated(mapping);
    };

    const openDeleteModal = (mapping: CoaMapping) => {
        setShowDeleteModal(true);
        setMappingUpdated(mapping);
    };

    const deleteMapping = (mapping: CoaMapping) => {
        deleteRemovedMappingsAsync(mapping.id);
        setShowDeleteModal(false);
    };

    const tableRowActions = (mapping: CoaMapping) => (
        <div>
            <Link onFollow={() => openEditModal(mapping)}>{t('bigOChannelMappings.edit')}</Link>
            &nbsp;&nbsp;
            <Link onFollow={() => openDeleteModal(mapping)}>{t('bigOChannelMappings.remove')}</Link>
        </div>
    );

    const columnDefinitions = [
        {
            id: 'company',
            header: t('bigOChannelMappings.company'),
            cell: (item: CoaMapping) => item.company
        },
        {
            id: 'location',
            header: t('bigOChannelMappings.location'),
            cell: (item: CoaMapping) => item.location
        },
        {
            id: 'costCenter',
            header: t('bigOChannelMappings.costCenter'),
            cell: (item: CoaMapping) => item.costCenter
        },
        {
            id: 'glAccount',
            header: t('bigOChannelMappings.glAccount'),
            cell: (item: CoaMapping) => item.glAccount
        },
        {
            id: 'product',
            header: t('bigOChannelMappings.productLine'),
            cell: (item: CoaMapping) => item.productLine
        },
        {
            id: 'channel',
            header: t('bigOChannelMappings.channel'),
            cell: (item: CoaMapping) => item.channel
        },
        {
            id: 'project',
            header: t('bigOChannelMappings.project'),
            cell: (item: CoaMapping) => item.project
        },
        {
            id: 'customEighthSegment',
            header: t('bigOChannelMappings.customEighthSegment'),
            cell: (item: CoaMapping) => item.customEighthSegment
        },
        {
            id: 'bigObjective',
            header: t('bigOChannelMappings.bigObjective'),
            cell: (item: CoaMapping) => t(`dropdown.bigOList.${item.bigObjective}`)
        },
        {
            id: 'channelType',
            header: t('bigOChannelMappings.channelType'),
            cell: (item: CoaMapping) => t(`dropdown.channelsList.${item.channelType}`)
        },
        {
            id: 'action',
            header: t('bigOChannelMappings.action'),
            cell: tableRowActions
        }
    ];

    const EmptyTable = () => (
        <Box textAlign="center">
            <Box margin={{ bottom: 'xs' }} padding={{ top: 's' }} variant="awsui-key-label">
                <b>{t('bigOChannelMappings.noMappings')}</b>
            </Box>
        </Box>
    );

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <ErrorRouter errorStatusCode={applicationHealth.errorCode} />
                ) : (
                    <Grid gridDefinition={[{ colspan: 1 }, { colspan: 10 }, { colspan: 1 }]}>
                        <div></div>
                        <div>
                            <br />
                            <Form header={<Header variant="h1">{t('bigOChannelMappings.bigOChannelMapping')}</Header>}>
                                <div>
                                    <Table
                                        columnDefinitions={columnDefinitions}
                                        items={bigOChannelMappings}
                                        loadingText={t('bigOChannelMappings.loadingMappings')}
                                        sortingDisabled
                                        header={
                                            <BigOChannelMappingsHeader
                                                mappingsCount={bigOChannelMappings.length}
                                                onAddCoaModalOpen={() => setAddCoaModal(true)}
                                            />
                                        }
                                        resizableColumns={true}
                                        empty={<EmptyTable />}
                                    />
                                </div>
                            </Form>
                            {addCoaModal && (
                                <AddCoaModal
                                    coaDropdownOptions={coaDropdownOptions || new CoaDropdownOptions()}
                                    onCancel={() => setAddCoaModal(false)}
                                    onSave={saveNewCoaModal}
                                    repeatedMapping={repeatedMapping}
                                />
                            )}
                            {editMappingModal && mappingUpdated && (
                                <EditBigOChannelMappingModal
                                    mapping={mappingUpdated}
                                    onCancel={() => setEditMappingModal(false)}
                                    onSave={updateMappingEdit}
                                />
                            )}
                            {showDeleteModal && mappingUpdated && (
                                <DeleteBigOChannelMappingModal
                                    mapping={mappingUpdated}
                                    onCancel={() => setShowDeleteModal(false)}
                                    onRemove={deleteMapping}
                                />
                            )}
                        </div>
                        <div></div>
                    </Grid>
                )
            }
            notifications={<Flashbar items={flashbarItems} />}
            navigationHide={true}
            toolsHide={true}
            tools={undefined}
            headerSelector="#merp-nav"
        />
    );
};
