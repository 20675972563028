import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Box, NonCancelableCustomEvent, DatePicker, CalendarProps } from '@amzn/awsui-components-react';

import { Receipt, ReceiptDateValidator } from 'modules/purchase/models';
import { DateGenerator } from 'modules/core/class';
import { DeepCopy } from 'modules/purchase/class';
import { isRequisition } from 'modules/app/store';

export interface EditReceiptEstimationProps {
    receipt: Receipt;
    index: number;
    onChanged: (receipt: Receipt) => void;
}

export const EditReceiptEstimation = (props: EditReceiptEstimationProps) => {
    const { receipt, onChanged, index } = props;

    const { register, setValue, triggerValidation, errors } = useFormContext();

    const dateName = `receiptDate[${index}]`;

    const [receiptTime, setReceiptTime] = useState(
        receipt.receiptTime ? new Date(receipt.receiptTime).toISOString().split('T')[0] : ''
    );

    const isPurchaseRequest = useSelector(isRequisition);

    const setInputValue = () => {
        setValue(dateName, receiptTime, true);

        triggerValidation(dateName);
    };

    useEffect(() => {
        register({ name: dateName, type: 'custom' });
    }, [register, dateName]);

    const useEffectOnRegister = (setInput: () => void) => useEffect(setInput, [register, dateName]);
    useEffectOnRegister(setInputValue);

    const onDateUpdated = ({ detail }: NonCancelableCustomEvent<CalendarProps.ChangeDetail>) => {
        const dateValue = detail.value;

        const receiptTime = DateGenerator.parsePolarisDate(dateValue).valueOf();

        setValue(dateName, detail.value);

        triggerValidation(dateName);

        const newReceipt = DeepCopy.receipt(receipt);
        newReceipt.receiptTime = receiptTime;

        setReceiptTime(dateValue);
        onChanged(newReceipt);
    };

    const validateDate = (date: Date) => {
        return date.getUTCFullYear() !== ReceiptDateValidator.invalidYear();
    };

    const editDisabled = !receipt.canEditReceipt && receipt.receiptStatus != null && !isPurchaseRequest;

    return (
        <div>
            <DatePicker
                data-cy="spendAmountDatePicker"
                placeholder="YYYY/MM/DD"
                name={dateName}
                value={receiptTime}
                i18nStrings={{
                    todayAriaLabel: 'Today',
                    nextMonthAriaLabel: 'Next month',
                    previousMonthAriaLabel: 'Previous month'
                }}
                isDateEnabled={validateDate}
                invalid={!receipt.hasValidReceiptDate}
                disabled={editDisabled}
                onChange={onDateUpdated}
            ></DatePicker>
            <Box color="text-status-error" fontSize="body-s" variant="div">
                {errors?.receiptDate && errors?.receiptDate[index]?.message}
            </Box>
        </div>
    );
};
