import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Header, DatePicker, Button, SpaceBetween, Modal, Box } from '@amzn/awsui-components-react';
import { FormSchema } from 'modules/bulkEdit/models/FormSchema';
import { ReportName } from 'modules/reports/constants';
import { useReports } from 'modules/reports/hooks/useReports.hook';

export const DownloadBulkEditSpend = () => {
    const { t } = useTranslation('bulkEdit');

    const { register, setValue, formState, errors, setError } = FormSchema.getUseForm();
    const { createInProgress, createReportAsync, report } = useReports();

    const [startDateValue, setStartDate] = useState<string | undefined>(undefined);
    const [endDateValue, setEndDate] = useState<string | undefined>(undefined);
    const [isDownloadSuccessModal, setIsDownloadSuccessModal] = useState<boolean>(false);
    const [isDownloadFailureModal, setIsDownloadFailureModal] = useState<boolean>(false);
    const [disableDownloadButton, setDisableDownloadButton] = useState<boolean>(true);
    const [reportId, setReportID] = useState<string | undefined>(undefined);
    const [reportGenerationError, setReportGenerationError] = useState<string | undefined>();

    useEffect(() => {
        const formInvalid = !formState.isValid || createInProgress;

        const loadState = !startDateValue && !endDateValue;
        const areBothDatesSelected = startDateValue !== undefined && endDateValue !== undefined;
        const validDate = loadState || areBothDatesSelected;

        setDisableDownloadButton(formInvalid || !validDate);

        if (validDate) return;

        if (!endDateValue) setError('endDate', 'manual', 'End Date is not selected');
        if (!startDateValue) setError('startDate', 'manual', 'Start Date is not selected');
    }, [startDateValue, endDateValue, formState.isValid, createInProgress]);

    useEffect(() => {
        register({ name: 'startDate', type: 'custom' });
        register({ name: 'endDate', type: 'custom' });
    }, [register]);

    useEffect(() => {
        const generatedId = report?.report?.id;
        if (report?.succeeded && generatedId) {
            setReportID(generatedId);
            setIsDownloadSuccessModal(true);
            return;
        }
        if (report?.error) {
            setReportGenerationError(report?.error);
            setIsDownloadFailureModal(true);
        }
    }, [report]);

    const onSubmit = async () => {
        setReportID(undefined);
        setReportGenerationError(undefined);

        await createReportAsync(ReportName.ExportBulkEditSpend, createDateParams());
    };

    const createDateParams = () => {
        if (startDateValue && endDateValue) {
            return {
                creationStartDate: new Date(startDateValue).valueOf(),
                creationEndDate: new Date(endDateValue).valueOf()
            };
        }
    };

    const onStartDateChange = (value: string) => {
        setValue('startDate', new Date(value), true);
        setStartDate(value);
        if (startDateValue && !endDateValue) setError('startDate', 'manual', 'End Date is not selected');
    };

    const onEndDateChange = (value: string) => {
        setValue('endDate', new Date(value), true);
        setEndDate(value);
    };

    const DatePickers = () => (
        <SpaceBetween direction="horizontal" size="l">
            <div>
                <DatePicker
                    data-cy="fromDateSearchPicker"
                    placeholder="MM/DD/YYYY"
                    todayAriaLabel="Today"
                    nextMonthAriaLabel="Next month"
                    previousMonthAriaLabel="Previous month"
                    onChange={event => onStartDateChange(event.detail.value)}
                    value={startDateValue || ''}
                ></DatePicker>
                <Box color="text-status-error" fontSize="body-s" variant="span">
                    {errors?.startDate?.message}
                </Box>
            </div>
            <div>{t('downloadBulkEditSpend.to')}</div>
            <div>
                <DatePicker
                    data-cy="toDateSearchPicker"
                    placeholder="MM/DD/YYYY"
                    todayAriaLabel="Today"
                    nextMonthAriaLabel="Next month"
                    previousMonthAriaLabel="Previous month"
                    onChange={event => onEndDateChange(event.detail.value)}
                    value={endDateValue || ''}
                ></DatePicker>
                <Box color="text-status-error" fontSize="body-s" variant="span">
                    {errors?.endDate?.message}
                </Box>
            </div>
        </SpaceBetween>
    );

    const DownloadSuccessModal = () => (
        <Modal
            visible={isDownloadSuccessModal}
            header={t('modal.reportSubmitted')}
            onDismiss={() => setIsDownloadSuccessModal(false)}
            footer={
                <Box float="right">
                    <Button variant="primary" onClick={() => setIsDownloadSuccessModal(false)}>
                        {t('ok')}
                    </Button>
                </Box>
            }
        >
            {t('modal.reportSubmittedBody', { reportId })}
        </Modal>
    );

    const DownloadFailureModal = () => (
        <Modal
            visible={isDownloadFailureModal}
            header={t('modal.reportCreationFailed')}
            onDismiss={() => setIsDownloadFailureModal(false)}
            footer={
                <Box float="right">
                    <Button variant="primary" onClick={() => setIsDownloadFailureModal(false)}>
                        {t('ok')}
                    </Button>
                </Box>
            }
        >
            {reportGenerationError}
        </Modal>
    );

    return (
        <>
            <DownloadSuccessModal />
            <DownloadFailureModal />

            <Container header={<Header variant="h2">{t('downloadBulkEditSpend.title')}</Header>}>
                <Header variant="h3">{t('downloadBulkEditSpend.header')}</Header>
                <DatePickers />

                <p>{t('downloadBulkEditSpend.content')}</p>
                <br />

                <Button variant="primary" onClick={onSubmit} disabled={disableDownloadButton}>
                    {t('downloadBtn')}
                </Button>
            </Container>
        </>
    );
};
