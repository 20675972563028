/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@amzn/awsui-components-react';

interface Loadingrops {
    hasNextPage: boolean;
    loadLinesRef: (jsxElement: any) => void;
}

export const LoadingIndicator = ({ hasNextPage, loadLinesRef }: Loadingrops) => {
    const { t } = useTranslation('purchase');

    return (
        <>
            {hasNextPage && (
                <div ref={loadLinesRef}>
                    <Box variant="h1" textAlign="center">
                        {t('version.loading')}
                    </Box>
                </div>
            )}
        </>
    );
};
