import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandableCell } from 'modules/core/components/Table';
import { TableRow } from 'modules/core/model';
import { DeepCopy } from 'modules/purchase/class';
import { PurchaseIndex } from '@amzn/merp-core/models/purchase';
import { Link } from '@amzn/awsui-components-react';

interface PurchaseTableEditProps {
    row: TableRow<PurchaseIndex>;
}
export const PurchaseTableEdit = ({ row }: PurchaseTableEditProps) => {
    const { t } = useTranslation('purchase');

    const onEditClick = ({ payload }: TableRow<PurchaseIndex>) => {
        const data = DeepCopy.purchaseIndex(payload);

        const path = data.purchaseType === 'PURCHASE_REQUEST' ? 'purchaseRequest' : 'purchaseOrder';

        return window.open(`/${path}/${payload.purchaseId}`, '_blank');
    };

    return (
        <ExpandableCell
            row={row}
            cell={
                <Link variant="secondary" onFollow={() => onEditClick(row)}>
                    {t('view')}
                </Link>
            }
            content={row.payload.purchaseLines?.map(l => (
                <div key={l.lineNumber}></div>
            ))}
        />
    );
};
