import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
    Button,
    Modal,
    FormField,
    SpaceBetween,
    Box,
    Multiselect,
    MultiselectProps,
    NonCancelableCustomEvent
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

import { CustomField, CustomFieldType } from 'modules/customCategory/models';
import { useCustomField } from 'modules/customCategory/hooks';
import { useSelector } from 'react-redux';
import { getPurchaseId } from 'modules/app/store';
import { CustomSelectionDropdown } from '../CustomSelectionDropdown';

interface EditSelectionModalProps {
    visible?: boolean;
    defaultSelectionFields: CustomField[];
    onDismissed: () => void;
}

export const EditSelectionModal = (props: EditSelectionModalProps) => {
    const { visible, defaultSelectionFields, onDismissed } = props;

    const { t } = useTranslation('customCategory');

    const [multiSelectOptions, setMultiSelectOptions] = useState<readonly OptionDefinition[]>([]);
    const [selections, setSelections] = useState<string[]>([]);

    const purchaseId = useSelector(getPurchaseId);
    const { loading, success, updateCustomFieldsForPurchaseAsync } = useCustomField(purchaseId);

    useEffect(() => {
        if (!success) return;

        onDismissed();
    }, [success]);

    const onSave = () => {
        if (selections.length === 0) {
            onDismissed();

            return;
        }

        updateCustomFieldsForPurchaseAsync(purchaseId, [
            Object.assign(new CustomField(), {
                fieldKey: defaultSelectionFields[0].fieldKey,
                fieldValues: selections,
                fieldType: CustomFieldType.SELECTION,
                optional: false,
                multiSelect: defaultSelectionFields[0].isMultiSelect
            })
        ]);
        onDismissed();
    };

    const onCancel = () => {
        setSelections([]);
        setMultiSelectOptions([]);
        onDismissed();
    };

    const getMultiSelectOptions = (customFieldOptions: string[]) => {
        return customFieldOptions.map(option => {
            return { label: option, value: option };
        });
    };

    const onSingleSelectionChanged = (selection: string) => {
        setSelections([selection]);
    };

    const onMultiSelectionChanged = (event: NonCancelableCustomEvent<MultiselectProps.MultiselectChangeDetail>) => {
        setMultiSelectOptions(event.detail.selectedOptions);

        const selectedOptions = event.detail.selectedOptions.map(option => {
            return option.label ?? '';
        });
        setSelections(selectedOptions);
    };

    return (
        <Modal
            visible={visible ?? false}
            header={t('editModal.selectionTitle')}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="normal"
                            onClick={onCancel}
                            disabled={loading}
                            data-cy="EditSelectionModalModal.cancel"
                        >
                            {t('editModal.cancel')}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={onSave}
                            disabled={loading}
                            loading={loading}
                            data-cy="EditSelectionModalModal.save"
                        >
                            {t('editModal.save')}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={onDismissed}
        >
            <div>
                {defaultSelectionFields && defaultSelectionFields.length > 0 && (
                    <FormField>
                        <div>{defaultSelectionFields[0].fieldKey}</div>
                        {defaultSelectionFields[0].isMultiSelect ? (
                            <Multiselect
                                selectedOptions={multiSelectOptions}
                                onChange={onMultiSelectionChanged}
                                options={getMultiSelectOptions(defaultSelectionFields[0].fieldValues)}
                                placeholder="Choose options"
                                selectedAriaLabel="Selected"
                                filteringType="auto"
                            />
                        ) : (
                            <CustomSelectionDropdown
                                onChange={onSingleSelectionChanged}
                                selections={defaultSelectionFields[0].fieldValues}
                                selected={selections.length > 0 ? selections[0] : ''}
                            />
                        )}
                    </FormField>
                )}
            </div>
        </Modal>
    );
};
