import React from 'react';
import { Box, Button, Container, Grid } from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resetErrorPage } from 'modules/app/store';

import styles from './ErrorContent.module.scss';

interface ErrorContentProps {
    message: string;
    btnContent: string;
    btnFunction?: () => void;
}
export const ErrorContent = ({ message, btnContent, btnFunction }: ErrorContentProps) => {
    const { t } = useTranslation('purchase');
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const reloadPage = () => {
        navigate('/');
        return;
    };

    const navigatePage = () => {
        dispatch(resetErrorPage());
        btnFunction ? btnFunction() : reloadPage();
    };

    return (
        <div className={styles.wrapper}>
            <Grid
                gridDefinition={[
                    { colspan: { xxs: 12, m: 1 } },
                    { colspan: { xxs: 12, m: 10 } },
                    { colspan: { xxs: 12, m: 1 } }
                ]}
            >
                <div></div>
                <Container fitHeight>
                    <Box variant="strong" textAlign="center" display="inline-block" padding={'s'}>
                        {t(message)}
                    </Box>
                    <Box textAlign="center" padding={'s'}>
                        <Button variant="primary" onClick={navigatePage}>
                            {t(btnContent)}
                        </Button>
                    </Box>
                </Container>

                <div></div>
            </Grid>
        </div>
    );
};
