import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppLayout, Header, Tabs } from '@amzn/awsui-components-react';

import { ChannelView } from '../Channel';
import { TabsEvent } from 'modules/core/types';
import { BigObjectiveView } from '../BigObjective';
import { SmartEView } from '../SmartE';

const PageRoutes = {
    BigObjective: 'bigObjective',
    Channel: 'channel',
    SMARTe: 'smartE'
};

export const RollUpsView = () => {
    const [activeTab, updateActiveTab] = useState(PageRoutes.BigObjective);
    const { t } = useTranslation('rollups');
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const tabs = Object.values(PageRoutes).map(id => ({ id, label: t(id) }));

    const onTabChange = (event: TabsEvent) => {
        navigate(event.detail.activeTabId);
        updateActiveTab(event.detail.activeTabId);
    };

    useEffect(() => {
        const path = pathname?.split('/').pop() || PageRoutes.BigObjective;
        updateActiveTab(path);
    }, [pathname]);

    return (
        <AppLayout
            content={
                <section>
                    <Header variant="h1">{t('dashboardHeader')}</Header>
                    <Tabs tabs={tabs} activeTabId={activeTab} onChange={onTabChange} />
                    <Routes>
                        <Route path={PageRoutes.BigObjective} element={<BigObjectiveView />} />
                        <Route path={PageRoutes.Channel} element={<ChannelView />} />
                        <Route path={PageRoutes.SMARTe} element={<SmartEView />} />
                        <Route path="/" element={<Navigate to={PageRoutes.BigObjective} />} />
                    </Routes>
                </section>
            }
            toolsHide={true}
            navigationHide={true}
        />
    );
};
