import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Grid } from '@amzn/awsui-components-react';

import { PurchaseLine } from 'modules/purchase/models';

import { isQuantityBasedLine } from 'modules/app/store';
import { I18nFormatter } from 'modules/core/class';

interface PurchaseLabelIndicatorProps {
    line: PurchaseLine;
}

export const PurchaseLabelIndicator = ({ line }: PurchaseLabelIndicatorProps) => {
    const { t } = useTranslation('purchase');

    const indicator: BoxProps.Color = line.hasValidDistribution ? 'text-status-success' : 'text-status-error';

    const isQuantityBased = isQuantityBasedLine(line);
    const AmountDistributionMessage = () => {
        return (
            <Box color={indicator} variant="p" data-cy="amountDistributionMessage">
                {t('line.distributionMessage', {
                    actual: I18nFormatter.amount(t, line.estimatedAmount),
                    expected: I18nFormatter.amount(t, line.amount)
                })}
                {line.remainingAmount < 0 &&
                    t('line.distributionOverflow', {
                        remaining: I18nFormatter.amount(t, -line.remainingAmount)
                    })}
            </Box>
        );
    };
    const QuantityDistributionMessage = () => {
        return (
            <Box color={indicator} variant="p" data-cy="quantityDistributionMessage">
                {t('line.quantityDistributionMessage', {
                    actual: I18nFormatter.amount(t, line.estimatedQuantity),
                    expected: I18nFormatter.amount(t, line.quantity ?? 0)
                })}
                {line.remainingQuantity < 0 &&
                    t('line.quantityDistributionOverflow', {
                        remaining: I18nFormatter.amount(t, -line.remainingQuantity)
                    })}
            </Box>
        );
    };

    return (
        <Grid gridDefinition={[{ colspan: { xxs: 12, m: 4 } }]}>
            <div>{isQuantityBased ? QuantityDistributionMessage() : AmountDistributionMessage()}</div>
        </Grid>
    );
};
