import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, ColumnLayout, Container, Header } from '@amzn/awsui-components-react';

import { CountryMapping } from 'modules/mappings/models';
import style from '../OrgMappings/OrgMappings.module.scss';
import { AppConfig } from 'modules/core';

interface CountryMappingsProps {
    countryMappings: CountryMapping[];
}

export const CountryMappings = ({ countryMappings }: CountryMappingsProps) => {
    const { t } = useTranslation('mappings');

    const openTroubledTicket = () => {
        window.open(AppConfig.troubleTicketLink, '_blank');
    };

    return (
        <Box>
            <Container header={<Header variant="h2">{t('countryMappings.countries')}</Header>}>
                <ColumnLayout columns={4}>
                    {countryMappings.map(countryMapping => (
                        <div key={countryMapping.companyCode}>
                            <div className={style['grey-text']}>{countryMapping.companyCode}</div>
                            {countryMapping.countryCode}
                        </div>
                    ))}
                </ColumnLayout>
            </Container>
            <Box float="right" padding={{ top: 'l' }}>
                <Button variant="primary" onClick={() => openTroubledTicket()}>
                    {t('countryMappings.changeMapping')}
                </Button>
            </Box>
        </Box>
    );
};
