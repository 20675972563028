import React, { useEffect } from 'react';
import { Input, NonCancelableCustomEvent, InputProps, Box } from '@amzn/awsui-components-react';

import { ChannelSpend } from 'modules/channel/models/ChannelSpend';
import { FormSchema } from 'modules/purchase/models';

interface ChannelAllocationInputProps {
    channelIndex: number;
    channelAllocation: ChannelSpend;
    onChannelAllocationChange?: (channelIndex: number, amount: number) => void;
    onModalChannelAllocationChange?: (channelIndex: number, amount: number) => void;
    updateSaveButton?: (formState: boolean) => void;
}

export const ChannelAllocationInput = (props: ChannelAllocationInputProps) => {
    const {
        channelAllocation,
        channelIndex,
        onChannelAllocationChange,
        onModalChannelAllocationChange,
        updateSaveButton
    } = props;

    const { register, setValue, formState, errors } = FormSchema.getChannelAllocationUseForm();
    const [inputText, setInputText] = React.useState(channelAllocation.amount.toString());

    useEffect(() => {
        register({ name: 'allocationAmount', type: 'custom' });
        setValue('allocationAmount', channelAllocation.amount, true);
    }, [register]);

    function onAllocationUpdate({ detail: { value } }: NonCancelableCustomEvent<InputProps.ChangeDetail>): void {
        setInputText(value);

        const inputAmount = value.trim();
        setValue('allocationAmount', Number(inputAmount), true);

        if (formState.isValid) {
            if (onChannelAllocationChange) {
                onChannelAllocationChange(channelIndex, parseFloat(inputAmount));
            }

            if (onModalChannelAllocationChange) {
                onModalChannelAllocationChange(channelIndex, parseFloat(inputAmount));
            }
        }

        if (updateSaveButton) {
            updateSaveButton(formState.isValid);
        }
    }

    return (
        <>
            <Input onChange={onAllocationUpdate} value={inputText} invalid={!formState.isValid} />
            <Box color="text-status-error" fontSize="body-s" variant="span" textAlign="center">
                {errors?.allocationAmount?.message}
            </Box>
        </>
    );
};
