import React from 'react';
import { Box } from '@amzn/awsui-components-react';

import { ReactComponent as LogoSvg } from '../../../../assets/logo.svg';

export const Loading = () => (
    <div className="logo-loading">
        <div className="logo">
            <LogoSvg />
            <Box fontSize="heading-xs">Loading...</Box>
        </div>
    </div>
);
