import React from 'react';
import { Select, SelectProps, TokenGroup, TokenGroupProps } from '@amzn/awsui-components-react';

import style from '../OrgMappings/OrgMappings.module.scss';
import { useTranslation } from 'react-i18next';
import { OrgMapping } from 'modules/mappings/models';
import { useSelector } from 'react-redux';
import { ApplicationState, getCostCentersList } from 'modules/app/store';

interface OrgMappingRowProps {
    orgMapping: OrgMapping;
    onMappingUpdate: (updatedMapping: OrgMapping) => void;
    onCostCenterTokenDismiss: (costCenter: string, currOrgMapping: OrgMapping) => void;
}

export const OrgMappingRow = (props: OrgMappingRowProps) => {
    const { orgMapping, onMappingUpdate, onCostCenterTokenDismiss } = props;
    const { t } = useTranslation('mappings');

    const { costCentersDetails } = useSelector((state: ApplicationState) => state.orgCostCenters);
    const costCentersList = useSelector((state: ApplicationState) => getCostCentersList(state));

    const isNewCostCenter = (costCenter: string) => {
        const costCenterDetails = costCentersDetails.find(details => details.costCenterName === costCenter);
        return costCenterDetails ? costCenterDetails.isNewCostCenter() : false;
    };

    const disableCostCenter = (costCenter: string) => {
        const costCenterDetails = costCentersDetails.find(details => details.costCenterName === costCenter);
        return costCenterDetails ? costCenterDetails.mappedOrg : false;
    };

    const dropDownOptions = costCentersList.map(costCenter => ({
        label: costCenter,
        value: costCenter,
        disabled: disableCostCenter(costCenter)
    })) as SelectProps.Option[];

    const tokenGroupOptions = orgMapping.costCenters.map((costCenter, index) => {
        const newTag = isNewCostCenter(costCenter) ? t('orgMappings.newOrgTag') : undefined;
        return {
            label: costCenter,
            labelTag: newTag,
            description: t('orgMappings.costCenterIndex', { index: index + 1 })
        } as TokenGroupProps.Item;
    });

    const onSelectionUpdate = (selectedOption: SelectProps.Option) => {
        if (!selectedOption.label) return;
        orgMapping.costCenters.push(selectedOption.label);

        onMappingUpdate(orgMapping);
    };

    const onTokenDismiss = (tokenIndex: number) => {
        const dismissedCostCenter = tokenGroupOptions[tokenIndex].label;

        if (!dismissedCostCenter) return;
        onCostCenterTokenDismiss(dismissedCostCenter, orgMapping);
    };

    return (
        <>
            <div className={style['org-updates-div']}>
                <div className={style['grey-text']}>{orgMapping.orgName}</div>
                <Select
                    selectedOption={null}
                    onChange={({ detail }) => onSelectionUpdate(detail.selectedOption)}
                    options={dropDownOptions}
                    placeholder={t('costCenter')}
                    data-cy="selectCostCenter"
                />
                <TokenGroup
                    onDismiss={({ detail: { itemIndex } }) => onTokenDismiss(itemIndex)}
                    items={tokenGroupOptions}
                />
            </div>
        </>
    );
};
