import React, { useState } from 'react';
import { Box, Button, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';

import { OrgMapping } from 'modules/mappings/models';
import { useTranslation } from 'react-i18next';
import { OrgMappingRow } from '../OrgMappingRow/OrgMappingRow';
import { NewOrgMappingsModal } from '../NewOrgMappingsModal/NewOrgMappingsModal';
import { OrgMappingsUpdatesModal } from '../OrgMappingsUpdatesModal/OrgMappingsUpdatesModal';
import { ReassignCostCenterOrgMapping } from '../ReassignCostCenterOrgMapping/ReassignCostCenterOrgMapping';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'modules/app/store';

interface OrgMappingsProps {
    orgMappings: OrgMapping[];
    onMappingsSave: () => void;
    onMappingUpdate: (updatedMapping: OrgMapping, removedCostCenter?: string) => void;
    onAddingNewOrg: (newOrg: string) => void;
}

interface reassignCcDetails {
    costCenter: string;
    currOrgMapping: OrgMapping;
}

export const OrgMappings = (props: OrgMappingsProps) => {
    const { orgMappings, onMappingsSave, onMappingUpdate, onAddingNewOrg } = props;
    const { t } = useTranslation('mappings');

    const { costCentersDetails } = useSelector((state: ApplicationState) => state.orgCostCenters);

    const [createNewOrgModal, setCreateNewOrgModal] = useState(false);
    const [updatedMappingsModal, setUpdatedMappingsModal] = useState(false);
    const [reassignCcModal, setReassignCcModal] = useState(false);
    const [reassignedCC, setReassignedCC] = useState<reassignCcDetails>();

    const saveNewOrg = (newOrg: string) => {
        setCreateNewOrgModal(false);
        onAddingNewOrg(newOrg);

        if (!reassignedCC) return;
        const newOrgMapping = {
            orgName: newOrg,
            costCenters: [reassignedCC.costCenter]
        } as OrgMapping;

        removeCcFromMapping(reassignedCC.currOrgMapping, reassignedCC.costCenter);
        onMappingUpdate(newOrgMapping);

        reassignCostCenterReset();
    };

    const costCenterTokenRemove = (costCenter: string, currOrgMapping: OrgMapping) => {
        if (!reassignedCostCenter(costCenter)) {
            removeCcFromMapping(currOrgMapping, costCenter);
            return;
        }

        setReassignedCC({ costCenter: costCenter, currOrgMapping: currOrgMapping } as reassignCcDetails);

        setReassignCcModal(true);
    };

    const reassignedCostCenter = (costCenter: string) => {
        const costCenterDetails = costCentersDetails.find(details => details.costCenterName === costCenter);
        return costCenterDetails ? !costCenterDetails.isNewCostCenter() : false;
    };

    const onNewOrgCancel = () => {
        reassignCostCenterReset();

        setCreateNewOrgModal(false);
    };

    const reassignCostCenterReset = () => {
        setReassignedCC(undefined);
        setReassignCcModal(false);
    };

    const onReassignToNewCC = () => {
        setReassignCcModal(false);
        setCreateNewOrgModal(true);
    };

    const onReassignCCSave = (reassignedOrg: string) => {
        if (!reassignedCC) return;

        removeCcFromMapping(reassignedCC.currOrgMapping, reassignedCC.costCenter);
        addCcToReassignedMapping(reassignedOrg, reassignedCC.costCenter);

        reassignCostCenterReset();
    };

    const addCcToReassignedMapping = (reassignedOrg: string, costCenter: string) => {
        const newOrgIndex = orgMappings.map(mapping => mapping.orgName).indexOf(reassignedOrg);
        orgMappings[newOrgIndex].costCenters.push(costCenter);
        onMappingUpdate(orgMappings[newOrgIndex]);
    };

    const removeCcFromMapping = (orgMapping: OrgMapping, costCenter: string) => {
        orgMapping.costCenters = orgMapping.costCenters.filter(cc => cc !== costCenter);
        onMappingUpdate(orgMapping, costCenter);
    };

    const saveUpdatedMappings = () => {
        setUpdatedMappingsModal(false);

        onMappingsSave();
    };

    const EditOrgMappings = (
        <Box>
            <div>
                {orgMappings.map(orgMapping => (
                    <OrgMappingRow
                        key={orgMapping.orgName}
                        orgMapping={orgMapping}
                        onMappingUpdate={onMappingUpdate}
                        onCostCenterTokenDismiss={costCenterTokenRemove}
                    />
                ))}
            </div>
        </Box>
    );

    return (
        <Box>
            <SpaceBetween size="l">
                <Container header={<Header variant="h2">{t('orgMappings.orgs')}</Header>}>{EditOrgMappings}</Container>
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="normal" onClick={() => setCreateNewOrgModal(true)}>
                            {t('orgMappings.createNewOrg')}
                        </Button>
                        <Button variant="primary" onClick={() => setUpdatedMappingsModal(true)}>
                            {t('save')}
                        </Button>
                    </SpaceBetween>
                </Box>
            </SpaceBetween>

            {createNewOrgModal && <NewOrgMappingsModal onCancel={onNewOrgCancel} onSave={saveNewOrg} />}

            {updatedMappingsModal && (
                <OrgMappingsUpdatesModal
                    orgMappings={orgMappings}
                    onCancel={() => setUpdatedMappingsModal(false)}
                    onSave={saveUpdatedMappings}
                />
            )}

            {reassignCcModal && reassignedCC && (
                <ReassignCostCenterOrgMapping
                    orgList={orgMappings.map(mapping => mapping.orgName)}
                    onCreateNewOrg={onReassignToNewCC}
                    onSave={onReassignCCSave}
                    onCancel={reassignCostCenterReset}
                    costCenter={reassignedCC.costCenter}
                    currentOrg={reassignedCC.currOrgMapping.orgName}
                />
            )}
        </Box>
    );
};
